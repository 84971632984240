<template>
  <!-- begin sidebar nav -->
  <ul class="nav" v-if="menus">
    <li class="nav-search" v-if="pageOptions.pageSidebarSearch">
      <input type="text" v-on:keyup="handleSidebarFilter" class="form-control" placeholder="Sidebar menu filter..." />
    </li>
    <template v-for="menu in menus">
      <sidebar-nav-list
        ref="sidebarNavList"
        v-bind:menu="menu"
        v-bind:scrollTop="scrollTop"
        v-bind:key="menu.id"
        v-bind:status="menu.title"
        v-on:collapse-other="handleCollapseOther(menu)"
        v-on:show-float-submenu="handleShowFloatSubmenu"
        v-on:hide-float-submenu="handleHideFloatSubmenu"></sidebar-nav-list>
    </template>
    <!-- begin sidebar minify button -->
    <li><a href="javascript:;" class="sidebar-minify-btn" v-on:click="handleSidebarMinify()"><i class="fa fa-angle-double-left"></i></a></li>
    <!-- end sidebar minify button -->
  </ul>
  <!-- end sidebar nav -->
</template>

<script>
import SidebarNavList from './SidebarNavList.vue'
import PageOptions from '../../config/PageOptions.vue'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as AUTH_TYPES } from '@/pages/auth/store'

export default {
  name: 'SidebarNav',
  components: {
    SidebarNavList
  },
  data () {
    return {
      pageOptions: PageOptions
    }
  },
  async created () {
    await this.getMenu()
  },
  computed: {
    ...mapGetters({
      menu: AUTH_TYPES.AUTH.menu
    }),
    menus: function () {
      const res = []
      if (this.menu && Array.isArray(this.menu)) {
        return this.menu
      }
      return res
    }
  },
  methods: {
    ...mapActions({
      getMenu: AUTH_TYPES.AUTH.getMenu
    }),
    handleShowFloatSubmenu: function (menu, offset) {
      this.$emit('show-float-submenu', menu, offset)
    },
    handleHideFloatSubmenu: function () {
      this.$emit('hide-float-submenu')
    },
    handleCollapseOther: function (menu) {
      for (let i = 0; i < this.menus.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu)
      }
    },
    handleSidebarMinify: function () {
      this.pageOptions.pageSidebarMinified = !this.pageOptions.pageSidebarMinified
    },
    handleSidebarFilter: function (e) {
      let value = e.target.value
      value = value.toLowerCase()

      if (value) {
        for (let x = 0; x < this.menus.length; x++) {
          const title = (this.menus[x].title).toLowerCase()
          const children = this.menus[x].children

          if (title.search(value) > -1) {
            this.$refs.sidebarNavList[x].show()

            if (children) {
              this.$refs.sidebarNavList[x].searchExpand()
            }
          } else {
            if (children) {
              let hasActive = false
              for (let y = 0; y < children.length; y++) {
                const title2 = (children[y].title).toLowerCase()

                if (title2.search(value) > -1) {
                  hasActive = true
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show()
                  this.$refs.sidebarNavList[x].searchExpand()
                } else {
                  if (hasActive) {
                    this.$refs.sidebarNavList[x].searchExpand()
                  } else {
                    this.$refs.sidebarNavList[x].hide()
                  }
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide()
                }
              }
            } else {
              this.$refs.sidebarNavList[x].hide()
            }
          }
        }
      } else {
        for (let a = 0; a < this.menus.length; a++) {
          this.$refs.sidebarNavList[a].show()

          const submenu = this.menus[a].children
          if (submenu) {
            for (let b = 0; b < submenu.length; b++) {
              this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show()
            }
          }
        }
      }
    }
  }
}
</script>
