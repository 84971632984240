<template>
  <BlockUI v-if="getLoading">
    <ThuxSpinner />
  </BlockUI>
</template>

<style>
.loading-container .loading-backdrop {
  background: #fff !important;
  opacity: 0.7 !important;
}
.loading-container .loading {
  background: none !important;
  box-shadow: none !important;
}
</style>

<script>
import Vue from 'vue'
import BlockUI from 'vue-blockui'
import ThuxSpinner from './ThuxSpinner'

Vue.use(BlockUI)

export default {
  components: { ThuxSpinner },
  props: {
    loading: undefined
  },
  computed: {
    getLoading () {
      return this.$store.getters.loading
    }
  }
}
</script>
