<template>
  <div>
    <div
      v-if="!pageOptions.pageEmpty"
      class="fade page-sidebar-fixed page-header-fixed show page-container"
      v-bind:class="{
        'page-sidebar-minified': pageOptions.pageSidebarMinified,
        'page-content-full-height': pageOptions.pageContentFullHeight,
        'page-without-sidebar': pageOptions.pageWithoutSidebar,
        'page-with-right-sidebar': pageOptions.pageWithRightSidebar,
        'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
        'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
        'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
        'page-with-top-menu': pageOptions.pageWithTopMenu,
        'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
        'page-right-sidebar-toggled': pageOptions.pageMobileRightSidebarToggled || pageOptions.pageRightSidebarToggled,
        'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
        'has-scroll': pageOptions.pageBodyScrollTop,
        'layout-responsive': isMobile
      }">
      <Header />
      <TopMenu v-if="pageOptions.pageWithTopMenu" />
      <Sidebar v-if="!pageOptions.pageWithoutSidebar" />
      <SidebarRight v-if="pageOptions.pageWithTwoSidebar" />
      <div class="content router-transitions top-collapsed" v-bind:class="{ 'content-full-width': pageOptions.pageContentFullWidth, 'content-inverse-mode': pageOptions.pageContentInverseMode }">
        <router-view></router-view>
        <portal to="layout-header" slim></portal>
        <portal to="layout-main" slim></portal>
        <portal to="layout-detail" slim></portal>
        <portal to="layout-sidebar" slim></portal>
        <portal to="layout-footer" slim></portal>
        <vue-ins-progress-bar></vue-ins-progress-bar>
      </div>
      <Footer v-if="pageOptions.pageWithFooter" />
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/sidebar/Sidebar.vue'
import SidebarRight from '../components/sidebar-right/SidebarRight.vue'
import Header from '../components/header/Header.vue'
import TopMenu from '../components/top-menu/TopMenu.vue'
import Footer from '../components/footer/Footer.vue'
import PageOptions from '../config/PageOptions.vue'

export default {
  name: 'appLayout',
  components: {
    Sidebar,
    SidebarRight,
    Header,
    TopMenu,
    Footer
  },
  data () {
    return {
      isMobile: false,
      pageOptions: PageOptions
    }
  },
  beforeDestroy () {
    this.layoutHelpers.destroy()
  },
  mounted () {
    this.isMobile = window.innerWidth < 1140
    this.$insProgress.finish()
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
    // if (window.innerWidth < 1140) {
    try {
      document.querySelector('.wrapper').classList.add('sidebar-collapsed')
      document.querySelector('#sidebar-toggle').classList.remove('active')
    } catch (e) {}
    // } else {
    //   try {
    //     document.querySelector('.wrapper').classList.remove('sidebar-collapsed')
    //     document.querySelector('#sidebar-toggle').classList.add('active')
    //   } catch (e) {}
    // }
  },
  created () {
    PageOptions.pageBodyScrollTop = window.scrollY

    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.setIsMobile)

    this.$insProgress.start()

    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start()
      next()
    })
    this.$router.afterEach(() => {
      this.$insProgress.finish()
    })
  },
  methods: {
    handleScroll: function () {
      PageOptions.pageBodyScrollTop = window.scrollY
    },
    closeSidenav () {
      this.layoutHelpers.setCollapsed(true)
    },
    setIsMobile () {
      this.isMobile = window.innerWidth < 1140

      // hide sidebar by default on mobile
      if (window.innerWidth < 1140 && !document.activeElement.tagName === 'input') {
        document.querySelector('.wrapper').classList.add('sidebar-collapsed')
        document.querySelector('#sidebar-toggle').classList.remove('active')
      }
    }
  }
}
</script>
