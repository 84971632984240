import { routes as vOfficeRoutes, ROUTES as OFFICE_ROUTES } from './office/router'

const routes = [
  {
    path: 'office/',
    component: () => import('./OfficeWrapper'),
    name: 'office-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vOfficeRoutes
    ]
  }
]

const ROUTES = {
  ...OFFICE_ROUTES
}

export { routes, ROUTES }
