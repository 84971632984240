import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  DPI_LIST: 'miscellaneous-dpi'
}

const routes = [
  {
    path: 'dpi/',
    name: ROUTES.DPI_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['miscellaneous_dpi_list'],
      requiresAuth: true
    },
    component: () => import('./DpiList.vue')
  }

]

export { routes, ROUTES }
