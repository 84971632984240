import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-js-cookie'
import { merge } from 'lodash'

import { router } from './router'
import { auth } from './pages/auth/store'
import { store as accountStore } from './pages/account/store'
import { store as addressStore } from './pages/address/store'
import { store as carriageStore } from './pages/carriage/store'
import { store as currencyStore } from './pages/currency/store'
import { store as documentStore } from './pages/document/store'
import { store as i18nStore } from './pages/i18n/store'
import { store as invoiceStore } from './pages/invoice/store'
import { store as l10nStore } from './pages/l10n/store'
import { store as officeStore } from './pages/office/store'
import { store as organizationStore } from './pages/organization/store'
import { store as paymentStore } from './pages/payment/store'
import { store as priceStore } from './pages/price/store'
import { store as productStore } from './pages/product/store'
import { store as projectStore } from './pages/project/store'
import { store as proposalStore } from './pages/proposal/store'
import { store as quotationStore } from './pages/quotation/store'
import { store as vatStore } from './pages/vat/store'
import { store as yardStore } from './pages/yard/store'
import { store as miscStore } from './pages/miscellaneous/store'
import { store as bankStore } from './pages/bank/store'
import { store as agreementStore } from './pages/agreement/store'
import { store as dashboardStore } from './pages/dashboard/store'
import { store as notifyStore } from './pages/notify/store'
import { commonFilters } from './pages/common-filters/store'

Vue.use(Vuex)
Vue.use(VueCookies)

const NOTIFY_ERROR_DURATION = 6000
const NOTIFY_SUCCESS_DURATION = 2000

export default new Vuex.Store({
  modules: {
    auth,
    commonFilters,
    ...merge(
      accountStore,
      addressStore,
      carriageStore,
      currencyStore,
      documentStore,
      i18nStore,
      invoiceStore,
      l10nStore,
      officeStore,
      organizationStore,
      paymentStore,
      priceStore,
      productStore,
      projectStore,
      proposalStore,
      productStore,
      quotationStore,
      vatStore,
      yardStore,
      miscStore,
      bankStore,
      notifyStore,
      agreementStore,
      dashboardStore
    )
  },
  state: {
    notification: undefined,
    loading: undefined,
    componentLoading: true
  },
  getters: {
    notification (state) {
      return state.notification
    },
    loading (state) {
      return state.loading
    },
    componentLoading (state) {
      return state.componentLoading
    }
  },
  mutations: {
    loading (state, show) {
      state.loading = show
    },
    componentLoading (state, componentLoading) {
      state.componentLoading = componentLoading
    }
  },
  actions: {
    maintenance () {
      router.replace({ name: 'maintenance' })
    },
    notifySuccess (context, { title, text }) {
      title = `<h6>${this._vm.$t(title) || ''}</h6>`
      text = `<p><strong>${this._vm.$t(text)}</strong></p>`
      this._vm.$notify({
        group: 'messages',
        type: 'bg-success text-white',
        duration: NOTIFY_SUCCESS_DURATION,
        title: title,
        text: text
      })
    },
    notifyError (context, { error }) {
      let errorTitle = `<h6 class="error-code">${this._vm.$t('Operation failed')}</h6>`
      let errorList = '<ul>'
      let template = ''
      Object.entries(error).forEach(
        ([key, value]) => {
          if (key === 'status_code') {
            errorTitle = `<h6 class="error-code">${value} ${this._vm.$t('Operation failed')}</h6>`
          } else if (key === 'non_field_errors') {
            errorList += `<li class="error-item">${value}</li>`
          } else {
            errorList += `<li class="error-item">${key ? key + ': ' : ''}${value}</li>`
          }
        }
      )
      errorList += '</ul>'
      template += errorList
      this._vm.$notify({
        group: 'messages',
        type: 'bg-danger text-white',
        duration: NOTIFY_ERROR_DURATION,
        title: errorTitle,
        text: template
      })
    }
  }
})
