import Vue from 'vue'
import moment from 'moment'

export default {
  initDefaultFilter (vue) {
    Vue.filter('truncate', function (value, maxLenght = 20) {
      maxLenght = maxLenght > 0 ? maxLenght : 0
      if (value.length > maxLenght) {
        value = value.substring(0, maxLenght) + '...'
      }
      return value
    })

    Vue.filter('date', (value, format = 'L') => {
      if (value) {
        return moment(new Date(String(value))).locale(vue.$i18n.locale()).format(format)
      }
    })

    Vue.filter('time', (value, format = 'HH:mm') => {
      if (value) {
        const mm = moment(new Date(String(value))).locale(vue.$i18n.locale()).format(format)
        return mm
      }
    })

    Vue.filter('datetime', function (value, format = 'L LT') {
      if (value) {
        if (format) {
          return moment(new Date(String(value))).locale(vue.$i18n.locale()).format(format)
        }
        return moment(new Date(String(value))).locale(vue.$i18n.locale()).format(format)
      }
    })

    Vue.filter('currency', function (value) {
      if (typeof (value) === 'number' || (typeof (value) === 'string' && !isNaN(value))) {
        return Number(value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      }
    })

    Vue.filter('striphtml', function (value) {
      const regex = /(<([^>]+)>)/gi
      const text = value.replace(/&nbsp;/gi, ' ')
      return text.replace(regex, ' ')
    })

    Vue.filter('duration', (value, showDays = true, showHours = true) => {
      if (value) {
        const array = value.split(' ')
        if (array && array.length > 1) {
          let days
          if (Math.abs(array[0]) > 1) {
            days = `${array[0]} ${vue.$i18n.translate('Days')}`
          } else {
            days = `${array[0]} ${vue.$i18n.translate('Day')}`
          }
          if (showDays && !showHours) {
            return days
          } else if (showHours && !showDays) {
            return array[1]
          } else {
            return `${days} ${array[1]}`
          }
        }
      }
      return value
    })
  }
}
