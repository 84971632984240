import { parseApiUrl, handleError, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const proposal = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/proposal/{profile}/proposal',
    app: 'proposal',
    analysisTypeChoicesList: undefined
  },
  getters: {
    analysisTypeChoicesList (state) {
      return state.analysisTypeChoicesList
    }
  },
  mutations: {
    setAnalysistypechoiceslist (state, analysisTypeChoicesList) {
      state.analysisTypeChoicesList = analysisTypeChoicesList
    },
    setClose (state, data) {
      if (state.list && state.list.results) {
        const index = state.list.results.findIndex(element => element.id === data.id)
        if (index >= 0) {
          state.list.results[index].is_closed = data.is_closed
          state.list.results[index].date_close = data.date_close
        }
      }
      if (state.detail) {
        state.detail.is_closed = data.is_closed
        state.detail.date_close = data.date_close
      }
    }
  },
  actions: {
    async getAnalysistypechoiceslist ({ state, commit }, filters = {}) {
      const url = parseApiUrl(this, `${state.apiUrl}/analisys-type-choices/`)
      return axiosThux.get(url)
        .then(response => {
          commit('setAnalysistypechoiceslist', response.data)
        })
    },
    async closeProposal ({ state, commit, dispatch }, id) {
      let url = `/api/v1/proposal/{profile}/proposal/${id}/close/`
      url = parseApiUrl(this, url)
      return axiosThux.patch(url)
        .then(
          response => {
            commit('setClose', { ...response.data })
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async reOpenProposal ({ state, commit, dispatch }, id) {
      let url = `/api/v1/proposal/{profile}/proposal/${id}/re-open/`
      url = parseApiUrl(this, url)
      return axiosThux.patch(url)
        .then(
          response => {
            commit('setClose', { ...response.data })
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'proposal', model: 'proposal', listEntries: ['analysisTypeChoicesList'] }
    ),
    {
      GENERIC: {
        proposal: {
          proposal:
          {
            LIST: {
              ACTIONS: {
                closeProposal: 'GENERIC/proposal/proposal/closeProposal',
                reOpenProposal: 'GENERIC/proposal/proposal/reOpenProposal'
              }
            }
          }
        }
      }
    }
  )
)()

export { proposal, TYPES }
