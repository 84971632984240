import { merge } from 'lodash'
import { agreement, TYPES as AGREEMENT_TYPES } from './agreement/store'
import { agreementdocument, TYPES as AGREEMENT_DOCUMENT_TYPES } from './agreement-document/store'
import { agreementdocumenthistory, TYPES as AGREEMENT_DOCUMENT_HISTORY_TYPES } from './agreement-document/history/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      agreement: {
        namespaced: true,
        modules: {
          agreement,
          agreementdocument,
          agreementdocumenthistory
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    AGREEMENT_TYPES,
    AGREEMENT_DOCUMENT_TYPES,
    AGREEMENT_DOCUMENT_HISTORY_TYPES
  )

export { store, TYPES }
