import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const ownerproposaladdress = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/proposal/{profile}/owner-proposal-address',
    app: 'proposal'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'proposal', model: 'ownerproposaladdress' }
    ),
    { }
  )
)()

export { ownerproposaladdress, TYPES }
