import { merge } from 'lodash'
import { user, TYPES as USER_TYPES } from './user/store'
import { grouprole, TYPES as GROUPROLE_TYPES } from './group-role/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      account: {
        namespaced: true,
        modules: {
          user,
          grouprole
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    USER_TYPES,
    GROUPROLE_TYPES
  )

export { store, TYPES }
