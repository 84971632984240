import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const productCategoryCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/product/{profile}/product-category',
    app: 'product',
    pk: 'code'
  }
})

const TYPES = {
  PRODUCT_CATEGORY_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/productCategoryCommonFilter/filters',
        list: 'commonFilters/productCategoryCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/productCategoryCommonFilter/setFilters',
        setList: 'commonFilters/productCategoryCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/productCategoryCommonFilter/getList',
        setFilters: 'commonFilters/productCategoryCommonFilter/setFilters'
      }
    }
  }
}

export { productCategoryCommonFilter, TYPES }
