import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  CONTRACT_TYPOLOGY_LIST: 'organization-contract-typology'
}

const routes = [
  {
    path: 'contract-typology/',
    name: ROUTES.CONTRACT_TYPOLOGY_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['organization_contracttypology_list'],
      requiresAuth: true
    },
    component: () => import('./ContractTypologyList.vue')
  }

]

export { routes, ROUTES }
