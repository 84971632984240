import { routes as vProjectTypesRoutes, ROUTES as PROJECT_TYPE_ROUTES } from './project-type/router'

const routes = [
  {
    path: 'project/',
    component: () => import('./ProjectWrapper'),
    name: 'project-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vProjectTypesRoutes
    ]
  }
]

const ROUTES = {
  ...PROJECT_TYPE_ROUTES
}

export { routes, ROUTES }
