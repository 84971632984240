<template>

  <!-- menu with submenu -->
  <li v-if="menu.children.length > 0" class="has-sub" v-bind:class="{ 'active': subIsActive(menu.id), 'expand': this.stat =='expand', 'd-none': this.stat =='hide' }">
   <a href="#" v-on:click.prevent.stop="expand($event)" @mouseover="mouseOver(menu.children, $event)" @mouseleave="hideFloatSubmenu">
      <b class="caret"></b>
      <i v-if="menu.info && menu.info['icon-class']" v-bind:class="menu.info['icon-class']"></i>
      <span>
        {{ menu.title }}
      </span>
    </a>
    <ul class="sub-menu" v-bind:class="{ 'd-block': this.stat == 'expand', 'd-none': this.stat == 'collapse' }" v-bind:style="{ marginTop: (pageOptions.pageSidebarMinified) ? - (scrollTop + 40) + 'px' : '' }">
      <template v-for="submenu in menu.children">
        <sidebar-nav-list :root-menu-tree="menuTree" v-bind:menu="submenu" v-bind:key="submenu.id" ref="sidebarNavList" v-on:calc-float-submenu="handleCalcFloatSubmenu" v-on:collapse-other="handleCollapseOther(submenu)"></sidebar-nav-list>
      </template>
    </ul>
  </li>
  <!-- menu without submenu -->
  <router-link v-else v-bind:to="getRoute(menu)" v-bind:class="{ 'd-none': this.stat =='hide', 'active': subIsActive(menu.id)}" tag="li" v-on:click.native="collapseOther()">
    <a>
      <i v-if="menu.info && menu.info['icon-class']" v-bind:class="menu.info['icon-class']"></i>
      <span>
        {{ menu.title }}
      </span>
    </a>
  </router-link>
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'
import { cloneDeep } from 'lodash'

export default {
  name: 'SidebarNavList',
  props: ['menu', 'rootMenuTree'],
  created () {
    if (!this.rootMenuTree) {
      this.setMenuTree()
    } else {
      this.$set(this, 'menuTree', this.rootMenuTree)
    }
  },
  data () {
    return {
      stat: '',
      pageOptions: PageOptions,
      menuTree: [],
      item: {},
      parents: []
    }
  },
  methods: {
    setSubMenu (subMenu) {
      if (subMenu.children && subMenu.children.length > 0) {
        this.parents.push(subMenu.id)
        subMenu.children.every((children) => {
          if (!this.item.router) {
            this.setSubMenu(children)
            return true
          } else {
            return false
          }
        })
      } else {
        if (!this.menuTree[subMenu.router]) {
          this.parents.push(subMenu.id)
          this.$set(this, 'item', { router: subMenu.router, parents: cloneDeep(this.parents) })
        }
      }
    },
    setMenuTree () {
      const that = this
      const menuCopy = this.menu ? cloneDeep(this.menu) : []
      if (menuCopy.children && menuCopy.children.length > 0) {
        menuCopy.children.forEach((submenu) => {
          do {
            this.$set(this, 'item', {})
            this.$set(this, 'parents', [menuCopy.id])
            this.setSubMenu(submenu)
            if (Object.keys(that.item).length > 0) {
              this.menuTree[that.item.router] = this.item.parents
            }
          } while (Object.keys(that.item).length > 0)
        })
      } else {
        this.menuTree[this.menu.router] = [menuCopy.id]
      }
    },
    mouseOver: function (data, event) {
      if (this.pageOptions.pageSidebarMinified) {
        const offset = event.target.offsetParent.getBoundingClientRect()
        this.$emit('show-float-submenu', data, offset)
      }
    },
    hideFloatSubmenu: function () {
      if (this.pageOptions.pageSidebarMinified) {
        this.$emit('hide-float-submenu')
      }
    },
    expand: function () {
      if (this.stat === '') {
        this.stat = (this.subIsActive(this.menu.id)) ? 'collapse' : 'expand'
      } else {
        this.stat = (this.stat === 'expand') ? 'collapse' : 'expand'
      }
      this.$emit('collapse-other', this.menu)

      if (this.pageOptions.pageSidebarMinified) {
        this.$emit('calc-float-submenu')
      }
    },
    handleCalcFloatSubmenu: function () {
      if (this.pageOptions.pageSidebarMinified) {
        this.$emit('calc-float-submenu')
      }
    },
    collapse: function (menu) {
      if (this.menu !== menu) {
        this.stat = 'collapse'
      }
    },
    hide: function () {
      this.stat = 'hide'
    },
    show: function () {
      this.stat = ''
    },
    searchExpand: function () {
      this.stat = 'expand'
    },
    collapseOther: function () {
      this.$emit('collapse-other', this.menu)
    },
    handleCollapseOther: function (menu) {
      for (let i = 0; i < this.menu.children.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu)
      }
    },
    subIsActive (id) {
      if (this.menuTree[this.$route.name]) {
        return this.menuTree[this.$route.name].some((item) => { return item === id })
      }
      return false
    },
    getRoute (menu) {
      if (menu.info && menu.info.org_type_code) {
        return { name: menu.router, params: { orgType: menu.info.org_type_code } }
      }
      return { name: menu.router }
    }
  }
}
</script>
