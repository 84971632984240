const ROUTES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD_CONFIRM: 'reset-password-confirm',
  ACTIVATE_AND_RESET_PASSWORD_CONFIRM: 'user-account-activation-and-set-password'
}

const routes = [
  {
    path: '/auth',
    component: () => import('@/layout/LayoutAuth.vue'),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: ROUTES.LOGIN,
        name: ROUTES.LOGIN,
        component: () => import('./Login')
      },
      {
        path: ROUTES.LOGOUT,
        name: ROUTES.LOGOUT,
        component: () => import('./Logout'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        name: ROUTES.FORGOT_PASSWORD,
        component: () => import('./ForgotPassword')
      }
    ]
  },
  {
    path: '/reset-password-confirm',
    component: () => import('@/layout/LayoutAuth.vue'),
    meta: {
      requiresAuth: false
    },
    children: [{
      path: ':uid/:token/',
      name: ROUTES.RESET_PASSWORD_CONFIRM,
      component: () => import('./ResetPasswordConfirm')
    }]
  },
  {
    path: '/user-account-activation-and-set-password',
    component: () => import('@/layout/LayoutAuth.vue'),
    meta: {
      requiresAuth: false
    },
    children: [{
      path: ':uid/:token/',
      name: ROUTES.ACTIVATE_AND_RESET_PASSWORD_CONFIRM,
      component: () => import('./ActivateAndResetPasswordConfirm')
    }]
  },
  {
    path: '/user-account-activation',
    component: () => import('@/layout/LayoutAuth.vue'),
    meta: {
      requiresAuth: false
    },
    children: [{
      path: ':uid/:token/',
      name: ROUTES.USER_ACCOUNT_ACTIVATION,
      component: () => import('./UserAccountActivation')
    }]
  }
]

export { routes, ROUTES }
