import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const organizationrow = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-row',
    app: 'organization',
    ccnlList: {},
    atecoList: {},
    equalOrganizationList: {},
    professionalRegisterList: {},
    taxRegimeList: [],
    liquidationStatusChoices: [],
    soleShareholderChoices: []
  },
  getters: {
    ccnlList (state) {
      return state.ccnlList
    },
    atecoList (state) {
      return state.atecoList
    },
    equalOrganizationList (state) {
      return state.equalOrganizationList
    },
    professionalRegisterList (state) {
      return state.professionalRegisterList
    },
    taxRegimeList (state) {
      return state.taxRegimeList
    },
    liquidationStatusChoices (state) {
      return state.liquidationStatusChoices
    },
    soleShareholderChoices (state) {
      return state.soleShareholderChoices
    }
  },
  mutations: {
    setCcnllist (state, ccnlList) {
      state.ccnlList = ccnlList
    },
    setAtecolist (state, atecoCodeList) {
      state.atecoList = atecoCodeList
    },
    setEqualorganizationlist (state, equalOrganizationList) {
      state.equalOrganizationList = equalOrganizationList
    },
    setProfessionalregisterlist (state, professionalRegisterList) {
      state.professionalRegisterList = professionalRegisterList
    },
    setTaxregimelist (state, list) {
      state.taxRegimeList = list
    },
    setLiquidationstatuschoices (state, list) {
      state.liquidationStatusChoices = list
    },
    setSoleshareholderchoices (state, list) {
      state.soleShareholderChoices = list
    }
  },
  actions: {
    async getCcnllist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/ccnl-contract/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setCcnllist', response.data)
        })
    },
    async getAtecolist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/ateco-code/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setAtecolist', response.data)
        })
    },
    async getEqualorganizationlist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/equal-organization/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setEqualorganizationlist', response.data)
        })
    },
    async getProfessionalregisterlist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/professional-register/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setProfessionalregisterlist', response.data)
        })
    },
    async getTaxregimelist ({ commit }) {
      let url = '/api/v1/organization/{profile}/organization-row/tax-regime-choices/?'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setTaxregimelist', response.data)
        })
    },
    async getLiquidationstatuschoices ({ commit }) {
      let url = '/api/v1/organization/{profile}/organization-row/liquidation-status-choices/?'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setLiquidationstatuschoices', response.data)
        })
    },
    async getSoleshareholderchoices ({ commit }) {
      let url = '/api/v1/organization/{profile}/organization-row/sole-shareholder-choices/?'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setSoleshareholderchoices', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      {
        profile: 'GENERIC',
        app: 'organization',
        model: 'organizationrow',
        listEntries: [
          'ccnlList',
          'atecoList',
          'equalOrganizationList',
          'professionalRegisterList',
          'taxRegimeList',
          'liquidationStatusChoices',
          'soleShareholderChoices'
        ]
      }
    ),
    { }
  )
)()

export { organizationrow, TYPES }
