import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  PRODUCT_CATEGORY_LIST: 'product-product-category'
}

const routes = [
  {
    path: 'product-category/',
    name: ROUTES.PRODUCT_CATEGORY_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['product_productcategory_list'],
      requiresAuth: true
    },
    component: () => import('./ProductCategoryList.vue')
  }
]

export { routes, ROUTES }
