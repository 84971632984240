import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  ATTACHMENT_TYPOLOGY_LIST: 'miscellaneous-attachment-typology'
}

const routes = [
  {
    path: 'attachment-typology/',
    name: ROUTES.ATTACHMENT_TYPOLOGY_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['miscellaneous_attachmenttypology_list'],
      requiresAuth: true
    },
    component: () => import('./AttachmentTypologyList.vue')
  }

]

export { routes, ROUTES }
