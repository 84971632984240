import AccountWrapper from './AccountWrapper'
import { routes as vUserRoutes, ROUTES as USER_ROUTES } from './user/router'

const ROUTES = {
  CHANGE_PASSWORD: 'user-change-password',
  CHANGE_EMAIL: 'user-change-email',
  ...USER_ROUTES
}

const routes = [
  {
    path: 'account/',
    component: AccountWrapper,
    name: 'ticket-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'change-password',
        name: ROUTES.CHANGE_PASSWORD,
        component: () => import('../auth/ChangePassword')
      },
      {
        path: 'change-email',
        name: ROUTES.CHANGE_EMAIL,
        component: () => import('../auth/ChangeEmail')
      },
      ...vUserRoutes
    ]
  }
]

export { routes, ROUTES }
