import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const ownerorganization = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/owner-organization',
    app: 'organization',
    groups: undefined,
    organizationform: undefined,
    typologyChoices: undefined,
    employeeCategoryChoices: undefined,
    ccnlList: undefined,
    atecoList: undefined,
    staffTypes: undefined
  },
  getters: {
    groups (state) {
      return state.groups
    },
    organizationform (state) {
      return state.organizationform
    },
    typologyChoices (state) {
      return state.typologyChoices
    },
    employeeCategoryChoices (state) {
      return state.employeeCategoryChoices
    },
    ccnlList (state) {
      return state.ccnlList
    },
    atecoList (state) {
      return state.atecoList
    },
    staffTypes (state) {
      return state.staffTypes
    }
  },
  mutations: {
    setGroups (state, groups) {
      state.groups = groups
    },
    setOrganizationform (state, organizationform) {
      state.organizationform = organizationform
    },
    setTypologyChoices (state, choices) {
      state.typologyChoices = choices
    },
    setEmployeeCategoryChoices (state, choices) {
      state.employeeCategoryChoices = choices
    },
    setCcnlList (state, ccnlList) {
      state.ccnlList = ccnlList
    },
    setAtecoList (state, atecoCodeList) {
      state.atecoList = atecoCodeList
    },
    setStaffTypes (state, staffTypes) {
      state.staffTypes = staffTypes
    }
  },
  actions: {
    cleanLocalData ({ commit }) {
      commit('setGroups', undefined)
    },
    async getGroups ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/organization-group/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setGroups', response.data)
        })
    },
    async getOrganizationform ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/organization-form/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setOrganizationform', response.data)
        })
    },
    async getTypologychoices ({ commit }) {
      let url = '/api/v1/organization/{profile}/organization/typology-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setTypologyChoices', response.data)
        })
    },
    async getEmployeecategorychoices ({ commit }) {
      let url = '/api/v1/organization/{profile}/organization/category-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setEmployeeCategoryChoices', response.data)
        })
    },
    async getCcnllist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/ccnl-contract/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setCcnlList', response.data)
        })
    },
    async getAtecolist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/ateco-code/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setAtecoList', response.data)
        })
    },
    async getStafftypes ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/staff-typology?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setStaffTypes', response.data)
        })
    }
  }

})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'ownerorganization', listEntries: ['groups', 'organizationform', 'typologyChoices', 'employeeCategoryChoices', 'ccnlList', 'atecoList', 'staffTypes'] }
    ),
    { }
  )
)()

export { ownerorganization, TYPES }
