import { routes as vDocumentRoutes, ROUTES as DOCUMENT_ROUTES } from './document/router'
import { routes as vDocumentTypeRoutes, ROUTES as DOCUMENT_TYPE_ROUTES } from './document-type/router'

const routes = [
  {
    path: 'document/',
    component: () => import('./DocumentWrapper'),
    name: 'document-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vDocumentRoutes,
      ...vDocumentTypeRoutes
    ]
  }
]

const ROUTES = {
  ...DOCUMENT_ROUTES,
  ...DOCUMENT_TYPE_ROUTES
}

export { routes, ROUTES }
