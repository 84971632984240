import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  SUPPLIER_ORGANIZATION_LIST: 'organization-supplier-organization',
  SUPPLIER_ORGANIZATION_CREATE: 'organization-supplier-organization-create',
  SUPPLIER_ORGANIZATION_DETAIL: 'organization-supplier-organization-detail',
  SUPPLIER_ORGANIZATION_DOCUMENTS: 'organization-supplier-organization-documents',
  SUPPLIER_ORGANIZATION_CONTACT_DETAIL: 'organization-supplier-organization-contact-detail',
  SUPPLIER_ORGANIZATION_CONTACT_DOCUMENTS: 'organization-supplier-organization-contact-documents',
  SUPPLIER_ORGANIZATION_INSPECTIONS_LIST: 'organization-supplier-inspections'
}

const routes = [
  {
    path: 'supplier-organization/:orgType',
    name: ROUTES.SUPPLIER_ORGANIZATION_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['organization_supplierorganization_list'],
      requiresAuth: true
    },
    component: () => import('./SupplierOrganizationList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.SUPPLIER_ORGANIZATION_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_supplierorganization_create'],
          requiresAuth: true
        },
        component: () => import('./SupplierOrganizationCreate.vue')
      },
      {
        path: ':id/',
        name: ROUTES.SUPPLIER_ORGANIZATION_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_supplierorganization_retrieve'],
          requiresAuth: true
        },
        component: () => import('./SupplierOrganizationDetail.vue')
      },
      {
        path: ':id/inspections',
        name: ROUTES.SUPPLIER_ORGANIZATION_INSPECTIONS_LIST,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_organizationinspection_list'],
          requiresAuth: true
        },
        component: () => import('../../organization-inspections/OrganizationInspectionsList')
      },
      {
        path: ':id/contact/:cntId/',
        name: ROUTES.SUPPLIER_ORGANIZATION_CONTACT_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_suppliercontact_retrieve'],
          requiresAuth: true
        },
        component: () => import('../../../contact/contact/supplier-contact/SupplierContactDetail')
      }
    ]
  },
  {
    path: 'supplier-organization/:orgType/:orgId/documents',
    name: ROUTES.SUPPLIER_ORGANIZATION_DOCUMENTS,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['document_uploaddocument_list'],
      requiresAuth: true
    },
    component: () => import('../../../../document/organization-upload-document/OrganizationUploadDocumentPage')
  },
  {
    path: 'supplier-organization/:orgType/:orgId/:cntId/documents',
    name: ROUTES.SUPPLIER_ORGANIZATION_CONTACT_DOCUMENTS,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['document_uploaddocument_list'],
      requiresAuth: true
    },
    component: () => import('../../../../document/upload-document/UploadDocumentsList.vue')
  }
]

export { routes, ROUTES }
