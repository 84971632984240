import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { parseApiUrl, parseFilters } from '../../../storeMixin'
import { axiosThux } from '../../../axiosThux'

const document = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/document',
    app: 'document',
    evaluationDocList: [],
    subtypeList: []
  },
  getters: {
    evaluationDocList (state) {
      return state.evaluationDocList
    },
    subtypeList (state) {
      return state.subtypeList
    }
  },
  mutations: {
    setEvaluationDocList (state, evaluationDocList) {
      state.evaluationDocList = evaluationDocList
    },
    setSubtypeList (state, subtypeList) {
      state.subtypeList = subtypeList
    }
  },
  actions: {
    async getEvaluationdoclist ({ commit }, filters = {}) {
      let url = '/api/v1/document/{profile}/document/evaluation-doc-list/?no_page=no_page'
      url = parseFilters({ filters }, url)
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setEvaluationDocList', response.data)
        })
    },
    async getSubtypeList ({ commit }, filters = {}) {
      let url = '/api/v1/document/{profile}/document/subtype-options/'
      url = parseFilters({ filters }, url)
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setSubtypeList', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      {
        profile: 'GENERIC',
        app: 'document',
        model: 'document',
        listEntries: ['evaluationDocList']
      }
    ),
    {
      GENERIC: {
        document: {
          document: {
            LIST: {
              GETTERS: {
                subtypeList: 'GENERIC/document/document/subtypeList'
              },
              MUTATIONS: {
                setSubtypeList: 'GENERIC/document/document/setSubtypeList'
              },
              ACTIONS: {
                getSubtypeList: 'GENERIC/document/document/getSubtypeList'
              }
            }
          }
        }
      }
    }
  )
)()

export { document, TYPES }
