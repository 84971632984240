import { routes as vQuotationRoutes, ROUTES as QUOTATION_ROUTES } from './quotation/router'
import { routes as vQuotationExtraModuleRoutes, ROUTES as QUOTATION_EXTRA_MODULE_ROUTES } from './quotation-extra-module/router'

const routes = [
  {
    path: 'quotation/',
    component: () => import('./QuotationWrapper'),
    name: 'quotation-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vQuotationRoutes,
      ...vQuotationExtraModuleRoutes
    ]
  }
]

const ROUTES = {
  ...QUOTATION_ROUTES,
  ...QUOTATION_EXTRA_MODULE_ROUTES
}

export { routes, ROUTES }
