import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const unittype = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/product/{profile}/unit-type',
    app: 'product'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'product', model: 'unittype' }
    ),
    { }
  )
)()

export { unittype, TYPES }
