import { storeMixin, storeGenerator, parseForm, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '../../../../axiosThux'
import { parseApiUrl, parseFilters } from '../../../../storeMixin'

const contactemail = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact-email',
    app: 'organization',
    emailTypologyList: []
  },
  getters: {
    emailTypologyList (state) {
      return state.emailTypologyList
    }
  },
  mutations: {
    setEmailTypologyList (state, emailTypologyList) {
      state.emailTypologyList = emailTypologyList
    }
  },
  actions: {
    async getEmailtypologylist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/email-typology/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setEmailTypologyList', response.data)
        })
    },
    async createOrganizationEmail ({ state, commit, dispatch }, { id, ...data }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/create-organization-email/`
      return axiosThux.post(url, parseForm(data))
        .then(
          response => {
            commit('setDetail', response.data)
            commit('refreshList', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'contactemail', listEntries: ['emailTypologyList'] }
    ),
    {
      GENERIC: {
        organization: {
          contactemail: {
            DETAIL: {
              ACTIONS: {
                createOrganizationEmail: 'GENERIC/organization/contactemail/createOrganizationEmail'
              }
            }
          }
        }
      }
    }
  )
)()

export { contactemail, TYPES }
