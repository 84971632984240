import { merge } from 'lodash'
import { projecttype, TYPES as PROJECT_TYPE_TYPES } from './project-type/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      project: {
        namespaced: true,
        modules: {
          projecttype
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    PROJECT_TYPE_TYPES
  )

export { store, TYPES }
