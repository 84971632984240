import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const documenttype = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/document-type',
    app: 'document'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'document', model: 'documenttype' }
    ),
    { }
  )
)()

export { documenttype, TYPES }
