import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { handleError, parseApiUrl } from '../../../storeMixin'
import { axiosThux as axios } from '../../../axiosThux'

const quotationinstallment = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/quotation/{profile}/quotation-installment',
    app: 'quotation',
    typologyChoices: []
  },
  getters: {
    typologyChoices (state) {
      return state.typologyChoices
    }
  },
  mutations: {
    setTypologychoices (state, typologyChoices) {
      state.typologyChoices = typologyChoices
    }
  },
  actions: {
    getTypologychoices ({ state, commit, dispatch }, id) {
      const url = `${parseApiUrl(this, `${state.apiUrl}/typology-choices/`)}`
      return axios.get(url)
        .then(response => {
          commit('setTypologychoices', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    createInvoices ({ state, commit, dispatch }, data) {
      const url = `${parseApiUrl(this, `${state.apiUrl}/create-invoices/`)}`
      return axios.post(url, data)
        .then(response => {
          return response.data
        })
        .catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'quotation', model: 'quotationinstallment', listEntries: ['typologyChoices'] }
    ),
    {
      GENERIC: {
        quotation: {
          quotationinstallment:
          {
            LIST: {
              ACTIONS: {
                createInvoices: 'GENERIC/quotation/quotationinstallment/createInvoices'
              }
            }
          }
        }
      }
    }
  )
)()

export { quotationinstallment, TYPES }
