import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const stafftypology = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/staff-typology',
    app: 'organization'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'stafftypology' }
    ),
    { }
  )
)()

export { stafftypology, TYPES }
