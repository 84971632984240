import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const customerContactCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/customer-contact',
    app: 'organization'
  }
})

const TYPES = {
  CUSTOMER_CONTACT_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/customerContactCommonFilter/filters',
        list: 'commonFilters/customerContactCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/customerContactCommonFilter/setFilters',
        setList: 'commonFilters/customerContactCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/customerContactCommonFilter/getList',
        setFilters: 'commonFilters/customerContactCommonFilter/setFilters'
      }
    }
  }
}

export { customerContactCommonFilter, TYPES }
