
const ROUTES = {
  CUSTOMER_ORGANIZATION_GENERIC_SEARCH_LIST: 'organization-generic-search'
}

const routes = [
  {
    path: 'generic-search',
    name: ROUTES.CUSTOMER_ORGANIZATION_GENERIC_SEARCH_LIST,
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('./GenericSearchPage.vue')
  }
]

export { routes, ROUTES }
