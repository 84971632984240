import { routes as vAddressScopeRoutes, ROUTES as ADDRESS_SCOPE_ROUTES } from './address-scope/router'

const routes = [
  {
    path: 'address/',
    component: () => import('./AddressWrapper'),
    name: 'address-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vAddressScopeRoutes
    ]
  }
]

const ROUTES = {
  ...ADDRESS_SCOPE_ROUTES
}

export { routes, ROUTES }
