import { parseOrderBy, parseApiUrl, storeMixin, storeGenerator, parseForm, handleError, parseFilters, parseUpdateForm } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const organizationotherdocument = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/upload-document',
    app: 'document',
    documentStatusChoices: undefined,
    historyFilters: {},
    historyPage: 1,
    historyPerPage: undefined,
    historyList: {}
  },
  getters: {
    documentStatusChoices (state) {
      return state.documentStatusChoices
    },
    historyFilters (state) {
      return state.historyFilters
    },
    historyPage (state) {
      return state.historyPage || 1
    },
    historyPerPage (state) {
      return state.historyPerPage
    },
    historyList (state) {
      return state.historyList
    }
  },
  mutations: {
    setDocumentstatuschoices (state, documentStatusChoices) {
      state.documentStatusChoices = documentStatusChoices
    },
    setHistoryFilters (state, filters) {
      state.historyFilters = filters
    },
    setHistoryPage (state, page) {
      state.historyPage = Number(page)
    },
    setHistoryPerPage (state, perPage) {
      state.historyPerPage = perPage
    },
    setHistoryList (state, list) {
      state.historyList = list
    }
  },
  actions: {
    changeHistoryPage ({ dispatch, commit }, page) {
      if (parseInt(page)) {
        commit('setHistoryPage', page)
        dispatch('getHistory')
      }
    },
    changeHistoryPerPage ({ dispatch, commit }, perPage) {
      commit('setHistoryPerPage', perPage)
      commit('setHistoryPage', 1)
      dispatch('getHistory')
    },
    async getDocumentstatuschoices ({ commit }) {
      let url = '/api/v1/document/{profile}/upload-document/status-choices/ '
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setDocumentstatuschoices', response.data)
        })
    },
    async getList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/organization-yard-documents/?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
        getters.orderBy ? '&order_by__=' + getters.orderBy : ''
      }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async duplicate ({ commit, dispatch, state }, { id, yardId }) {
      let url = `/api/v1/document/{profile}/upload-document/${id}/duplicate/`
      url = parseApiUrl(this, url)
      return axiosThux.post(url, parseForm({ yard: yardId }))
        .then(
          response => {
            commit('setDetail', response.data)
            if (state.list) {
              const resultList = state.list.results ? state.list.results : state.list
              const index = resultList.findIndex(element => { return element[state.pk] === id })
              if (index > -1) {
                Object.assign(resultList[index], state.detail)
              } else {
                resultList.unshift(state.detail)
                state.list.count += 1
              }
            }
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
            return response.data
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async setHistoryFilters ({ dispatch, commit }, filters) {
      commit('setHistoryFilters', filters)
      commit('setHistoryPage', 1)
      return dispatch('getHistory')
    },
    async getHistory ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/?`
      url = `${url}${getters.historyPerPage ? 'per_page=' + getters.historyPerPage : ''}`
      url = `${url}${getters.historyPage ? '&page=' + getters.historyPage : ''}`
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setHistoryList', response.data)
          commit('setHistoryPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async bulkCreate ({ state, commit, dispatch }, data) {
      const url = `${parseApiUrl(this, state.apiUrl)}/bulk-create/`
      return axiosThux.post(url, parseForm(data))
        .then(
          response => {
            // commit('setDetail', response.data)
            // commit('refreshList', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async bulkUpdate ({ state, commit, dispatch }, { id, ...data }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/bulk-update/`
      return axiosThux.put(url, parseUpdateForm(data))
        .then(
          response => {
            // commit('setDetail', response.data)
            // commit('refreshList', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async checkExistingDocument ({ state, commit, dispatch }, filters) {
      let url = `${parseApiUrl(this, state.apiUrl)}/check-existing-documents/?no_page=no_page`
      if (filters) {
        for (const key in filters) {
          url = `${url}${filters[key] != null ? '&' + key + '=' + filters[key] : ''}`
        }
      }
      return axiosThux.get(url)
        .then(
          response => {
            return response
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'document', model: 'organizationotherdocument', listEntries: ['documentStatusChoices'] }
    ),
    {
      GENERIC: {
        document: {
          organizationotherdocument:
          {
            DETAIL: {
              ACTIONS: {
                duplicate: 'GENERIC/document/organizationotherdocument/duplicate',
                bulkCreate: 'GENERIC/document/organizationotherdocument/bulkCreate',
                bulkUpdate: 'GENERIC/document/organizationotherdocument/bulkUpdate',
                checkExistingDocument: 'GENERIC/document/organizationotherdocument/checkExistingDocument'
              }
            }
          }
        }
      }
    }
  )
)()

export { organizationotherdocument, TYPES }
