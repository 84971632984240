import { storeMixin, storeGenerator, parseApiUrl, handleError } from '@/storeMixin'
import { axiosThux as axios } from '@/axiosThux'
import { merge } from 'lodash'

const country = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/l10n/{profile}/country',
    app: 'l10n',
    pk: 'iso2_code',
    continentList: [],
    administrativeAreaList: []
  },
  getters: {
    continentList (state) {
      return state.continentList
    },
    administrativeAreaList (state) {
      return state.administrativeAreaList
    }
  },
  mutations: {
    setContinentList (state, continentList) {
      state.continentList = continentList
    },
    setAdministrativeAreaList (state, administrativeAreaList) {
      state.administrativeAreaList = administrativeAreaList
    }
  },
  actions: {
    getContinentlist ({ state, commit, dispatch }) {
      const url = `${parseApiUrl(this, `${state.apiUrl}/continent-choices/`)}`
      return axios.get(url)
        .then(response => {
          commit('setContinentList', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    getAdministrativearealist ({ state, commit, dispatch }) {
      const url = `${parseApiUrl(this, `${state.apiUrl}/administrative-area-choices/`)}`
      return axios.get(url)
        .then(response => {
          commit('setAdministrativeAreaList', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'l10n', model: 'country', listEntries: ['continentList', 'administrativeAreaList'] }
    ),
    { }
  )
)()

export { country, TYPES }
