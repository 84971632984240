import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const currency = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/currency/{profile}/currency',
    app: 'currency',
    pk: 'code'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'currency', model: 'currency' }
    ),
    { }
  )
)()

export { currency, TYPES }
