import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const contactCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact',
    app: 'organization'
  }
})

const TYPES = {
  CONTACT_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/contactCommonFilter/filters',
        list: 'commonFilters/contactCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/contactCommonFilter/setFilters',
        setList: 'commonFilters/contactCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/contactCommonFilter/getList',
        setFilters: 'commonFilters/contactCommonFilter/setFilters'
      }
    }
  }
}

export { contactCommonFilter, TYPES }
