import { routes as vYardRoutes, ROUTES as YARD_ROUTES } from './yard/router'

const routes = [
  {
    path: 'yard/',
    component: () => import('./YardWrapper'),
    name: 'yard-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vYardRoutes
    ]
  }
]

const ROUTES = {
  ...YARD_ROUTES
}

export { routes, ROUTES }
