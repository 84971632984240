import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const ROUTES = {
  SUPPLIER_CONTACT_LIST: 'organization-supplier-contact',
  SUPPLIER_CONTACT_CREATE: 'organization-supplier-contact-create',
  SUPPLIER_CONTACT_DETAIL: 'organization-supplier-contact-detail',
  SUPPLIER_CONTACT_DOCUMENT_LIST: 'organization-supplier-documents'
}

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const routes = [
  {
    path: ':orgType/supplier-contact/',
    name: ROUTES.SUPPLIER_CONTACT_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['organization_suppliercontact_list'],
      requiresAuth: true
    },
    component: () => import('./SupplierContactList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.SUPPLIER_CONTACT_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_suppliercontact_create'],
          requiresAuth: true
        },
        component: () => import('./SupplierContactCreate.vue')
      },
      {
        path: ':cntId/',
        name: ROUTES.SUPPLIER_CONTACT_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_suppliercontact_retrieve'],
          requiresAuth: true
        },
        component: () => import('./SupplierContactDetail.vue'),
        children: [
          {
            path: 'documents/',
            name: ROUTES.SUPPLIER_CONTACT_DOCUMENT_LIST,
            beforeEnter: beforeEnter,
            props: true,
            meta: {
              permissions: ['document_uploaddocument_list'],
              requiresAuth: true
            },
            component: () => import('../../../../document/upload-document/UploadDocumentsList.vue')
          }

        ]
      }
    ]
  }

]

export { routes, ROUTES }
