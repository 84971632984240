import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  LANGUAGE_LIST: 'i18n-language'
}

const routes = [
  {
    path: 'language/',
    name: ROUTES.LANGUAGE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['i18n_language_list'],
      requiresAuth: true
    },
    component: () => import('./LanguageList.vue')
  }

]

export { routes, ROUTES }
