import { parseApiUrl, parseFilters, storeMixin, storeGenerator, parseForm, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const contactaddress = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact-address',
    app: 'organization',
    addressScopes: undefined,
    typologyChoices: [],
    countryList: undefined
  },
  getters: {
    addressScopes (state) {
      return state.addressScopes
    },
    typologyChoices (state) {
      return state.typologyChoices
    },
    countryList (state) {
      return state.countryList
    }
  },
  mutations: {
    setAddressScopes (state, addrScopes) {
      state.addressScopes = addrScopes
    },
    setTypologyChoices (state, choices) {
      state.typologyChoices = choices
    },
    setCountryList (state, list) {
      state.countryList = list
    }
  },
  actions: {
    async getAddressscopes ({ commit }, filters = {}) {
      let url = '/api/v1/address/{profile}/address-scope/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setAddressScopes', response.data)
        })
    },
    async getTypologychoices ({ commit }) {
      let url = '/api/v1/organization/{profile}/organization-address/typology-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setTypologyChoices', response.data)
        })
    },
    async getCountrylist ({ commit }, filters = {}) {
      let url = '/api/v1/l10n/{profile}/country/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setCountryList', response.data)
        })
    },
    async createOrganizationAddress ({ state, commit, dispatch }, { id, ...data }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/create-organization-address/`
      return axiosThux.post(url, parseForm(data))
        .then(
          response => {
            commit('setDetail', response.data)
            commit('refreshList', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'contactaddress', listEntries: ['addressScopes', 'typologyChoices', 'countryList'] }
    ),
    {
      GENERIC: {
        organization: {
          contactaddress: {
            DETAIL: {
              ACTIONS: {
                createOrganizationAddress: 'GENERIC/organization/contactaddress/createOrganizationAddress'
              }
            }
          }
        }
      }
    }
  )
)()

export { contactaddress, TYPES }
