import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const yardproposaladdress = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/proposal/{profile}/yard-proposal-address',
    app: 'proposal'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'proposal', model: 'yardproposaladdress' }
    ),
    { }
  )
)()

export { yardproposaladdress, TYPES }
