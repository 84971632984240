import { merge } from 'lodash'
import { document, TYPES as DOCUMENT_TYPES } from './document/store'
import { documenttype, TYPES as DOCUMENT_TYPE_TYPES } from './document-type/store'
import { documentconfig, TYPES as DOCUMENT_CONFIG_TYPES } from './document-config/store'
import { uploaddocument, TYPES as UPLOAD_DOCUMENT_TYPES } from './upload-document/store'
import { organizationuploaddocument, TYPES as ORG_UPLOAD_DOCUMENT_TYPES } from './organization-upload-document/organization-document/store'
import { organizationotherdocument, TYPES as ORG_YARD_UPLOAD_DOCUMENT_TYPES } from './organization-upload-document/other-document/store'
import { uploaddocumenthistory, TYPES as UPLOAD_DOCUMENT_HISTORY_TYPES } from './upload-document/history/store'
import { documentyardlink, TYPES as DOCUMENT_YARD_LINK_TYPES } from './document-yard-link/store'
import { documentdownloadable, TYPES as DOCUMENT_TO_DOWNLOAD_TYPES } from './components/DocumentToDownload/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      document: {
        namespaced: true,
        modules: {
          document,
          documenttype,
          documentconfig,
          uploaddocument,
          organizationuploaddocument,
          organizationotherdocument,
          uploaddocumenthistory,
          documentyardlink,
          documentdownloadable
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    DOCUMENT_TYPES,
    DOCUMENT_TYPE_TYPES,
    DOCUMENT_CONFIG_TYPES,
    UPLOAD_DOCUMENT_TYPES,
    ORG_UPLOAD_DOCUMENT_TYPES,
    ORG_YARD_UPLOAD_DOCUMENT_TYPES,
    UPLOAD_DOCUMENT_HISTORY_TYPES,
    DOCUMENT_YARD_LINK_TYPES,
    DOCUMENT_TO_DOWNLOAD_TYPES
  )

export { store, TYPES }
