import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const ownerContactCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/owner-contact',
    app: 'organization'
  }
})

const TYPES = {
  OWNER_CONTACT_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/ownerContactCommonFilter/filters',
        list: 'commonFilters/ownerContactCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/ownerContactCommonFilter/setFilters',
        setList: 'commonFilters/ownerContactCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/ownerContactCommonFilter/getList',
        setFilters: 'commonFilters/ownerContactCommonFilter/setFilters'
      }
    }
  }
}

export { ownerContactCommonFilter, TYPES }
