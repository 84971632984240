import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  VAT_LIST: 'vat-vat'
}

const routes = [
  {
    path: 'vat/',
    name: ROUTES.VAT_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['vat_vat_list'],
      requiresAuth: true
    },
    component: () => import('./VatList.vue')
  }

]

export { routes, ROUTES }
