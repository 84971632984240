import { merge } from 'lodash'
import { parseApiUrl, parseFilters, storeGenerator, storeMixin } from '../../../storeMixin'
import { axiosThux } from '../../../axiosThux'

const organizationcontact = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-contact',
    app: 'organization',
    organizationList: undefined,
    roles: undefined,
    staffTypeList: undefined,
    contractTypeList: undefined,
    jobRiskList: undefined,
    contactQualificationList: undefined,
    securityRoleList: undefined,
    organizationContact: {},
    workplaceList: undefined
  },
  getters: {
    organizationList (state) {
      return state.organizationList
    },
    roles (state) {
      return state.roles
    },
    staffTypeList (state) {
      return state.staffTypeList
    },
    contractTypeList (state) {
      return state.contractTypeList
    },
    jobRiskList (state) {
      return state.jobRiskList
    },
    workplaceList (state) {
      return state.workplaceList
    },
    organizationContact (state) {
      return state.organizationContact
    },
    contactQualificationList (state) {
      return state.contactQualificationList
    },
    securityRoleList (state) {
      return state.securityRoleList
    }
  },
  mutations: {
    setOrganizationlist (state, organizationList) {
      state.organizationList = organizationList
    },
    setRoles (state, roles) {
      state.roles = roles
    },
    setStaffTypeList (state, staffTypeList) {
      state.staffTypeList = staffTypeList
    },
    setContractTypeList (state, contractTypeList) {
      state.contractTypeList = contractTypeList
    },
    setJobRiskList (state, jobRiskList) {
      state.jobRiskList = jobRiskList
    },
    setOrganizationContact (state, organizationContact) {
      state.organizationContact = organizationContact
    },
    setDetail (state, detail) {
      state.organizationContact = detail
      state.detail = detail
    },
    setWorkplacelist (state, workplaceList) {
      state.workplaceList = workplaceList
    },
    setContactqualificationlist (state, contactqualificationlist) {
      state.contactQualificationList = contactqualificationlist
    },
    setSecurityrolelist (state, securityrolelist) {
      state.securityRoleList = securityrolelist
    }
  },
  actions: {
    async getOrganizationlist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/organization/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setOrganizationlist', response.data)
        })
    },
    async getRoles ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/contact-role/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setRoles', response.data)
        })
    },
    async getStafftypelist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/staff-typology/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setStaffTypeList', response.data)
        })
    },
    async getContracttypelist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/contract-typology/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setContractTypeList', response.data)
        })
    },
    async getJobrisklist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/job-risk/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setJobRiskList', response.data)
        })
    },
    async getOrganizationcontact ({ commit }, { orgId, cntId }) {
      const filters = {
        filter__organization_id: orgId,
        filter__contact_id: cntId
      }
      let url = '/api/v1/organization/{profile}/organization-contact/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          if (response.data.results && response.data.results.length > 0) {
            commit('setOrganizationContact', response.data.results[0])
          } else {
            commit('setOrganizationContact', {})
          }
        })
    },
    async getWorkplacelist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/workplace/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setWorkplacelist', response.data)
        })
    },
    async getContactqualificationlist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/contact-qualification/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setContactqualificationlist', response.data)
        })
    },
    async getSecurityrolelist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/contact-security-role/?'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setSecurityrolelist', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      {
        profile: 'GENERIC',
        app: 'organization',
        model: 'organizationcontact',
        listEntries: ['organizationList', 'roles', 'staffTypeList', 'contractTypeList', 'jobRiskList', 'workplaceList', 'contactQualificationList', 'securityRoleList'],
        detailEntries: ['organizationContact']
      }
    ),
    { }
  )
)()

export { organizationcontact, TYPES }
