import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const atecocode = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/miscellaneous/{profile}/ateco-code',
    app: 'miscellaneous',
    riskLevelList: []
  },
  getters: {
    riskLevelList (state) {
      return state.riskLevelList
    }
  },
  mutations: {
    setRiskLevelList (state, levels) {
      state.riskLevelList = levels
    }
  },
  actions: {
    cleanLocalData ({ commit }) {
      commit('setRiskLevelList', undefined)
    },
    async getRisklevellist ({ commit }, filters = {}) {
      let url = '/api/v1/miscellaneous/{profile}/job-risk/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setRiskLevelList', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'miscellaneous', model: 'atecocode', listEntries: ['riskLevelList'] }
    ),
    { }
  )
)()

export { atecocode, TYPES }
