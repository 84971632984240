import { routes as vPriceList, ROUTES as PRICE_LIST_ROUTES } from './price-list/router'

const routes = [
  {
    path: 'price/',
    component: () => import('./PriceWrapper'),
    name: 'price-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vPriceList
    ]
  }
]

const ROUTES = {
  ...PRICE_LIST_ROUTES
}

export { routes, ROUTES }
