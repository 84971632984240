import { parseApiUrl, parseFilters, parseOrderBy, handleError, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const documentdownloadable = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/document/downloadable',
    app: 'document'
  },
  actions: {
    async getList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
        getters.orderBy ? '&order_by__=' + getters.orderBy : ''
      }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      {
        profile: 'GENERIC',
        app: 'document',
        model: 'documentdownloadable'
      }
    ),
    { }
  )
)()

export { documentdownloadable, TYPES }
