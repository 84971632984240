import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const bank = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/bank/{profile}/bank',
    app: 'bank'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'bank', model: 'bank' }
    ),
    { }
  )
)()

export { bank, TYPES }
