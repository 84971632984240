import { routes as vPaymentForm, ROUTES as PAYMENT_FORM_ROUTES } from './payment-form/router'

const routes = [
  {
    path: 'payment/',
    component: () => import('./PaymentWrapper'),
    name: 'payment-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vPaymentForm
    ]
  }
]

const ROUTES = {
  ...PAYMENT_FORM_ROUTES
}

export { routes, ROUTES }
