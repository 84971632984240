import { merge } from 'lodash'
import { agreementdocument, TYPES as AGREEMENT_DOCUMENT_TYPES } from './agreement-document/store'
import { uploaddocument, TYPES as UPLOAD_DOCUMENT_TYPES } from './upload-document/store'
import { eligibility, TYPES as ELEGIBILITY_DOCUMENT_TYPES } from './eligibility/store'
import { unilav, TYPES as UNILAV_TYPES } from './unilav/store'
import { training, TYPES as TRAINING_TYPES } from './training/store'
import { yardnotificationdocument, TYPES as YARD_NOTIFICATION_TYPES } from './yard-notification-document/store'
import { yard, TYPES as YARD_TYPES } from './yard-gantt/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      dashboard: {
        namespaced: true,
        modules: {
          agreementdocument,
          uploaddocument,
          eligibility,
          unilav,
          training,
          yard,
          yardnotificationdocument
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    AGREEMENT_DOCUMENT_TYPES,
    UPLOAD_DOCUMENT_TYPES,
    ELEGIBILITY_DOCUMENT_TYPES,
    UNILAV_TYPES,
    TRAINING_TYPES,
    YARD_TYPES,
    YARD_NOTIFICATION_TYPES
  )

export { store, TYPES }
