import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  LEAD_ORGANIZATION_LIST: 'organization-lead-organization',
  LEAD_ORGANIZATION_CREATE: 'organization-lead-organization-create',
  LEAD_ORGANIZATION_DETAIL: 'organization-lead-organization-detail',
  LEAD_ORGANIZATION_CONTACT_DETAIL: 'organization-lead-organization-contact-detail',
  LEAD_ORGANIZATION_DOCUMENTS: 'organization-lead-organization-documents',
  LEAD_ORGANIZATION_CONTACT_DOCUMENT_LIST: 'organization-lead-organization-contact-documents',
  LEAD_ORGANIZATION_INSPECTIONS_LIST: 'organization-lead-inspections'
}

const routes = [
  {
    path: 'extra-organization/:orgType',
    name: ROUTES.LEAD_ORGANIZATION_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['organization_customerorganization_list'],
      requiresAuth: true
    },
    component: () => import('./LeadOrganizationList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.LEAD_ORGANIZATION_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_customerorganization_create'],
          requiresAuth: true
        },
        component: () => import('./LeadOrganizationCreate.vue')
      },
      {
        path: ':id/',
        name: ROUTES.LEAD_ORGANIZATION_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_customerorganization_retrieve'],
          requiresAuth: true
        },
        component: () => import('./LeadOrganizationDetail.vue')
      },
      {
        path: ':id/inspections',
        name: ROUTES.LEAD_ORGANIZATION_INSPECTIONS_LIST,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_organizationinspection_list'],
          requiresAuth: true
        },
        component: () => import('../../organization-inspections/OrganizationInspectionsList')
      },
      {
        path: ':id/contact/:cntId/',
        name: ROUTES.LEAD_ORGANIZATION_CONTACT_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_customercontact_retrieve'],
          requiresAuth: true
        },
        component: () => import('../../../contact/contact/lead-contact/LeadContactDetail')
      }
    ]
  },
  {
    path: 'extra-organization/:orgType/:orgId/documents',
    name: ROUTES.LEAD_ORGANIZATION_DOCUMENTS,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['document_uploaddocument_list'],
      requiresAuth: true
    },
    component: () => import('../../../../document/organization-upload-document/OrganizationUploadDocumentPage')
  },
  {
    path: 'extra-organization/:orgType/:orgId/:cntId/documents',
    name: ROUTES.LEAD_ORGANIZATION_CONTACT_DOCUMENT_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['document_uploaddocument_list'],
      requiresAuth: true
    },
    component: () => import('../../../../document/upload-document/UploadDocumentsList.vue')
  }

]

export { routes, ROUTES }
