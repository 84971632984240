import { merge } from 'lodash'
import { ownerorganization, TYPES as OWNER_ORGANIZATION_TYPES } from './organization/organization/owner-organization/store'
import { customerorganization, TYPES as CUSTOMER_ORGANIZATION_TYPES } from './organization/organization/customer-organization/store'
import { supplierorganization, TYPES as SUPPLIER_ORGANIZATION_TYPES } from './organization/organization/supplier-organization/store'
import { leadorganization, TYPES as LEAD_ORGANIZATION_TYPES } from './organization/organization/lead-organization/store'
import { organizationrow, TYPES as ORGANIZATION_ROW_TYPES } from './organization/organization-row/store'
import { inailcode, TYPES as INAIL_CODE_TYPES } from './inail-code/store'
import { organizationgroup, TYPES as ORG_GROUP_TYPES } from './organization/organization-group/store'
import { organizationform, TYPES as ORG_FORM_TYPES } from './organization/organization-form/store'
import { organizationaddress, TYPES as ORG_ADDRESS_TYPES } from './organization/organization-address/store'
import { organizationipaaddress, TYPES as ORG_IPA_ADDRESS_TYPES } from './organization/organization-ipa-address/store'
import { organizationemail, TYPES as ORG_EMAIL_TYPES } from './organization/organization-email/store'
import { organizationphone, TYPES as ORG_PHONE_TYPES } from './organization/organization-phone/store'
import { organizationwebsite, TYPES as ORG_WEB_SITE_TYPES } from './organization/organization-website/store'
import { contactemail, TYPES as CONTACT_EMAIL_TYPES } from './contact/contact-email/store'
import { contactphone, TYPES as CONTACT_PHONE_TYPES } from './contact/contact-phone/store'
import { contactaddress, TYPES as CONTACT_ADDRESS_TYPES } from './contact/contact-address/store'
import { contactrole, TYPES as CONTACT_ROLE_TYPES } from './contact/contact-role/store'
import { contactsecurityrole, TYPES as CONTACT_SECURITY_ROLE_TYPES } from './contact/contact-security-role/store'
import { contactsocialaccount, TYPES as CONTACT_SOCIAL_ACCOUNT_TYPES } from './contact/contact-social-account/store'
import { contacttitle, TYPES as CONTACT_TITLE_TYPES } from './contact/contact-title/store'
import { emailtypology, TYPES as EMAIL_TYPOLOGY_TYPES } from './email-typology/store'
import { phonetypology, TYPES as PHONE_TYPOLOGY_TYPES } from './phone-typology/store'
import { stafftypology, TYPES as STAFF_TYPOLOGY_TYPES } from './staff-typology/store'
import { contracttypology, TYPES as CONTRACT_TYPOLOGY_TYPES } from './contract-typology/store'
import { organization, TYPES as ORGANIZATION_TYPES } from './organization/store'
import { organizationcontact, TYPES as ORGANIZATION_CONTACT_TYPES } from './organization-contact/store'
import { customercontact, TYPES as CUSTOMER_CONTACT_TYPES } from './contact/contact/customer-contact/store'
import { ownercontact, TYPES as OWNER_CONTACT_TYPES } from './contact/contact/owner-contact/store'
import { suppliercontact, TYPES as SUPPLIER_CONTACT_TYPES } from './contact/contact/supplier-contact/store'
import { subjectcontact, TYPES as SUBJECT_CONTACT_TYPES } from './contact/contact/subject-contact/store'
import { contact, TYPES as CONTACT_TYPES } from './contact/store'
import { leadcontact, TYPES as LEAD_CONTACT_TYPES } from './contact/contact/lead-contact/store'
import { contactqualification, TYPES as CONTACT_QUALIFICATION_TYPES } from './contact/contact-qualification/store'
import { contactdpi, TYPES as CONTACT_DPI_TYPES } from './contact-dpi/store'
import { organizationcontactsecurityrole, TYPES as ORGANIZATION_CONTACT_SECURITY_ROLE_TYPES } from './organization-contact-security-role/store'
import { ownercontactemail, TYPES as OWNER_CONTACT_EMAIL_TYPES } from './owner-contact-emails/store'
import { organizationinspection, TYPES as ORG_INSPECTIONS_TYPES } from './organization/organization-inspections/store'
import { organizationinspectionattachment, TYPES as ORG_INSPECTIONS_ATTACH_TYPES } from './organization/organization-inspection-attachment/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      organization: {
        namespaced: true,
        modules: {
          ownerorganization,
          customerorganization,
          supplierorganization,
          leadorganization,
          organizationrow,
          inailcode,
          organizationgroup,
          organizationform,
          organizationaddress,
          organizationipaaddress,
          organizationemail,
          organizationphone,
          organizationwebsite,
          contactemail,
          contactphone,
          contactaddress,
          contactrole,
          contactsecurityrole,
          contactsocialaccount,
          contacttitle,
          emailtypology,
          phonetypology,
          stafftypology,
          contracttypology,
          organization,
          organizationcontact,
          contact,
          customercontact,
          ownercontact,
          suppliercontact,
          subjectcontact,
          leadcontact,
          contactqualification,
          contactdpi,
          organizationcontactsecurityrole,
          ownercontactemail,
          organizationinspection,
          organizationinspectionattachment
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    OWNER_ORGANIZATION_TYPES,
    CUSTOMER_ORGANIZATION_TYPES,
    SUPPLIER_ORGANIZATION_TYPES,
    LEAD_ORGANIZATION_TYPES,
    ORGANIZATION_ROW_TYPES,
    INAIL_CODE_TYPES,
    ORG_GROUP_TYPES,
    ORG_FORM_TYPES,
    ORG_ADDRESS_TYPES,
    ORG_IPA_ADDRESS_TYPES,
    ORG_EMAIL_TYPES,
    ORG_PHONE_TYPES,
    ORG_WEB_SITE_TYPES,
    CONTACT_EMAIL_TYPES,
    CONTACT_PHONE_TYPES,
    CONTACT_ADDRESS_TYPES,
    CONTACT_ROLE_TYPES,
    CONTACT_SECURITY_ROLE_TYPES,
    CONTACT_SOCIAL_ACCOUNT_TYPES,
    CONTACT_TITLE_TYPES,
    EMAIL_TYPOLOGY_TYPES,
    PHONE_TYPOLOGY_TYPES,
    STAFF_TYPOLOGY_TYPES,
    CONTRACT_TYPOLOGY_TYPES,
    ORGANIZATION_TYPES,
    ORGANIZATION_CONTACT_TYPES,
    CUSTOMER_CONTACT_TYPES,
    OWNER_CONTACT_TYPES,
    SUPPLIER_CONTACT_TYPES,
    SUBJECT_CONTACT_TYPES,
    CONTACT_TYPES,
    LEAD_CONTACT_TYPES,
    CONTACT_QUALIFICATION_TYPES,
    CONTACT_DPI_TYPES,
    ORGANIZATION_CONTACT_SECURITY_ROLE_TYPES,
    OWNER_CONTACT_EMAIL_TYPES,
    ORG_INSPECTIONS_TYPES,
    ORG_INSPECTIONS_ATTACH_TYPES
  )

export { store, TYPES }
