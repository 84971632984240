import { merge } from 'lodash'
import { language, TYPES as LANGUAGE_TYPES } from './language/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      i18n: {
        namespaced: true,
        modules: {
          language
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    LANGUAGE_TYPES
  )

export { store, TYPES }
