import { merge } from 'lodash'
import { proposal, TYPES as PROPOSAL_TYPES } from './proposal/store'
import { ownerproposaladdress, TYPES as OWNER_PROPOSAL_ADDRESS_TYPES } from './owner-proposal-address/store'
import { yardproposaladdress, TYPES as YARD_PROPOSAL_ADDRESS_TYPES } from './yard-proposal-address/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      proposal: {
        namespaced: true,
        modules: {
          proposal,
          ownerproposaladdress,
          yardproposaladdress
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    PROPOSAL_TYPES,
    OWNER_PROPOSAL_ADDRESS_TYPES,
    YARD_PROPOSAL_ADDRESS_TYPES
  )

export { store, TYPES }
