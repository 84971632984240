import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const organizationphone = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-phone',
    app: 'organization',
    phoneTypes: undefined
  },
  getters: {
    phoneTypes (state) {
      return state.phoneTypes
    }
  },
  mutations: {
    setPhoneTypes (state, types) {
      state.phoneTypes = types
    }
  },
  actions: {
    async getPhonetypes ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/phone-typology?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setPhoneTypes', response.data)
        })
    }

  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'organizationphone', listEntries: ['phoneTypes'] }
    ),
    { }
  )
)()

export { organizationphone, TYPES }
