import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const yardcontrolbody = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/yard/{profile}/yard-control-body',
    app: 'yard'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'yard', model: 'yardcontrolbody' }
    ),
    { }
  )
)()

export { yardcontrolbody, TYPES }
