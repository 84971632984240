import { storeMixin, storeGenerator, parseApiUrl, parseFilters, parseOrderBy, handleError, parseForm } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const agreementdocument = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/agreement/{profile}/agreement-document',
    app: 'agreementdocument',
    organizationChoices: []
  },
  getters: {
    organizationChoices (state) {
      return state.organizationChoices
    }
  },
  mutations: {
    setOrganizationchoices (state, organizationChoices) {
      state.organizationChoices = organizationChoices
    }
  },
  actions: {
    async getOrganizationchoices ({ commit }, filters) {
      let url = '/api/v1/organization/{profile}/customer-organization/?'
      url = parseFilters({ filters }, url)
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setOrganizationchoices', response.data)
        })
    },
    async getList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/valid-documents?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
        getters.orderBy ? '&order_by__=' + getters.orderBy : ''
      }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async newRevisionAgreementDocument ({ state, commit, dispatch }, { originalDoc, ...data }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${originalDoc}/new-revision/`
      return axiosThux.post(url, parseForm(data))
        .then(
          response => {
            commit('setDetail', response.data)
            commit('refreshList', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'agreement', model: 'agreementdocument', listEntries: ['organizationChoices'] }
    ),
    {
      GENERIC: {
        agreement: {
          agreementdocument:
          {
            DETAIL: {
              ACTIONS: {
                newRevisionAgreementDocument: 'GENERIC/agreement/agreementdocument/newRevisionAgreementDocument'
              }
            }
          }
        }
      }
    }
  )
)()

export { agreementdocument, TYPES }
