import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  INVOICE_INVOICE_LIST: 'invoice-invoice-list',
  INVOICE_INVOICE_DETAIL: 'invoice-invoice-detail'
}

const routes = [
  {
    path: 'invoice/',
    name: ROUTES.INVOICE_INVOICE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['invoice_invoice_list'],
      requiresAuth: true
    },
    component: () => import('./InvoiceList.vue'),
    children: [
      {
        path: ':id/',
        name: ROUTES.INVOICE_INVOICE_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['invoice_invoice_retrieve'],
          requiresAuth: true
        },
        component: () => import('./InvoiceEditPage.vue')
      }
    ]
  }
]

export { routes, ROUTES }
