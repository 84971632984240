import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  PAYMENT_FORM_LIST: 'payment-payment-form'
}

const routes = [
  {
    path: 'payment-form/',
    name: ROUTES.PAYMENT_FORM_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['payment_paymentform_list'],
      requiresAuth: true
    },
    component: () => import('./PaymentFormList.vue')
  }

]

export { routes, ROUTES }
