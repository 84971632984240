import { parseApiUrl, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const paymentform = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/payment/{profile}/payment-form',
    app: 'payment',
    electronicInvoicePaymentCodeList: undefined
  },
  getters: {
    electronicInvoicePaymentCodeList (state) {
      return state.electronicInvoicePaymentCodeList
    }
  },
  mutations: {
    setElectronicinvoicepaymentcodelist (state, list) {
      state.electronicInvoicePaymentCodeList = list
    }
  },
  actions: {
    async getElectronicinvoicepaymentcodelist ({ state, commit }, filters = {}) {
      const url = parseApiUrl(this, `${state.apiUrl}/electronic-payment-code/`)
      return axiosThux.get(url)
        .then(response => {
          commit('setElectronicinvoicepaymentcodelist', response.data)
        })
    }
  }

})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'payment', model: 'paymentform', listEntries: ['electronicInvoicePaymentCodeList'] }
    ),
    { }
  )
)()

export { paymentform, TYPES }
