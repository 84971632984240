import { routes as vCurrencyRoutes, ROUTES as CURRENCY_ROUTES } from './currency/router'

const routes = [
  {
    path: 'currency/',
    component: () => import('./CurrencyWrapper'),
    name: 'currency-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vCurrencyRoutes
    ]
  }
]

const ROUTES = {
  ...CURRENCY_ROUTES
}

export { routes, ROUTES }
