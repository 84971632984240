import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  ADDRESS_SCOPE_LIST: 'address-address-scope'
}

const routes = [
  {
    path: 'address-scope/',
    name: ROUTES.ADDRESS_SCOPE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['address_addressscope_list'],
      requiresAuth: true
    },
    component: () => import('./AddressScopeList.vue')
  }

]

export { routes, ROUTES }
