import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  ORGANIZATION_FORM_LIST: 'organization-organization-form',
  CUSTOMER_ORGANIZATION_INSPECTIONS_LIST: 'organization-customer-inspections'
}

const routes = [
  {
    path: 'organization-form/',
    name: ROUTES.ORGANIZATION_FORM_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['organization_organizationform_list'],
      requiresAuth: true
    },
    component: () => import('./OrganizationFormList.vue')
  }

]

export { routes, ROUTES }
