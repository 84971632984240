import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const carriageCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/carriage/{profile}/carriage',
    app: 'carriage',
    pk: 'code'
  }
})

const TYPES = {
  CARRIAGE_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/carriageCommonFilter/filters',
        list: 'commonFilters/carriageCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/carriageCommonFilter/setFilters'
      },
      ACTIONS: {
        getList: 'commonFilters/carriageCommonFilter/getList',
        setFilters: 'commonFilters/carriageCommonFilter/setFilters'
      }
    }
  }
}

export { carriageCommonFilter, TYPES }
