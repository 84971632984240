import { routes as vProduct, ROUTES as PRODUCT_ROUTES } from './product/router'
import { routes as vProductCategory, ROUTES as PRODUCT_CATEGORY_ROUTES } from './product-category/router'
import { routes as vUnitType, ROUTES as UNIT_TYPE_ROUTES } from './unit-type/router'

const routes = [
  {
    path: 'product/',
    component: () => import('./ProductWrapper'),
    name: 'product-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vProduct,
      ...vProductCategory,
      ...vUnitType
    ]
  }
]

const ROUTES = {
  ...PRODUCT_ROUTES,
  ...PRODUCT_CATEGORY_ROUTES,
  ...UNIT_TYPE_ROUTES
}

export { routes, ROUTES }
