import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const organizationipaaddress = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/ipa-address',
    app: 'organization',
    addressScopes: undefined,
    typologyChoices: [],
    countryList: undefined
  },
  getters: {
    addressScopes (state) {
      return state.addressScopes
    },
    typologyChoices (state) {
      return state.typologyChoices
    },
    countryList (state) {
      return state.countryList
    }
  },
  mutations: {
    setAddressScopes (state, addrScopes) {
      state.addressScopes = addrScopes
    },
    setTypologyChoices (state, choices) {
      state.typologyChoices = choices
    },
    setCountryList (state, list) {
      state.countryList = list
    }
  },
  actions: {
    async getAddressscopes ({ commit }, filters = {}) {
      let url = '/api/v1/address/{profile}/address-scope/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setAddressScopes', response.data)
        })
    },
    async getTypologychoices ({ commit }) {
      let url = '/api/v1/organization/{profile}/organization-address/typology-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setTypologyChoices', response.data)
        })
    },
    async getCountrylist ({ commit }, filters = {}) {
      let url = '/api/v1/l10n/{profile}/country/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setCountryList', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'organizationipaaddress', listEntries: ['addressScopes', 'typologyChoices', 'countryList'] }
    ),
    { }
  )
)()

export { organizationipaaddress, TYPES }
