import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  CURRENCY_LIST: 'currency-currency'
}

const routes = [
  {
    path: 'currency/',
    name: ROUTES.CURRENCY_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['currency_currency_list'],
      requiresAuth: true
    },
    component: () => import('./CurrencyList.vue')
  }

]

export { routes, ROUTES }
