import { storeMixin, storeGenerator, parseApiUrl, parseOrderBy, parseFilters, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const uploaddocument = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/upload-document',
    app: 'dashboard'
  },
  actions: {
    async getList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/periodic-meeting/?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
        getters.orderBy ? '&order_by__=' + getters.orderBy : ''
      }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async generateDeadlineTrainingXlsxJob ({ state, commit }, data) {
      if (!data.file_name) {
        data.filename = 'formazione_scaduta.xlsx'
      }
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/generate-deadline-training-xlsx-job/?`
      return axiosThux.post(url, storeMixin.utils.parseForm(data))
        .then(
          response => {
            commit('notifySuccess', { message: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => storeMixin.utils.handleError(commit, error))
    },
    async generateNotPerformedTrainingXlsxJob ({ state, commit }, data) {
      if (!data.file_name) {
        data.filename = 'formazione_non_effettuata.xlsx'
      }
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/generate-not-carried-out-training-xlsx-job/?`
      return axiosThux.post(url, storeMixin.utils.parseForm(data))
        .then(
          response => {
            commit('notifySuccess', { message: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => storeMixin.utils.handleError(commit, error))
    },
    async generateExpiringThreeMonthsTrainingXlsxJob ({ state, commit }, data) {
      if (!data.file_name) {
        data.filename = 'formazione_in_scadenza.xlsx'
      }
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/generate-expiring-training-xlsx-job/?`
      return axiosThux.post(url, storeMixin.utils.parseForm(data))
        .then(
          response => {
            commit('notifySuccess', { message: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => storeMixin.utils.handleError(commit, error))
    },
    async generateExpiringMedicalEligibilityTrainingXlsxJob ({ state, commit }, data) {
      if (!data.file_name) {
        data.filename = 'idoneita_medica_in_scadenza.xlsx'
      }
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/generate-expiring-health-suitability-xlsx-job/?`
      return axiosThux.post(url, storeMixin.utils.parseForm(data))
        .then(
          response => {
            commit('notifySuccess', { message: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => storeMixin.utils.handleError(commit, error))
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'dashboard', model: 'uploaddocument' }
    ),
    {
      GENERIC: {
        dashboard: {
          uploaddocument: {
            LIST: {
              ACTIONS: {
                generateDeadlineTrainingXlsxJob: 'GENERIC/dashboard/uploaddocument/generateDeadlineTrainingXlsxJob',
                generateNotPerformedTrainingXlsxJob: 'GENERIC/dashboard/uploaddocument/generateNotPerformedTrainingXlsxJob',
                generateExpiringThreeMonthsTrainingXlsxJob: 'GENERIC/dashboard/uploaddocument/generateExpiringThreeMonthsTrainingXlsxJob',
                generateExpiringMedicalEligibilityTrainingXlsxJob: 'GENERIC/dashboard/uploaddocument/generateExpiringMedicalEligibilityTrainingXlsxJob'
              }
            }
          }
        }
      }
    }
  )
)()

export { uploaddocument, TYPES }
