import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const yardinspectionattachment = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/yard/{profile}/yard-inspection-attachment',
    app: 'yard'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'yard', model: 'yardinspectionattachment' }
    ),
    { }
  )
)()

export { yardinspectionattachment, TYPES }
