import { parseApiUrl, storeMixin, storeGenerator, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const yard = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/yard/{profile}/yard',
    app: 'dashboard',
    yardGantt: {}
  },
  getters: {
    yardGantt (state) {
      return state.yardGantt
    }
  },
  mutations: {
    setYardGantt (state, list) {
      state.yardGantt = list
    }
  },
  actions: {
    async getYardGantt ({ state, commit, getters, dispatch }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/gantt/?`
      return axiosThux.get(url)
        .then(response => {
          commit('setYardGantt', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'dashboard', model: 'yard' }
    ),
    {
      GENERIC: {
        dashboard: {
          yard: {
            LIST: {
              GETTERS: {
                yardGantt: 'GENERIC/dashboard/yard/yardGantt'
              },
              MUTATIONS: {
                setYardGantt: 'GENERIC/dashboard/yard/setYardGantt'
              },
              ACTIONS: {
                getYardGantt: 'GENERIC/dashboard/yard/getYardGantt'
              }
            }
          }
        }
      }
    }
  )
)()

export { yard, TYPES }
