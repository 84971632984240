import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const organizationGroupCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-group',
    app: 'organization',
    pk: 'code'
  }
})

const TYPES = {
  ORGANIZATION_GROUP_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/organizationGroupCommonFilter/filters',
        list: 'commonFilters/organizationGroupCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/organizationGroupCommonFilter/setFilters',
        setList: 'commonFilters/organizationGroupCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/organizationGroupCommonFilter/getList',
        setFilters: 'commonFilters/organizationGroupCommonFilter/setFilters'
      }
    }
  }
}

export { organizationGroupCommonFilter, TYPES }
