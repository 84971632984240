import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const securityRoleCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact-security-role',
    app: 'organization',
    pk: 'code'
  }
})

const TYPES = {
  SECURITY_ROLE_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/securityRoleCommonFilter/filters',
        list: 'commonFilters/securityRoleCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/securityRoleCommonFilter/setFilters',
        setList: 'commonFilters/securityRoleCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/securityRoleCommonFilter/getList',
        setFilters: 'commonFilters/securityRoleCommonFilter/setFilters'
      }
    }
  }
}

export { securityRoleCommonFilter, TYPES }
