import { storeMixin, storeGenerator, parseApiUrl, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const ownercontactemail = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/owner-contact-email',
    app: 'organization',
    primaryEmailsList: [],
    primaryEmailsFilters: {}
  },
  getters: {
    primaryEmailsList (state) {
      return state.primaryEmailsList
    },
    primaryEmailsFilters (state) {
      return state.primaryEmailsFilters
    }
  },
  mutations: {
    setPrimaryEmailsList (state, list) {
      state.primaryEmailsList = list
    },
    setPrimaryEmailsFilters (state, filters) {
      state.primaryEmailsFilters = filters
    }
  },
  actions: {
    async setPrimaryEmailsFilters ({ dispatch, commit }, filters) {
      commit('setPrimaryEmailsFilters', filters)
      return dispatch('getPrimaryEmailsList')
    },
    async getPrimaryEmailsList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/primary-emails/?no_page=no_page`
      if (state.primaryEmailsFilters) {
        for (const key in state.primaryEmailsFilters) {
          url = `${url}${state.primaryEmailsFilters[key] != null ? '&' + key + '=' + state.primaryEmailsFilters[key] : ''}`
        }
      }
      return axiosThux.get(url)
        .then(response => {
          commit('setPrimaryEmailsList', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }

  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'ownercontactemail' }
    ),
    {
      GENERIC: {
        organization: {
          ownercontactemail: {
            LIST: {
              GETTERS: {
                primaryEmailsList: 'GENERIC/organization/ownercontactemail/primaryEmailsList',
                primaryEmailsFilters: 'GENERIC/organization/ownercontactemail/primaryEmailsFilters'
              },
              MUTATIONS: {
                setPrimaryEmailsList: 'GENERIC/organization/ownercontactemail/setPrimaryEmailsList',
                setPrimaryEmailsFilters: 'GENERIC/organization/ownercontactemail/setPrimaryEmailsFilters'
              },
              ACTIONS: {
                setPrimaryEmailsFilters: 'GENERIC/organization/ownercontactemail/setPrimaryEmailsFilters',
                getPrimaryEmailsList: 'GENERIC/organization/ownercontactemail/getPrimaryEmailsList'
              }
            }
          }
        }
      }
    }
  )
)()

export { ownercontactemail, TYPES }
