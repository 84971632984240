import { merge } from 'lodash'
import { yard, TYPES as YARD_TYPES } from './yard/store'
import { yardaddress, TYPES as YARD_ADDRESS_TYPES } from './yard-address/store'
import { yardcontractor, TYPES as YARD_CONTRACTOR_TYPES } from './yard-contractor/store'
import { yardtechnician, TYPES as YARD_TECHNICIAN_TYPES } from './yard-technician/store'
import { yardcontrolbody, TYPES as YARD_CONTROL_BODY_TYPES } from './yard-control-body/store'
import { yardinspection, TYPES as YARD_INSPECTION_TYPES } from './yard-inspection/store'
import { yardinspectionattachment, TYPES as YARD_INSPECTION_ATTACHMENT_TYPES } from './yard-inspection-attachment/store'
import { yardattachment, TYPES as YARD_ATTACHMENT_TYPES } from './yard-attachments/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      yard: {
        namespaced: true,
        modules: {
          yard,
          yardaddress,
          yardcontractor,
          yardtechnician,
          yardcontrolbody,
          yardinspection,
          yardinspectionattachment,
          yardattachment
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    YARD_TYPES,
    YARD_ADDRESS_TYPES,
    YARD_CONTRACTOR_TYPES,
    YARD_TECHNICIAN_TYPES,
    YARD_CONTROL_BODY_TYPES,
    YARD_INSPECTION_TYPES,
    YARD_INSPECTION_ATTACHMENT_TYPES,
    YARD_ATTACHMENT_TYPES
  )

export { store, TYPES }
