import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  PROFESSIONAL_REGISTER_LIST: 'miscellaneous-professional-register'
}

const routes = [
  {
    path: 'professional-register/',
    name: ROUTES.PROFESSIONAL_REGISTER_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['miscellaneous_professionalregister_list'],
      requiresAuth: true
    },
    component: () => import('./ProfessionalRegisterList.vue')
  }

]

export { routes, ROUTES }
