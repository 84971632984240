<template>
  <div id="app">
    <notification />
    <blockui />
    <router-view />
  </div>
</template>

<script>
import BlockUi from './components/BlockUi'
import Notification from './components/Notification'

export default {
  name: 'app',
  components: {
    blockui: BlockUi,
    notification: Notification
  },
  methods: {}
}
</script>
