import { merge } from 'lodash'
import { atecocode, TYPES as ATECO_CODE_TYPES } from './ateco-code/store'
import { ccnlcontract, TYPES as CCNL_TYPES } from './ccnl-contract/store'
import { jobrisk, TYPES as JOB_RISK_TYPES } from './job-risk/store'
import { professionalregister, TYPES as PROFESSIONAL_REG_TYPES } from './professional-register/store'
import { equalorganization, TYPES as EQUAL_ORG_TYPES } from './equal-organization/store'
import { workplace, TYPES as WORKPLACE_TYPES } from './workplace/store'
import { dpi, TYPES as DPI_TYPES } from './dpi/store'
import { attachmenttypology, TYPES as ATTACHMENT_TYPOLOGYI_TYPES } from './attachment-typology/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      miscellaneous: {
        namespaced: true,
        modules: {
          atecocode,
          ccnlcontract,
          jobrisk,
          professionalregister,
          equalorganization,
          workplace,
          dpi,
          attachmenttypology
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    ATECO_CODE_TYPES,
    CCNL_TYPES,
    JOB_RISK_TYPES,
    PROFESSIONAL_REG_TYPES,
    EQUAL_ORG_TYPES,
    WORKPLACE_TYPES,
    DPI_TYPES,
    ATTACHMENT_TYPOLOGYI_TYPES
  )

export { store, TYPES }
