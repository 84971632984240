import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const organizationcontactsecurityrole = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-contact-security-role',
    app: 'organization'
  },
  actions: {
    async generateNominationZipJob ({ state, commit }, data) {
      if (!data.file_name) {
        data.filename = 'fnomine.zip'
      }
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/generate-download-nominations-job/?`
      return axiosThux.post(url, storeMixin.utils.parseForm(data))
        .then(
          response => {
            commit('notifySuccess', { message: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => storeMixin.utils.handleError(commit, error))
    },
    async downloadGeneratedNominationsZip ({ state, commit, dispatch }, { simpleFileName = 'nominations.zip' }) {
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/download-generated-nominations-zip/${simpleFileName}/`
      return axiosThux({
        url: url,
        method: 'GET',
        responseType: 'blob' // important
      }).then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', simpleFileName)
          document.body.appendChild(link)
          link.click()
        }
      ).catch(error => {
        storeMixin.utils.handleError(commit, dispatch, error)
      })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'organizationcontactsecurityrole' }
    ),
    {
      GENERIC: {
        organization: {
          organizationcontactsecurityrole: {
            LIST: {
              ACTIONS: {
                generateNominationZipJob: 'GENERIC/organization/organizationcontactsecurityrole/generateNominationZipJob',
                downloadGeneratedNominationsZip: 'GENERIC/organization/organizationcontactsecurityrole/downloadGeneratedNominationsZip'
              }
            }
          }
        }
      }

    }
  )
)()

export { organizationcontactsecurityrole, TYPES }
