import { storeMixin, storeGenerator, parseForm, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '../../../../axiosThux'
import { parseApiUrl, parseFilters } from '../../../../storeMixin'

const contactphone = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact-phone',
    app: 'organization',
    typologyList: []
  },
  getters: {
    typologyList (state) {
      return state.typologyList
    }
  },
  mutations: {
    setTypologyList (state, typologyList) {
      state.typologyList = typologyList
    }
  },
  actions: {
    async getTypologylist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/phone-typology/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setTypologyList', response.data)
        })
    },
    async createOrganizationPhone ({ state, commit, dispatch }, { id, ...data }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/create-organization-phone/`
      return axiosThux.post(url, parseForm(data))
        .then(
          response => {
            commit('setDetail', response.data)
            commit('refreshList', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'contactphone', listEntries: ['typologyList'] }
    ),
    {
      GENERIC: {
        organization: {
          contactphone: {
            DETAIL: {
              ACTIONS: {
                createOrganizationPhone: 'GENERIC/organization/contactphone/createOrganizationPhone'
              }
            }
          }
        }
      }
    }
  )
)()

export { contactphone, TYPES }
