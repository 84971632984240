import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const agreement = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/agreement/{profile}/agreement',
    app: 'agreement'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'agreement', model: 'agreement' }
    ),
    { }
  )
)()

export { agreement, TYPES }
