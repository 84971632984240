import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const equalorganization = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/miscellaneous/{profile}/equal-organization',
    app: 'miscellaneous'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'miscellaneous', model: 'equalorganization' }
    ),
    { }
  )
)()

export { equalorganization, TYPES }
