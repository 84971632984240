import { routes as vOwnerOrganizationRoutes, ROUTES as OWNER_ORG_ROUTES } from './organization/organization/owner-organization/router'
import { routes as vOrganizationGroupRoutes, ROUTES as ORG_GROUP_ROUTES } from './organization/organization-group/router'
import { routes as vOrganizationFormRoutes, ROUTES as ORG_FORM_ROUTES } from './organization/organization-form/router'
import { routes as vCustomerOrganizationRoutes, ROUTES as CUSTOMER_ORG_ROUTES } from './organization/organization/customer-organization/router'
import { routes as vSupplierOrganizationRoutes, ROUTES as SUPPLIER_ORG_ROUTES } from './organization/organization/supplier-organization/router'
import { routes as vLeadOrganizationRoutes, ROUTES as LEAD_ORG_ROUTES } from './organization/organization/lead-organization/router'
import { routes as vContactRoleRoutes, ROUTES as CONTACT_ROLE_ROUTES } from './contact/contact-role/router'
import { routes as vContactSecurityRoleRoutes, ROUTES as CONTACT_SECURITY_ROLE_ROUTES } from './contact/contact-security-role/router'
import { routes as vContactTitleRoutes, ROUTES as CONTACT_TITLE_ROUTES } from './contact/contact-title/router'
import { routes as vEmailTypologyRoutes, ROUTES as EMAIL_TYPOLOGY_ROUTES } from './email-typology/router'
import { routes as vPhoneTypologyRoutes, ROUTES as PHONE_TYPOLOGY_ROUTES } from './phone-typology/router'
import { routes as vStaffTypologyRoutes, ROUTES as STAFF_TYPOLOGY_ROUTES } from './staff-typology/router'
import { routes as vContractTypologyRoutes, ROUTES as CONTRACT_TYPOLOGY_ROUTES } from './contract-typology/router'
import { routes as vCustomerContactRoutes, ROUTES as CUSTOMER_CONTACT_ROUTES } from './contact/contact/customer-contact/router'
import { routes as vSupplierContactRoutes, ROUTES as SUPPLIER_CONTACT_ROUTES } from './contact/contact/supplier-contact/router'
import { routes as vSubjectContactRoutes, ROUTES as SUBJECT_CONTACT_ROUTES } from './contact/contact/subject-contact/router'
import { routes as vLeadContactRoutes, ROUTES as LEAD_CONTACT_ROUTES } from './contact/contact/lead-contact/router'
import { routes as vContactQualificationRoutes, ROUTES as CONTACT_QUALIFICATION_ROUTES } from './contact/contact-qualification/router'
import { routes as vOrganizationGenericSearchRoutes, ROUTES as ORG_GENERIC_SEARCH_ROUTES } from './organization/generic-search/router'

const routes = [
  {
    path: 'organization/',
    component: () => import('./OrganizationWrapper'),
    name: 'organization-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vOwnerOrganizationRoutes,
      ...vCustomerOrganizationRoutes,
      ...vOrganizationGroupRoutes,
      ...vOrganizationFormRoutes,
      ...vSupplierOrganizationRoutes,
      ...vLeadOrganizationRoutes,
      ...vContactRoleRoutes,
      ...vContactSecurityRoleRoutes,
      ...vContactTitleRoutes,
      ...vEmailTypologyRoutes,
      ...vPhoneTypologyRoutes,
      ...vStaffTypologyRoutes,
      ...vContractTypologyRoutes,
      ...vCustomerContactRoutes,
      ...vSupplierContactRoutes,
      ...vSubjectContactRoutes,
      ...vLeadContactRoutes,
      ...vContactQualificationRoutes,
      ...vOrganizationGenericSearchRoutes
    ]
  }
]

const ROUTES = {
  ...OWNER_ORG_ROUTES,
  ...CUSTOMER_ORG_ROUTES,
  ...ORG_GROUP_ROUTES,
  ...ORG_FORM_ROUTES,
  ...SUPPLIER_ORG_ROUTES,
  ...LEAD_ORG_ROUTES,
  ...CONTACT_ROLE_ROUTES,
  ...CONTACT_SECURITY_ROLE_ROUTES,
  ...CONTACT_TITLE_ROUTES,
  ...EMAIL_TYPOLOGY_ROUTES,
  ...PHONE_TYPOLOGY_ROUTES,
  ...STAFF_TYPOLOGY_ROUTES,
  ...CONTRACT_TYPOLOGY_ROUTES,
  ...CUSTOMER_CONTACT_ROUTES,
  ...SUPPLIER_CONTACT_ROUTES,
  ...SUBJECT_CONTACT_ROUTES,
  ...LEAD_CONTACT_ROUTES,
  ...CONTACT_QUALIFICATION_ROUTES,
  ...ORG_GENERIC_SEARCH_ROUTES
}

export { routes, ROUTES }
