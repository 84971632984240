import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '../../../../axiosThux'
import { parseApiUrl, parseFilters } from '../../../../storeMixin'

const contactsocialaccount = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact-social-account',
    app: 'organization',
    socialList: []
  },
  getters: {
    socialList (state) {
      return state.socialList
    }
  },
  mutations: {
    setSocialList (state, socialList) {
      state.socialList = socialList
    }
  },
  actions: {
    async getSociallist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/contact-social-account/social-choices/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setSocialList', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'contactsocialaccount', listEntries: ['socialList'] }
    ),
    { }
  )
)()

export { contactsocialaccount, TYPES }
