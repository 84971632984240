import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const ROUTES = {
  USER_LIST: 'account-user',
  USE_CREATE: 'account-create',
  USE_DETAIL: 'account-detail'
}

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const routes = [
  {
    path: 'user/',
    name: ROUTES.USER_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['account_user_list'],
      requiresAuth: true
    },
    component: () => import('./UserList.vue')
  }

]

export { routes, ROUTES }
