import { merge } from 'lodash'
import { addressscope, TYPES as ADDRESS_SCOPE_TYPES } from './address-scope/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      address: {
        namespaced: true,
        modules: {
          addressscope
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    ADDRESS_SCOPE_TYPES
  )

export { store, TYPES }
