import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const invoicerow = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/invoice/{profile}/invoice-row',
    app: 'invoice'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'invoice', model: 'invoicerow' }
    ),
    { }
  )
)()

export { invoicerow, TYPES }
