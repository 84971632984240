import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  PRICE_LIST: 'price-price-list',
  PRICE_LIST_DETAIL: 'price-price-list-detail'
}

const routes = [
  {
    path: 'price-list/',
    name: ROUTES.PRICE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['price_pricelist_list'],
      requiresAuth: true
    },
    component: () => import('./PriceListList.vue'),
    children: [
      {
        path: ':id/',
        name: ROUTES.PRICE_LIST_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['price_pricelist_retrieve'],
          requiresAuth: true
        },
        component: () => import('./PriceListDetail.vue')
      }
    ]
  }
]

export { routes, ROUTES }
