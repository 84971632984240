import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  YARD_QUOTATION_LIST: 'quotation-yard-quotation',
  YARD_QUOTATION_CREATE: 'quotation-yard-quotation-create',
  YARD_QUOTATION_DETAIL: 'quotation-yard-quotation-detail',
  QUOTATION_CREATE_YARD: 'quotation-quotation-create-yard',
  QUOTATION_LIST: 'quotation-quotation',
  QUOTATION_CREATE: 'quotation-quotation-create',
  QUOTATION_DETAIL: 'quotation-quotation-detail'
}

const routes = [
  {
    path: 'yard-quotation/',
    name: ROUTES.YARD_QUOTATION_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['quotation_quotation_list'],
      requiresAuth: true
    },
    component: () => import('./QuotationList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.YARD_QUOTATION_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['quotation_quotation_create'],
          requiresAuth: true
        },
        component: () => import('./QuotationEditPage.vue')
      },
      {
        path: ':id/',
        name: ROUTES.YARD_QUOTATION_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['quotation_quotation_retrieve'],
          requiresAuth: true
        },
        component: () => import('./QuotationEditPage.vue')
      },
      {
        path: ':quotationId/create-yard/',
        name: ROUTES.QUOTATION_CREATE_YARD,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yard_create'],
          requiresAuth: true
        },
        component: () => import('./QuotationToYard.vue')
      }
    ]
  },
  {
    path: 'quotation/',
    name: ROUTES.QUOTATION_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['quotation_quotation_list'],
      requiresAuth: true
    },
    component: () => import('./QuotationList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.QUOTATION_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['quotation_quotation_create'],
          requiresAuth: true
        },
        component: () => import('./QuotationEditPage.vue')
      },
      {
        path: ':id/',
        name: ROUTES.QUOTATION_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['quotation_quotation_retrieve'],
          requiresAuth: true
        },
        component: () => import('./QuotationEditPage.vue')
      }
    ]
  }
]

export { routes, ROUTES }
