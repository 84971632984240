import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  DOCUMENT_LIST: 'document-document',
  DOCUMENT_CONFIGURATION_LIST: 'document-document-config-list'
}

const routes = [
  {
    path: 'document/',
    name: ROUTES.DOCUMENT_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['document_document_list'],
      requiresAuth: true
    },
    component: () => import('./DocumentList.vue'),
    children: [
      {
        path: 'document/:docId/configuration',
        name: ROUTES.DOCUMENT_CONFIGURATION_LIST,
        beforeEnter: beforeEnter,
        meta: {
          permissions: ['document_documentconfig_list'],
          requiresAuth: true
        },
        component: () => import('../document-config/DocumentConfigList')
      }
    ]
  }
]

export { routes, ROUTES }
