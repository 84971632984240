import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import store from './store'

import { TYPES as AUTH_TYPES } from '@/pages/auth/store'

import Layout from '@/layout/Layout'
import { routes as maintenanceRoutes, ROUTES as MAINTENANCE_ROUTES } from '@/pages/maintenance/router'
import { routes as authRoutes, ROUTES as AUTH_ROUTES } from '@/pages/auth/router'
import { routes as accountRoutes, ROUTES as ACCOUNT_ROUTES } from '@/pages/account/router'
import { routes as addressRoutes, ROUTES as ADDRESS_ROUTES } from '@/pages/address/router'
import { routes as carriageRoutes, ROUTES as CARRIAGE_ROUTES } from '@/pages/carriage/router'
import { routes as currencyRoutes, ROUTES as CURRENCY_ROUTES } from '@/pages/currency/router'
import { routes as documentRoutes, ROUTES as DOCUMENT_ROUTES } from '@/pages/document/router'
import { routes as i18nRoutes, ROUTES as I18N_ROUTES } from '@/pages/i18n/router'
import { routes as l10nRoutes, ROUTES as L10N_ROUTES } from '@/pages/l10n/router'
import { routes as officeRoutes, ROUTES as OFFICE_ROUTES } from '@/pages/office/router'
import { routes as organizationRoutes, ROUTES as ORGANIZATION_ROUTES } from '@/pages/organization/router'
import { routes as paymentRoutes, ROUTES as PAYMENT_ROUTES } from '@/pages/payment/router'
import { routes as productRoutes, ROUTES as PRODUCT_ROUTES } from '@/pages/product/router'
import { routes as priceRoutes, ROUTES as PRICE_ROUTES } from '@/pages/price/router'
import { routes as projectRoutes, ROUTES as PROJECT_ROUTES } from '@/pages/project/router'
import { routes as quotationRoutes, ROUTES as QUOTATION_ROUTES } from '@/pages/quotation/router'
import { routes as vatRoutes, ROUTES as VAT_ROUTES } from '@/pages/vat/router'
import { routes as yardRoutes, ROUTES as YARD_ROUTES } from '@/pages/yard/router'
import { routes as miscRoutes, ROUTES as MISC_ROUTES } from '@/pages/miscellaneous/router'
import { routes as proposalRoutes, ROUTES as PROPOSAL_ROUTES } from '@/pages/proposal/router'
import { routes as invoiceRoutes, ROUTES as INVOICE_ROUTES } from '@/pages/invoice/router'
import { routes as agreementRoutes, ROUTES as AGREEMENT_ROUTES } from '@/pages/agreement/router'
import { routes as dashboardRoutes, ROUTES as DASHBOARD_ROUTES } from '@/pages/dashboard/router'
// import maintenance from '@/views/maintenance/router'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = {
  ...MAINTENANCE_ROUTES,
  ...AUTH_ROUTES,
  ...ACCOUNT_ROUTES,
  ...ADDRESS_ROUTES,
  ...CARRIAGE_ROUTES,
  ...CURRENCY_ROUTES,
  ...DOCUMENT_ROUTES,
  ...I18N_ROUTES,
  ...INVOICE_ROUTES,
  ...L10N_ROUTES,
  ...OFFICE_ROUTES,
  ...ORGANIZATION_ROUTES,
  ...PAYMENT_ROUTES,
  ...PRICE_ROUTES,
  ...PRODUCT_ROUTES,
  ...PROJECT_ROUTES,
  ...PROPOSAL_ROUTES,
  ...QUOTATION_ROUTES,
  ...VAT_ROUTES,
  ...YARD_ROUTES,
  ...MISC_ROUTES,
  ...AGREEMENT_ROUTES,
  ...DASHBOARD_ROUTES
  // ...HOME_ROUTES,
  // ...FULFILLMENT_ROUTES
}

// const mappings = {
//   generic: {
//     namespace: 'vGeneric',
//     keyType: 'GENERIC'
//   },
//   administrator: {
//     namespace: 'vSuperuser',
//     keyType: 'SUPERUSER'
//   },
//   backoffice: {
//     namespace: 'vBackoffice',
//     keyType: 'BACKOFFICE'
//   },
//   customer: {
//     namespace: 'vCustomer',
//     keyType: 'CUSTOMER'
//   }
// }

const me = (to, from, next) => {
  store.dispatch(AUTH_TYPES.AUTH.me).then(
    () => {
      store.commit(AUTH_TYPES.AUTH.profiles, store.getters['auth/me'])
      console.log(AUTH_TYPES.AUTH.profiles)
      const profile = store.getters[AUTH_TYPES.AUTH.profiles][0]
      store.commit(AUTH_TYPES.AUTH.profile, profile)
      // const mapping = mappings[store.getters['auth/profile']]
      // store.registerModule([`${mapping.namespace}/currentProfile`], {
      //   getters: {
      //     currentProfile (state) {
      //       return mapping.keyType
      //     }
      //   }
      // })
      setTimeout(() => next(), 10)
    },
    // eslint-disable-next-line no-console
    error => { console.log(error) }
  )
}

const router = new Router({
  base: '/',
  mode: 'history',
  store,
  routes: [
    {
      path: '/',
      component: Layout,
      meta: {
        requiresAuth: true
      },
      beforeEnter: me,
      children: [
        ...dashboardRoutes,
        ...accountRoutes,
        ...addressRoutes,
        ...carriageRoutes,
        ...currencyRoutes,
        ...documentRoutes,
        ...i18nRoutes,
        ...invoiceRoutes,
        ...l10nRoutes,
        ...officeRoutes,
        ...organizationRoutes,
        ...paymentRoutes,
        ...priceRoutes,
        ...productRoutes,
        ...projectRoutes,
        ...proposalRoutes,
        ...quotationRoutes,
        ...vatRoutes,
        ...yardRoutes,
        ...miscRoutes,
        ...agreementRoutes
      ]
    },
    ...authRoutes,
    ...maintenanceRoutes
  ]
})

router.afterEach(() => {
  document.body.classList.remove('app-loading')
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }
})

router.beforeEach(async (to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')
  await store.dispatch('auth/configuration')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch(AUTH_TYPES.AUTH.checkToken)
      .then(
        () => {
          setTimeout(() => next(), 10)
        },
        error => { console.log(error) }
      )
  } else {
    setTimeout(() => next(), 10)
  }
})

export { router, ROUTES }
