import { routes as vTimetableRoutes, ROUTES as TIMETABLE_ROUTES } from './quotation-timetable/router'
import { routes as vInvoiceRoutes, ROUTES as INVOICE_ROUTES } from './invoice/router'

const routes = [
  {
    path: 'invoice/',
    component: () => import('./InvoiceWrapper'),
    name: 'invoice-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vTimetableRoutes,
      ...vInvoiceRoutes
    ]
  }
]

const ROUTES = {
  ...TIMETABLE_ROUTES,
  ...INVOICE_ROUTES
}

export { routes, ROUTES }
