<template>
  <div class="panel" v-bind:class="[{ 'panel-expand': expand, 'panel-loading': reload }, panelTheme, panelClass]" v-if="!remove">
    <div class="panel-heading" :class="doCollapse ? 'clickable' : ''" @click.prevent="panelCollapse(doCollapse)">
      <slot name="header"></slot>
      <h4 class="panel-title" v-if="!hasHeaderSlot">
        {{ title }}
      </h4>
      <div class="panel-heading-btn" v-if="!noButton">
        <slot name="header-actions"></slot>
        <div class="ml-3">
          <a class="btn btn-xs btn-icon btn-circle btn-default" v-if="!buttons || buttons.indexOf('expand') > -1" @click.prevent="panelExpand"><i class="fa fa-expand"></i></a>
          <a class="btn btn-xs btn-icon btn-circle btn-success" v-if="!buttons || buttons.indexOf('redo') > -1" @click.prevent="panelReload"><i class="fa fa-redo"></i></a>
          <a class="btn btn-xs btn-icon btn-circle btn-warning" v-if="!buttons || buttons.indexOf('collapse') > -1" @click.prevent="panelCollapse(true)"><i :class="collapseIconClass"></i></a>
          <a class="btn btn-xs btn-icon btn-circle btn-danger" v-if="!buttons || buttons.indexOf('close') > -1" @click.prevent="panelRemove"><i class="fa fa-times"></i></a>
        </div>
      </div>
    </div>
    <slot name="beforeBody"></slot>
    <div class="panel-body" v-if="!noBody" v-show="!collapse" v-bind:class="bodyClass">
      <slot></slot>
      <div class="panel-loader" v-if="reload"><span class="spinner-small"></span></div>
    </div>
    <slot v-else></slot>
    <slot name="outsideBody"></slot>
    <div class="panel-footer" v-if="hasFooterSlot" v-bind:class="footerClass">
      <slot name="footer"></slot>
    </div>
    <slot name="afterFooter"></slot>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: ['title', 'body', 'footer', 'variant', 'noBody', 'noButton', 'outsideBody', 'bodyClass', 'footerClass', 'panelClass', 'buttons', 'doCollapse'],
  data () {
    return {
      expand: false,
      collapse: false,
      remove: false,
      reload: false,
      panelTheme: (this.variant) ? 'panel-' + this.variant : 'panel-inverse',
      collapseIconClass: 'fas fa-chevron-up'
    }
  },
  computed: {
    hasFooterSlot () {
      return !!this.$slots.footer
    },
    hasHeaderSlot () {
      return !!this.$slots.header
    }
  },
  methods: {
    panelExpand: function () {
      this.expand = !this.expand
    },
    panelCollapse: function (collapse = false) {
      if (collapse) {
        this.collapse = !this.collapse
        if (this.collapse) {
          this.collapseIconClass = 'fas fa-chevron-down'
        } else {
          this.collapseIconClass = 'fas fa-chevron-up'
        }
      }
    },
    panelRemove: function () {
      this.remove = !this.remove
    },
    panelReload: function () {
      this.reload = true
      setTimeout(function () {
        this.resetReload()
      }.bind(this), 2000)
    },
    resetReload: function () {
      this.reload = false
    }
  }
}
</script>
