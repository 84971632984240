import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const ROUTES = {
  CONTACT_QUALIFICATION_LIST: 'organization-contact-qualification'
}

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const routes = [
  {
    path: 'contact-qualification/',
    name: ROUTES.CONTACT_QUALIFICATION_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['organization_contactqualification_list'],
      requiresAuth: true
    },
    component: () => import('./ContactQualificationList.vue')
  }
]

export { routes, ROUTES }
