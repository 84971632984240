<template>
  <b-nav-item-dropdown menu-class="media-list dropdown-menu-right" toggle-class="f-s-14" no-caret>
          <template slot="button-content">
            <i class="fas fa-cloud-download-alt"></i>
            <span class="label" v-if="list && list.length>0">{{list.length}}</span>
            <span class="label" v-else>0</span>
          </template>
          <b-dropdown-header>DOWNLOADS ({{ list.length }})</b-dropdown-header>
          <b-dropdown-item @click="downloadDocument(el.body, el)" class="media" v-for="el in list" :key="el.id">
            <div class="d-flex">
              <div class="media-body">
                <h6 class="media-heading">{{el.body}}</h6>
              </div>
            </div>
          </b-dropdown-item>
        </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as AUTH_TYPES } from '@/pages/auth/store'
import { TYPES as NOTIFICATION_TYPES } from '@/pages/notify/notification/store'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '@/pages/document/upload-document/store'
import { TYPES as QUOTATION_TYPES } from '@/pages/quotation/quotation/store'
import { TYPES as ORG_CNT_SECURITY_ROLE_TYPES } from '@/pages/organization/organization-contact-security-role/store'
import { DOWNLOAD_POLLING_INTERVAL_MILLISECONDS, DOWNLOAD_NOTIFICATION_SLUGS } from '@/const'

export default {
  name: 'DownloadDropDown',
  data () {
    return {
      interval: undefined
    }
  },
  created () {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.setFilters({ no_page: 'no_page' })
    const localThis = this
    this.interval = setInterval(function () {
      localThis.setFilters({ no_page: 'no_page' })
    }, DOWNLOAD_POLLING_INTERVAL_MILLISECONDS)
  },
  computed: {
    ...mapGetters({
      me: AUTH_TYPES.AUTH.me,
      ...NOTIFICATION_TYPES.GENERIC.notify.notification.LIST.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...NOTIFICATION_TYPES.GENERIC.notify.notification.LIST.ACTIONS,
      setNotificationRead: NOTIFICATION_TYPES.GENERIC.notify.notification.DETAIL.ACTIONS.setRead,
      downloadGeneratedZipDocument: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS.downloadGeneratedZipDocument,
      downloadGeneratedQuotationXls: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.ACTIONS.downloadGeneratedQuotationXlsxDocument,
      downloadGeneratedDocumentReportXls: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.downloadGeneratedDocumentReportXls,
      downloadGeneratedNominationsZip: ORG_CNT_SECURITY_ROLE_TYPES.GENERIC.organization.organizationcontactsecurityrole.LIST.ACTIONS.downloadGeneratedNominationsZip
    }),
    downloadDocument (fileName, notification) {
      let promise
      if (notification.slug === DOWNLOAD_NOTIFICATION_SLUGS.documentZip) {
        promise = this.downloadGeneratedZipDocument({ simpleFileName: fileName })
      }
      if (notification.slug === DOWNLOAD_NOTIFICATION_SLUGS.quotationExcelList) {
        promise = this.downloadGeneratedQuotationXls({ simpleFileName: fileName })
      }
      if (notification.slug === DOWNLOAD_NOTIFICATION_SLUGS.expiredTraining) {
        promise = this.downloadGeneratedDocumentReportXls({ simpleFileName: fileName })
      }
      if (notification.slug === DOWNLOAD_NOTIFICATION_SLUGS.notPerformedTraining) {
        promise = this.downloadGeneratedDocumentReportXls({ simpleFileName: fileName })
      }
      if (notification.slug === DOWNLOAD_NOTIFICATION_SLUGS.expiringThreeMonthsTraining) {
        promise = this.downloadGeneratedDocumentReportXls({ simpleFileName: fileName })
      }
      if (notification.slug === DOWNLOAD_NOTIFICATION_SLUGS.expiringMedicalEligibility) {
        promise = this.downloadGeneratedDocumentReportXls({ simpleFileName: fileName })
      }
      if (notification.slug === DOWNLOAD_NOTIFICATION_SLUGS.nominationsZip) {
        promise = this.downloadGeneratedNominationsZip({ simpleFileName: fileName })
      }
      promise.then(() => {
        this.setNotificationRead({ id: notification.id }).then(
          () => {
            this.setFilters({ no_page: 'no_page' })
          }
        )
      })
    }
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  }
}
</script>
