<template>
  <notifications group="messages" />
</template>

<!-- Custom template animation -->
<style>
  .v-fade-left-enter-active,
  .v-fade-left-leave-active,
  .v-fade-left-move {
    transition: all .5s;
  }
  .v-fade-left-enter,
  .v-fade-left-leave-to {
    opacity: 0;
    transform: translateX(-500px) scale(0.2);
  }
</style>

<script>
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {}
</script>
