import { routes as vAgreementDocumentRoutes, ROUTES as AGREEMENT_DOCUMENT_ROUTES } from './agreement-document/router'

const routes = [
  {
    path: 'agreement/',
    component: () => import('./AgreementWrapper'),
    name: 'agreement-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vAgreementDocumentRoutes
    ]
  }
]

const ROUTES = {
  ...AGREEMENT_DOCUMENT_ROUTES
}

export { routes, ROUTES }
