import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const organizationgroup = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-group',
    app: 'organization'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'organizationgroup' }
    ),
    { }
  )
)()

export { organizationgroup, TYPES }
