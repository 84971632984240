import store from '@/store'
import { hasPerm } from '@/components/PermissionHelper'

const ROUTES = {
  DASHBOARD: 'dashboard'
}

const routes = [
  {
    path: '/',
    name: ROUTES.DASHBOARD,
    props: true,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!hasPerm('role_role_dashboard')) {
        setTimeout(() => next({ name: store.getters['auth/profileHomepage'] }), 10)
      } else {
        setTimeout(() => next(), 10)
      }
    },
    component: () => import('./Dashboard.vue')
  }

]

export { routes, ROUTES }
