import { merge } from 'lodash'
import { quotation, TYPES as QUOTATION_TYPES } from './quotation/store'
import { quotationinstallment, TYPES as QUOTATION_INSTALLMENT_TYPES } from './quotation-installment/store'
import { quotationrow, TYPES as QUOTATION_ROW_TYPES } from './quotation-row/store'
import { quotationextramodule, TYPES as QUOTATION_EXTRA_MODULE_TYPES } from './quotation-extra-module/store'
import { customerinvoiceaddress, TYPES as CUSTOMER_INV_ADDRESS_TYPES } from './customer-invoice-address/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      quotation: {
        namespaced: true,
        modules: {
          quotation,
          quotationinstallment,
          quotationrow,
          quotationextramodule,
          customerinvoiceaddress
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    QUOTATION_TYPES,
    QUOTATION_INSTALLMENT_TYPES,
    QUOTATION_ROW_TYPES,
    QUOTATION_EXTRA_MODULE_TYPES,
    CUSTOMER_INV_ADDRESS_TYPES
  )

export { store, TYPES }
