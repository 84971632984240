import { storeMixin, storeGenerator, parseApiUrl, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'
import { VALID_ORGANIZATION_TYPE } from '@/const'
import { parseForm } from '../../../storeMixin'

const contact = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact',
    app: 'organization',
    orgTypeChoices: undefined
  },
  getters: {
    orgTypeChoices (state) {
      return state.orgTypeChoices
    }
  },
  mutations: {
    setOrgTypeChoices (state, orgTypeChoices) {
      state.orgTypeChoices = orgTypeChoices
    }
  },
  actions: {
    async getOrgtypechoices ({ commit }) {
      let url = `/api/v1/organization/{profile}/organization-type/?no_page=no_page&filter__category__in=${VALID_ORGANIZATION_TYPE}&filter__status=1`
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setOrgTypeChoices', response.data)
        })
    },
    async subscribeToCourse ({ commit, dispatch }, { id, ...data }) {
      let url = `/api/v1/organization/{profile}/contact/${id}/subscribe-training/`
      url = parseApiUrl(this, url)
      return axiosThux.post(url, parseForm(data)).then(
        response => {
          dispatch('notifySuccess', { title: '', text: this._vm.$t('Contact succesfully subscribed') }, { root: true })
        }
      ).catch(error => {
        handleError(commit, dispatch, error)
      })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'contact', listEntries: ['orgTypeChoices'] }
    ),
    {
      GENERIC: {
        organization: {
          contact:
          {
            DETAIL: {
              ACTIONS: {
                subscribeToCourse: 'GENERIC/organization/contact/subscribeToCourse'
              }
            }
          }
        }
      }
    }
  )
)()

export { contact, TYPES }
