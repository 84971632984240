import { merge } from 'lodash'
import { office, TYPES as OFFICE_TYPES } from './office/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      office: {
        namespaced: true,
        modules: {
          office
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    OFFICE_TYPES
  )

export { store, TYPES }
