import Vue from 'vue'
import store from '@/store'

const initPermissions = () => {
  Vue.directive('has-perms', {
    inserted (el, binding, vnode, old) {
      if (!hasPerm(binding.value)) {
        el.remove()
      }
    }
  })
  Vue.directive('has-any-perms', {
    inserted (el, binding, vnode, old) {
      if (!hasAnyPerm(binding.value)) {
        el.remove()
      }
    }
  })
}

const beforeEnterHasPerm = (to, from, next) => {
  const permissionToCheck = to.meta?.permissions
  if (hasPerm(permissionToCheck)) {
    setTimeout(() => next(), 10)
  }
}

const hasPerm = (permissionToCheck) => {
  let _permissionToCheck = permissionToCheck
  const profilePermissions = store.getters['auth/me']?.profile_permissions
  if (!_permissionToCheck) {
    return true
  }
  if (!Array.isArray(_permissionToCheck)) {
    _permissionToCheck = [_permissionToCheck]
  }

  for (let index = 0; index < _permissionToCheck.length; index++) {
    const permission = _permissionToCheck[index]
    if (profilePermissions.indexOf(permission) === -1) {
      return false
    }
  }
  return true
}

const hasAnyPerm = (permissionToCheck) => {
  let _permissionToCheck = permissionToCheck
  const profilePermissions = store.getters['auth/me']?.profile_permissions
  if (!_permissionToCheck) {
    return true
  }
  if (!Array.isArray(_permissionToCheck)) {
    _permissionToCheck = [_permissionToCheck]
  }

  for (let index = 0; index < _permissionToCheck.length; index++) {
    const permission = _permissionToCheck[index]
    if (profilePermissions.indexOf(permission) !== -1) {
      return true
    }
  }
  return false
}

export { initPermissions, beforeEnterHasPerm, hasPerm, hasAnyPerm }
