import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux as axios } from '../../../axiosThux'
import { parseApiUrl, parseFilters, handleError } from '../../../storeMixin'

const delivery = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/carriage/{profile}/delivery',
    app: 'carriage',
    pk: 'code',
    carriageList: [],
    transportByList: []
  },
  getters: {
    carriageList (state) {
      return state.carriageList
    },
    transportByList (state) {
      return state.transportByList
    }
  },
  mutations: {
    setCarriageList (state, carriageList) {
      state.carriageList = carriageList
    },
    setTransportByList (state, transportByList) {
      state.transportByList = transportByList
    }
  },
  actions: {
    getCarriageList ({ commit, dispatch }, filters) {
      let url = `${parseApiUrl(this, '/api/v1/carriage/{profile}/carriage/?no_page=no_page')}`
      url = parseFilters({ filters: filters }, url)
      return axios.get(url)
        .then(response => {
          commit('setCarriageList', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    getTransportByList ({ state, commit, dispatch }) {
      const url = `${parseApiUrl(this, `${state.apiUrl}/transport-by-options/`)}`
      return axios.get(url)
        .then(response => {
          commit('setTransportByList', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'carriage', model: 'delivery', listEntries: ['carriageList'] }
    ),
    {
      GENERIC: {
        carriage: {
          delivery:
          {
            LIST: {
              GETTERS: {
                carriageList: 'GENERIC/carriage/delivery/carriageList',
                transportByList: 'GENERIC/carriage/delivery/transportByList'
              },
              MUTATIONS: {
                setCarriageList: 'GENERIC/carriage/delivery/setCarriageList',
                setTransportByList: 'GENERIC/carriage/delivery/setTransportByList'
              },
              ACTIONS: {
                getCarriageList: 'GENERIC/carriage/delivery/getCarriageList',
                getTransportByList: 'GENERIC/carriage/delivery/getTransportByList'
              }
            }
          }
        }
      }
    }

  )
)()

export { delivery, TYPES }
