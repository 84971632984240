import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const organizationemail = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-email',
    app: 'organization',
    emailTypes: undefined
  },
  getters: {
    emailTypes (state) {
      return state.emailTypes
    }
  },
  mutations: {
    setEmailTypes (state, types) {
      state.emailTypes = types
    }
  },
  actions: {
    async getEmailtypes ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/email-typology?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setEmailTypes', response.data)
        })
    }

  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'organizationemail', listEntries: ['emailTypes'] }
    ),
    { }
  )
)()

export { organizationemail, TYPES }
