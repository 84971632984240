import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
// import { axiosThux } from '@/axiosThux'

const documentyardlink = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/document-yard-link',
    app: 'document'
  // },
  // getters: {
  //   documentStatusChoices (state) {
  //     return state.documentStatusChoices
  //   }
  // },
  // mutations: {
  //   setDocumentstatuschoices (state, documentStatusChoices) {
  //     state.documentStatusChoices = documentStatusChoices
  //   }
  // },
  // actions: {
  //   async getDocumentstatuschoices ({ commit }) {
  //     let url = '/api/v1/document/{profile}/upload-document/status-choices/ '
  //     url = parseApiUrl(this, url)
  //     return axiosThux.get(url)
  //       .then(response => {
  //         commit('setDocumentstatuschoices', response.data)
  //       })
  //   },
  //   generateYardDocumentFromTemplate ({ state, commit, dispatch }, { id, action = 'download_document_file', simpleFileName = 'filename' }) {
  //     const url = `${parseApiUrl(this, state.apiUrl)}/${id}/print-yard-pdf/`
  //     return axiosThux({
  //       url: url,
  //       method: 'GET',
  //       responseType: 'blob' // important
  //     }).then(
  //       response => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]))
  //         const link = document.createElement('a')
  //         link.href = url
  //         link.setAttribute('download', simpleFileName)
  //         document.body.appendChild(link)
  //         link.click()
  //       }
  //     ).catch(error => {
  //       handleError(commit, dispatch, error)
  //     })
    // }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'document', model: 'documentyardlink', listEntries: [] }
    ),
    {
      // GENERIC: {
      //   document: {
      //     uploaddocument:
      //     {
      //       DETAIL: {
      //         ACTIONS: {
      //           generateDocumentFromTemplate: 'GENERIC/proposal/proposal/closeProposal',
      //           reOpenProposal: 'GENERIC/proposal/proposal/reOpenProposal'
      //         }
      //       }
      //     }
      //   }
      // }
    }
  )
)()

export { documentyardlink, TYPES }
