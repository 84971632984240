import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const office = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/office/{profile}/office',
    app: 'office'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'office', model: 'office' }
    ),
    { }
  )
)()

export { office, TYPES }
