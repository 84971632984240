import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const organizationinspectionattachment = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-inspection-attachment',
    app: 'organization'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'organizationinspectionattachment' }
    ),
    { }
  )
)()

export { organizationinspectionattachment, TYPES }
