import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const documentconfig = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/document-config',
    app: 'document',
    organizationFormList: [],
    organizationCategoryChoices: [],
    productList: {}
  },
  getters: {
    organizationFormList (state) {
      return state.organizationFormList
    },
    productList (state) {
      return state.productList
    },
    organizationCategoryChoices (state) {
      return state.organizationCategoryChoices
    }
  },
  mutations: {
    setOrganizationformlist (state, organizationFormList) {
      state.organizationFormList = organizationFormList
    },
    setProductlist (state, productList) {
      state.productList = productList
    },
    setOrganizationCategoryChoices (state, choices) {
      state.organizationCategoryChoices = choices
    }
  },
  actions: {
    getOrganizationformlist ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/organization-form/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setOrganizationformlist', response.data)
        })
    },
    getProductlist ({ commit }, filters = {}) {
      let url = '/api/v1/product/{profile}/product/?no_page=no_page'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setProductlist', response.data)
        })
    },
    getOrganizationcategorychoices ({ commit }, filters = {}) {
      let url = '/api/v1/organization/{profile}/organization/category-choices/'
      url = parseApiUrl(this, url)
      url = parseFilters({ filters }, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setOrganizationCategoryChoices', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      {
        profile: 'GENERIC',
        app: 'document',
        model: 'documentconfig',
        listEntries: ['organizationFormList', 'productList', 'organizationCategoryChoices']
      }
    ),
    { }
  )
)()

export { documentconfig, TYPES }
