import { handleError, parseApiUrl, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '../../../axiosThux'

const organization = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization',
    app: 'organization'
  },
  actions: {
    async downloadCompanyRegistry ({ state, commit, dispatch }, { id, simpleFileName = 'SchedaAnagraficaImpresa.pdf', staffIds = null }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/download-company-registry/`
      return axiosThux({
        url: url,
        method: 'POST',
        data: { contact_ids: staffIds },
        responseType: 'blob' // important
      }).then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', simpleFileName)
          document.body.appendChild(link)
          link.click()
        }
      ).catch(error => {
        handleError(commit, dispatch, error)
      })
    }

  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'organization' }
    ),
    {
      GENERIC: {
        organization: {
          organization: {
            DETAIL: {
              ACTIONS: {
                downloadCompanyRegistry: 'GENERIC/organization/organization/downloadCompanyRegistry'
              }
            }
          }
        }
      }
    }
  )
)()

export { organization, TYPES }
