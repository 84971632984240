import { routes as vVatRoutes, ROUTES as VAT_ROUTES } from './vat/router'

const routes = [
  {
    path: 'vat/',
    component: () => import('./VatWrapper'),
    name: 'vat-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vVatRoutes
    ]
  }
]

const ROUTES = {
  ...VAT_ROUTES
}

export { routes, ROUTES }
