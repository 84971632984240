import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const jobrisk = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/miscellaneous/{profile}/job-risk',
    app: 'miscellaneous'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'miscellaneous', model: 'jobrisk' }
    ),
    { }
  )
)()

export { jobrisk, TYPES }
