import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const yardCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/yard/{profile}/yard',
    app: 'yard',
    pk: 'code'
  }
})

const TYPES = {
  YARD_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/yardCommonFilter/filters',
        list: 'commonFilters/yardCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/yardCommonFilter/setFilters',
        setList: 'commonFilters/yardCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/yardCommonFilter/getList',
        setFilters: 'commonFilters/yardCommonFilter/setFilters'
      }
    }
  }
}

export { yardCommonFilter, TYPES }
