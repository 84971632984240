import { cloneDeep } from 'lodash'

export const VALID_ORGANIZATION_TYPE = ['cus', 'sup']

export const INDEX_ORDER_MAX_DECIMAL_PLACES = 9

export const ORGANIZATION_CUSTOMER_TYPE_CODE = 'cus'
export const ORGANIZATION_CUSTOMER_CODE = 'C'

export const ORGANIZATION_SUPPLIER_TYPE_CODE = 'sup'
export const ORGANIZATION_SUPPLIER_CODE = 'F'

export const ORGANIZATION_CUSTOMER_LEAD_CODE = 'L'

export const ORGANIZATION_OWNER_TYPE_CODE = 'own'
export const ORGANIZATION_OWNER_CODE = 'O'

export const YARD_YARDCONTRACTOR_COLORS = {
  SUI: 'green',
  SWP: 'yellow',
  NSU: 'red',
  NR: 'black'
}

export const DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS = {
  V: 'green',
  P: 'yellow',
  I: 'red',
  R: 'black'
}

export const DOCUMENT_DOCUMENT_DPI_SUBTYPE = 'DPI'

export const YARD_WORK_STATUSES = {
  YARD_STATUS_CONFIRMED: 'CON',
  YARD_STATUS_OPERATIVE: 'OP',
  YARD_STATUS_ACTIVE_NOT_OPERATIVE: 'NOP',
  YARD_STATUS_CLOSED: 'CMP'
}

const isEqual = function (value1, value2, callback) {
  // We only need to compare one because they will have the same type
  if ((value1 && !value2) || (!value1 && value2)) return false
  if (Array.isArray(value1)) {
    // If the arrays differ in size, it's considered different
    if (value1.length !== value2.length) {
      return false
    }

    // If they dont differ in size, we need to compare all the elements
    for (let x = 0; x < value1.length; x++) {
      if (Array.isArray(value1[x])) {
        isEqual(value1[x], value2[x], callback)
      } else if (typeof value1[x] === 'object') {
        callback(value1[x], value2[x])
      } else if (value1.toString() !== value2.toString()) {
        return false
      }
    }
  }

  // If we're comparing objects, we need to go deep :kappa:
  if (typeof value1 === 'object') {
    callback(value1, value2)
  } else {
    // Last case where it's neither an array or an object
    if (value1 && value2 && value1.toString() !== value2.toString()) {
      return false
    }
  }

  return true
}

const deepCompare = function (One, Two) {
  if (!One) return
  const ObjectOneKeys = Object.keys(One)

  for (let i = 0; i < ObjectOneKeys.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(Two, ObjectOneKeys[i])) {
      throw new Error('are not same')
    }
    if (!isEqual(One[ObjectOneKeys[i]], Two[ObjectOneKeys[i]], deepCompare, ObjectOneKeys[i])) {
      throw new Error('are not same')
    }
  }
}

export const checkIfEqual = function (element1, element2) {
  try {
    deepCompare(cloneDeep(element1), cloneDeep(element2))
  } catch (e) {
    return false
  }
  return true
}

export const MONTHS = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

export const PAYMENT_FORM_DEFAULT_CODE = 'MP05'

export const DOCUMENT_UPLOAD_DOCUMENT_REQUESTED_STATUS = 'R'

export const DOWNLOAD_POLLING_INTERVAL_MILLISECONDS = 30 * 1000

export const BASE_PRICE_LIST_CODE = 'PL-BASE'

export const DOWNLOAD_NOTIFICATION_SLUGS = {
  quotationExcelList: 'quotation-list-xls',
  documentZip: 'download-org-docs',
  expiredTraining: 'exp-training-report',
  notPerformedTraining: 'np-training-report',
  expiringThreeMonthsTraining: '3m-training-report',
  expiringMedicalEligibility: 'exp-health-report',
  nominationsZip: 'sec-roles-downloads'
}

export const CONTACT_SECURITY_ROLES_CODES = [
  'RSPP-DL',
  'RLS',
  'ASPI',
  'MC',
  'Preposto',
  'ASPS'
]
