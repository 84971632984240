import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  AGREEMENT_DOCUMENT_LIST: 'agreement-document',
  AGREEMENT_DOCUMENT_DETAIL: 'agreement-document-detail'
}

const routes = [
  {
    path: 'agreement-document/',
    name: ROUTES.AGREEMENT_DOCUMENT_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['agreement_agreementdocument_list'],
      requiresAuth: true
    },
    component: () => import('./AgreementDocumentList.vue'),
    children: [
      {
        path: 'agreement-document-detail/:id',
        name: ROUTES.AGREEMENT_DOCUMENT_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['agreement_agreementdocument_retrieve'],
          requiresAuth: true
        }
      }
    ]
  }

]

export { routes, ROUTES }
