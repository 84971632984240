import { merge } from 'lodash'
import { notification, TYPES as NOTIFICATION_TYPES } from './notification/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      notify: {
        namespaced: true,
        modules: {
          notification
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    NOTIFICATION_TYPES
  )

export { store, TYPES }
