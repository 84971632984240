import { merge, capitalize, omit } from 'lodash'
import Vue from 'vue'
import VueLodash from 'vue-lodash'

import { axiosThux as axios } from './axiosThux'

Vue.use(VueLodash)

export const handleError = (commit, dispatch, error) => {
  if (
    error.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    error.response.data.text().then(
      resp => {
        const jsonResp = JSON.parse(resp)
        commit('setErrors', jsonResp)
        dispatch('notifyError', { error: jsonResp }, { root: true })
      }
    )
  } else {
    commit('setErrors', error.response.data)
    dispatch('notifyError', { error: error.response.data }, { root: true })
  }
  throw error
}

const parseFilters = ({ filters }, url) => {
  if (filters) {
    for (const key in filters) {
      url = `${url}${filters[key] != null ? '&' + key + '=' + encodeURIComponent(filters[key]) : ''}`
    }
  }
  return url
}

const parseApiUrl = (store, url) => {
  if (url.indexOf('{profile}') > -1) {
    url = url.replace('{profile}', store.getters['auth/profile'])
  }
  return url
}

const parseForm = (data) => {
  const formData = new FormData()
  let isFile = false
  Object.keys(data).forEach(key => {
    if (data[key] instanceof File) {
      // temporary FIX
      isFile = true
    }
    formData.append(key, data[key])
  })
  return isFile ? formData : data
}

const parseUpdateForm = (data) => {
  const formData = new FormData()
  let isFile = false
  Object.keys(data).forEach(key => {
    if (data[key] instanceof File) {
      // temporary FIX
      isFile = true
    }
    if (data[key]) {
      formData.append(key, data[key])
    }
  })
  return isFile ? formData : data
}

const storeMixin = {
  getMixin: function () {
    return {
      namespaced: true,
      state: {
        apiUrl: '',
        pk: 'id',
        selectAll: false,
        selectedList: [],
        detail: {},
        list: {},
        filters: {},
        page: 1,
        perPage: undefined,
        pageOptions: [10, 25, 50],
        orderBy: undefined,
        errors: {}
      },
      getters: {
        apiUrl (state) {
          return state.apiUrl
        },
        detail (state) {
          return state.detail
        },
        list (state) {
          return state.list
        },
        selectAll (state) {
          return state.selectAll
        },
        selectedList (state) {
          return state.selectedList
        },
        filters (state) {
          return state.filters
        },
        errors (state) {
          return state.errors
        },
        page (state) {
          return state.page || 1
        },
        perPage (state) {
          return state.perPage
        },
        pageOptions (state) {
          return state.pageOptions
        },
        orderBy (state) {
          return state.orderBy
        }
      },
      mutations: {
        setSelectAll (state, selectAll) {
          state.selectAll = selectAll
        },
        setSelectedList (state, selectedList) {
          state.selectedList = selectedList
        },
        setDetail (state, detail) {
          state.detail = detail
        },
        setList (state, list) {
          state.list = list
        },
        setFilters (state, filters) {
          state.filters = filters
        },
        refreshList (state, detail) {
          if (state.list) {
            const resultList = state.list.results ? state.list.results : state.list
            if (resultList.findIndex) {
              const index = resultList.findIndex(element => { return element[state.pk] === detail[state.pk] })
              if (index > -1) {
                Object.assign(resultList[index], detail)
              } else {
                resultList.unshift(detail)
                state.list.count += 1
              }
            }
          }
        },
        setPage (state, page) {
          state.page = Number(page)
        },
        setPerPage (state, perPage) {
          state.perPage = perPage
        },
        setOrderBy (state, orderBy) {
          if (orderBy && orderBy !== '') {
            if (typeof orderBy === 'string') {
              state.orderBy = orderBy
            } else {
              state.orderBy = `${orderBy.sortDesc ? '-' : ''}${orderBy.sortBy}`
            }
          } else {
            state.orderBy = undefined
          }
        },
        setStatus (state, data) {
          if (state.list && state.list.results) {
            const index = state.list.results.findIndex(element => element.id === data.id)
            if (index >= 0) {
              state.list.results[index].status = data.status
              state.list.results[index].status_name = data.status_name
            }
          }
          if (state.detail) {
            state.detail.status = data.status
          }
        },
        setErrors (state, errors) {
          state.errors = errors
        }
      },
      actions: {
        async addFilters ({ dispatch, commit, state }, newFilters) {
          const filters = state.filters ? Object.assign({}, state.filters) : {}
          for (const [key, value] of Object.entries(newFilters)) {
            filters[key] = value
          }
          commit('setFilters', filters)
          commit('setPage', 1)
          return dispatch('getList')
        },
        async removeFilters ({ dispatch, commit, state }, oldFilters) {
          const filters = state.filters
          const cleanFilters = omit(filters, oldFilters)
          commit('setFilters', cleanFilters)
          commit('setPage', 1)
          return dispatch('getList')
        },
        async setFilters ({ dispatch, commit }, filters) {
          commit('setFilters', filters)
          commit('setPage', 1)
          return dispatch('getList')
        },
        cleanFilters ({ commit }, filters) {
          commit('setFilters', filters)
        },
        cleanErrors ({ commit }) {
          commit('setErrors', {})
        },
        cleanLocalData ({ commit }) { },
        cleanAllData ({ state, commit, dispatch }) {
          dispatch('cleanLocalData')
          commit('setPage', undefined)
          commit('setPerPage', undefined)
          commit('setOrderBy', undefined)
          commit('setFilters', {})
          commit('setDetail', {})
          commit('setList', {})
        },
        changePage ({ dispatch, commit }, page) {
          if (parseInt(page)) {
            commit('setPage', page)
            dispatch('getList')
          }
        },
        changePerPage ({ dispatch, commit }, perPage) {
          commit('setPerPage', perPage)
          commit('setPage', 1)
          dispatch('getList')
        },
        changeOrderBy ({ dispatch, commit }, orderBy) {
          commit('setOrderBy', orderBy)
          dispatch('getList')
        },
        async getList ({ state, commit, getters, dispatch }) {
          let url = `${parseApiUrl(this, state.apiUrl)}/?`
          url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
          url = `${url}${getters.page ? '&page=' + getters.page : ''}`
          url = `${url}${
            getters.orderBy ? '&order_by__=' + getters.orderBy : ''
          }`
          url = parseOrderBy(getters, url)
          url = parseFilters(getters, url)
          return axios.get(url)
            .then(response => {
              commit('setList', response.data)
              commit('setPerPage', response.data.per_page)
            }).catch(error => {
              handleError(commit, dispatch, error)
            })
        },
        async retrieve ({ state, commit, dispatch }, { id }) {
          const url = `${parseApiUrl(this, state.apiUrl)}/${id}/`
          return axios.get(url)
            .then(response => {
              commit('setDetail', response.data)
            }).catch(error => {
              handleError(commit, dispatch, error)
            })
        },
        async setStatus ({ state, commit, dispatch }, { id, status }) {
          let action = null
          if (status === 1) {
            action = 'enable'
          } else if (status === 0) {
            action = 'disable'
          }
          const url = `${parseApiUrl(this, state.apiUrl)}/${id}/${action}/`
          return axios.patch(url, { status: status })
            .then(
              response => {
                commit('setStatus', { ...response.data })
                dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
              }
            ).catch(error => {
              handleError(commit, dispatch, error)
            })
        },
        async create ({ state, commit, dispatch }, data) {
          const url = `${parseApiUrl(this, state.apiUrl)}/`
          return axios.post(url, parseForm(data))
            .then(
              response => {
                commit('setDetail', response.data)
                commit('refreshList', response.data)
                dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
              }
            ).catch(error => {
              handleError(commit, dispatch, error)
            })
        },
        async update ({ state, commit, dispatch }, { id, ...data }) {
          const url = `${parseApiUrl(this, state.apiUrl)}/${id}/`
          return axios.put(url, parseUpdateForm(data))
            .then(
              response => {
                commit('setDetail', response.data)
                commit('refreshList', response.data)
                dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
              }
            ).catch(error => {
              handleError(commit, dispatch, error)
            })
        },
        async partialUpdate ({ state, commit, dispatch }, { id, ...data }) {
          const url = `${parseApiUrl(this, state.apiUrl)}/${id}/`
          return axios.patch(url, parseForm(data))
            .then(
              response => {
                commit('setDetail', response.data)
                commit('refreshList', response.data)
                dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
              }
            ).catch(error => {
              handleError(commit, dispatch, error)
            })
        },
        async delete ({ state, getters, commit, dispatch }, { id }) {
          const url = `${parseApiUrl(this, state.apiUrl)}/${id}/`
          return axios.delete(url)
            .then((response) => {
              dispatch('getList')
              dispatch(
                'notifySuccess',
                {
                  title: '',
                  text: this._vm.$t('Data has been deleted successfully')
                },
                { root: true }
              )
            })
            .catch((error) => {
              commit('setErrors', error.response.data)
              dispatch(
                'notifyError',
                { error: error.response.data },
                { root: true }
              )
              throw error
            })
        },
        async download ({ state, commit, dispatch }, { id, action = 'download_document_file', simpleFileName = 'filename', apiUrl = null }) {
          apiUrl = apiUrl || state.apiUrl
          const url = `${parseApiUrl(this, apiUrl)}/${id}/${action}/`
          return axios({
            url: url,
            method: 'GET',
            responseType: 'blob' // important
          }).then(
            response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', simpleFileName)
              document.body.appendChild(link)
              link.click()
            }
          ).catch(error => {
            handleError(commit, dispatch, error)
          })
        }
      }
    }
  },
  utils: {
    parseApiUrl: parseApiUrl,
    parseOrderBy: parseOrderBy,
    parseFilters: parseFilters,
    parseFormMultipart: parseFormMultipart,
    parseForm: parseForm,
    parseNotifyError: parseNotifyError,
    handleError: handleError
  }
}

const sanitizeHtml = require('sanitize-html')

function parseNotifyError (error) {
  let errorList = '<ul>'
  let errorMessage = ''
  if (error.response && error.response.data) {
    Object.entries(error.response.data).forEach(
      ([key, value]) => {
        if (key === 'status_code') {
          errorList += ''
        } else {
          errorList += `<li>${key ? key + ': ' : ''}${value}</li>`
        }
      }
    )
  }
  if (error.response.statusText) {
    errorList = `${errorList}<li>${error.response.statusText}</li>`
  }
  errorList += '</ul>'
  errorMessage = sanitizeHtml(errorList)
  return errorMessage
}

function parseFormMultipart (formData, inputFileNames = []) {
  inputFileNames.forEach(key => {
    if (!formData[key] || typeof formData[key] === 'string') {
      delete formData[key]
    }
  })
  return formData
}

function parseOrderBy (getters, url) {
  const orderBy = getters.orderBy
  if (orderBy && orderBy.sortBy) {
    url = `${url}&order_by__=${orderBy.descending ? '-' : ''}${orderBy.sortBy}`
  }
  return url
}

const Types = {
  getMixin: function (config) {
    return {
      LIST: {
        GETTERS: {
          apiUrl: `${config.profile}/${config.app}/${config.model}/apiUrl`,
          list: `${config.profile}/${config.app}/${config.model}/list`,
          filters: `${config.profile}/${config.app}/${config.model}/filters`,
          page: `${config.profile}/${config.app}/${config.model}/page`,
          perPage: `${config.profile}/${config.app}/${config.model}/perPage`,
          pageOptions: `${config.profile}/${config.app}/${config.model}/pageOptions`,
          orderBy: `${config.profile}/${config.app}/${config.model}/orderBy`,
          selectAll: `${config.profile}/${config.app}/${config.model}/selectAll`,
          selectedList: `${config.profile}/${config.app}/${config.model}/selectedList`
        },
        MUTATIONS: {
          setList: `${config.profile}/${config.app}/${config.model}/setList`,
          setOrderBy: `${config.profile}/${config.app}/${config.model}/setOrderBy`,
          refreshList: `${config.profile}/${config.app}/${config.model}/refreshList`,
          setPage: `${config.profile}/${config.app}/${config.model}/setPage`,
          setSelectAll: `${config.profile}/${config.app}/${config.model}/setSelectAll`,
          setSelectedList: `${config.profile}/${config.app}/${config.model}/setSelectedList`
        },
        ACTIONS: {
          setStatus: `${config.profile}/${config.app}/${config.model}/setStatus`,
          getList: `${config.profile}/${config.app}/${config.model}/getList`,
          setFilters: `${config.profile}/${config.app}/${config.model}/setFilters`,
          addFilters: `${config.profile}/${config.app}/${config.model}/addFilters`,
          removeFilters: `${config.profile}/${config.app}/${config.model}/removeFilters`,
          cleanFilters: `${config.profile}/${config.app}/${config.model}/cleanFilters`,
          changePage: `${config.profile}/${config.app}/${config.model}/changePage`,
          changePerPage: `${config.profile}/${config.app}/${config.model}/changePerPage`,
          changeOrderBy: `${config.profile}/${config.app}/${config.model}/changeOrderBy`,
          cleanAllData: `${config.profile}/${config.app}/${config.model}/cleanAllData`,
          download: `${config.profile}/${config.app}/${config.model}/download`
        }
      },
      DETAIL: {
        GETTERS: {
          detail: `${config.profile}/${config.app}/${config.model}/detail`,
          errors: `${config.profile}/${config.app}/${config.model}/errors`
        },
        MUTATIONS: {
          setDetail: `${config.profile}/${config.app}/${config.model}/setDetail`
        },
        ACTIONS: {
          retrieve: `${config.profile}/${config.app}/${config.model}/retrieve`,
          create: `${config.profile}/${config.app}/${config.model}/create`,
          update: `${config.profile}/${config.app}/${config.model}/update`,
          delete: `${config.profile}/${config.app}/${config.model}/delete`,
          partialUpdate: `${config.profile}/${config.app}/${config.model}/partialUpdate`,
          setStatus: `${config.profile}/${config.app}/${config.model}/setStatus`,
          cleanErrors: `${config.profile}/${config.app}/${config.model}/cleanErrors`,
          cleanAllData: `${config.profile}/${config.app}/${config.model}/cleanAllData`,
          download: `${config.profile}/${config.app}/${config.model}/download`
        }
      }
    }
  }
}

const storeGenerator = ({ profile, app, model, listEntries = [], detailEntries = [] }) => {
  const store = {
    [profile]: {
      [app]: {
        [model]: merge(
          Types.getMixin({ profile, app, model }),
          { LIST: buildSingleStore({ profile, app, model, entries: listEntries }) },
          { DETAIL: buildSingleStore({ profile, app, model, entries: detailEntries }) }
        )
      }
    }
  }
  return store
}

const buildSingleStore = ({ profile, app, model, entries }) => {
  const GETTERS = {}
  entries.forEach(entry => {
    GETTERS[entry] = `${profile}/${app}/${model}/${entry}`
  })
  const MUTATIONS = {}
  entries.forEach(entry => {
    MUTATIONS[`set${capitalize(entry)}`] = `${profile}/${app}/${model}/set${capitalize(entry)}`
  })
  const ACTIONS = {}
  entries.forEach(entry => {
    ACTIONS[`get${capitalize(entry)}`] = `${profile}/${app}/${model}/get${capitalize(entry)}`
  })
  return {
    GETTERS, MUTATIONS, ACTIONS
  }
}

export {
  storeMixin,
  Types,
  buildSingleStore,
  storeGenerator,
  parseFilters,
  parseApiUrl,
  parseForm,
  parseOrderBy,
  parseUpdateForm
}
