import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  CCNL_CONTRACT_LIST: 'miscellaneous-ccnl-contract'
}

const routes = [
  {
    path: 'ccnl-contract/',
    name: ROUTES.CCNL_CONTRACT_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['miscellaneous_ccnlcontract_list'],
      requiresAuth: true
    },
    component: () => import('./CcnlContractList.vue')
  }

]

export { routes, ROUTES }
