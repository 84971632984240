import { carriageCommonFilter, TYPES as CARRIAGE_COMMON_FILTER_TYPES } from './carriage-filter/store'
import { contactCommonFilter, TYPES as CONTACT_COMMON_FILTER_TYPES } from './contact-filter/store'
import { customerContactCommonFilter, TYPES as CUSTOMER_CONTACT_COMMON_FILTER_TYPES } from './customer-contact-filter/store'
import { documentTypeCommonFilter, TYPES as DOCUMENT_TYPE_COMMON_FILTER_TYPES } from './document-type-filter/store'
import { ownerContactCommonFilter, TYPES as OWNER_CONTACT_COMMON_FILTER_TYPES } from './owner-contact-filter/store'
import { organizationCommonFilter, TYPES as ORGANIZATION_COMMON_FILTER_TYPES } from './organization-filter/store'
import { organizationAddressCommonFilter, TYPES as ORGANIZATION_ADDRESS_COMMON_FILTER_TYPES } from './organization-address-filter/store'
import { organizationGroupCommonFilter, TYPES as ORGANIZATION_GROUP_COMMON_FILTER_TYPES } from './organization-group-filter/store'
import { productCategoryCommonFilter, TYPES as PRODUCT_CATEGORY_COMMON_FILTER_TYPES } from './product-category-filter/store'
import { roleCommonFilter, TYPES as ROLE_COMMON_FILTER_TYPES } from './role-filter/store'
import { securityRoleCommonFilter, TYPES as SECURITY_ROLE_COMMON_FILTER_TYPES } from './security-role-filter/store'
import { yardCommonFilter, TYPES as YARD_COMMON_FILTER_TYPES } from './yard-filter/store'

const commonFilters = {
  namespaced: true,
  state: {
    reset: false
  },
  getters: {
    reset (state) {
      return state.reset
    }
  },
  mutations: {
    setReset (state, reset) {
      state.reset = reset
    }
  },
  modules: {
    carriageCommonFilter,
    contactCommonFilter,
    customerContactCommonFilter,
    documentTypeCommonFilter,
    ownerContactCommonFilter,
    organizationCommonFilter,
    organizationAddressCommonFilter,
    organizationGroupCommonFilter,
    roleCommonFilter,
    securityRoleCommonFilter,
    productCategoryCommonFilter,
    yardCommonFilter
  }
}

const CommonFilterTypes = {
  COMMON_FILTERS: {
    GETTERS: {
      reset: 'commonFilters/reset'
    },
    MUTATIONS: {
      setReset: 'commonFilters/setReset'
    },
    ...CARRIAGE_COMMON_FILTER_TYPES,
    ...CONTACT_COMMON_FILTER_TYPES,
    ...CUSTOMER_CONTACT_COMMON_FILTER_TYPES,
    ...DOCUMENT_TYPE_COMMON_FILTER_TYPES,
    ...OWNER_CONTACT_COMMON_FILTER_TYPES,
    ...ORGANIZATION_ADDRESS_COMMON_FILTER_TYPES,
    ...ORGANIZATION_COMMON_FILTER_TYPES,
    ...ORGANIZATION_GROUP_COMMON_FILTER_TYPES,
    ...ROLE_COMMON_FILTER_TYPES,
    ...SECURITY_ROLE_COMMON_FILTER_TYPES,
    ...PRODUCT_CATEGORY_COMMON_FILTER_TYPES,
    ...YARD_COMMON_FILTER_TYPES
  }
}

export { commonFilters, CommonFilterTypes }
