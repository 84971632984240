import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const invoiceissuerbank = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/invoice/{profile}/invoice-issuer-bank',
    app: 'invoice'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'invoice', model: 'invoiceissuerbank' }
    ),
    { }
  )
)()

export { invoiceissuerbank, TYPES }
