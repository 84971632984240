const ROUTES = {
  MAINTENANCE: 'maintenance'
}

const routes = [
  {
    path: '/',
    component: () => import('@/layout/LayoutAuth.vue'),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: 'maintenance/',
        name: ROUTES.MAINTENANCE,
        component: () => import('./Maintenance')
      }
    ]
  }
]

export { routes, ROUTES }
