import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  PROPOSAL_LIST: 'proposal-proposal',
  PROPOSAL_CREATE: 'proposal-proposal-create',
  PROPOSAL_DETAIL: 'proposal-proposal-detail',
  PROPOSAL_CREATE_QUOTATION: 'proposal-proposal-create_quotation'
}

const routes = [
  {
    path: 'proposal/',
    name: ROUTES.PROPOSAL_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['proposal_proposal_list'],
      requiresAuth: true
    },
    component: () => import('./ProposalList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.PROPOSAL_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['proposal_proposal_create'],
          requiresAuth: true
        },
        component: () => import('./ProposalEdit.vue')
      },
      {
        path: ':id/',
        name: ROUTES.PROPOSAL_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['proposal_proposal_retrieve'],
          requiresAuth: true
        },
        component: () => import('./ProposalEdit.vue')
      },
      {
        path: ':proposalId/create-quotation/',
        name: ROUTES.PROPOSAL_CREATE_QUOTATION,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['quotation_quotation_create'],
          requiresAuth: true
        },
        component: () => import('./ProposalToQuotation.vue')
      }
    ]
  }
]

export { routes, ROUTES }
