import { routes as vDashboard, ROUTES as DASHBOARD_ROUTES } from './dashboard/router'

const routes = [
  {
    path: '/',
    component: () => import('./DashboardWrapper.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      ...vDashboard
    ]
  }
]

const ROUTES = {
  ...DASHBOARD_ROUTES
}

export { routes, ROUTES }
