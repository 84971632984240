// import { parseApiUrl, parseFilters, storeMixin, storeGenerator } from '@/storeMixin'
import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
// import { axiosThux } from '@/axiosThux'

const pricelist = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/price/{profile}/price-list',
    app: 'price',
    productCategoryList: undefined,
    unitTypeList: undefined,
    vatList: undefined
  }
  // getters: {
  //   productCategoryList (state) {
  //     return state.productCategoryList
  //   },
  //   unitTypeList (state) {
  //     return state.unitTypeList
  //   },
  //   vatList (state) {
  //     return state.vatList
  //   }
  // },
  // mutations: {
  //   setProductcategorylist (state, productCategoryList) {
  //     state.productCategoryList = productCategoryList
  //   },
  //   setUnittypelist (state, unitTypeList) {
  //     state.unitTypeList = unitTypeList
  //   },
  //   setVatlist (state, vatList) {
  //     state.vatList = vatList
  //   }
  // },
  // actions: {
  //   getProductcategorylist ({ commit }, filters = {}) {
  //     let url = '/api/v1/product/{profile}/product-category/?'
  //     url = parseApiUrl(this, url)
  //     url = parseFilters({ filters }, url)
  //     return axiosThux.get(url)
  //       .then(response => {
  //         commit('setProductcategorylist', response.data)
  //       })
  //   },
  //   getUnittypelist ({ commit }, filters = {}) {
  //     let url = '/api/v1/product/{profile}/unit-type/?'
  //     url = parseApiUrl(this, url)
  //     url = parseFilters({ filters }, url)
  //     return axiosThux.get(url)
  //       .then(response => {
  //         commit('setUnittypelist', response.data)
  //       })
  //   },
  //   getVatlist ({ commit }) {
  //     let url = '/api/v1/vat/{profile}/vat/?no_page=no_page'
  //     url = parseApiUrl(this, url)
  //     return axiosThux.get(url)
  //       .then(response => {
  //         commit('setVatlist', response.data)
  //       })
  //   }
  // }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      {
        profile: 'GENERIC',
        app: 'price',
        model: 'pricelist'
        // listEntries: ['productCategoryList', 'unitTypeList', 'vatList']
      }
    ),
    { }
  )
)()

export { pricelist, TYPES }
