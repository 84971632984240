<template>
  <b-form-group
    :invalid-feedback="customMessageValidator ? customMessageValidator : firstErrorMessage"
    :state="isDirty ? false : getState"
    :label="getLabel"
    :label-class="labelClass"
    :description="description"
    label-align-sm="left"
    :label-cols-sm="labelColsSm"
    label-align-lg="left"
    :label-cols-lg="labelColsLg">
      <slot :attrs="{ state: getState}" :listeners="{ input: () => preferredValidator.$touch() }" />
      <div
        v-if="externalErrors && !disabled"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        class="invalid-feedback d-block"
      >
        <span v-for="error in externalErrors" :key="error">{{ error }}</span>
      </div>
  </b-form-group>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

const FormGroupThuxHorizontal = {
  name: 'FormGroupThuxHorizontal',
  extends: singleErrorExtractorMixin,
  props: {
    customMessageValidator: undefined,
    externalErrors: undefined,
    labelClass: undefined,
    labelColsLg: undefined,
    labelColsSm: undefined,
    disabled: false,
    isDirty: {},
    description: undefined,
    isRequired: { default: true }
  },
  computed: {
    getState () {
      if (this.disabled) {
        return undefined
      }
      if (!this.validator.$dirty) {
        return undefined
      } else {
        return !!(this.isValid && !this.externalErrors)
      }
    },
    getLabel () {
      if (this.validator && 'required' in this.validator && this.isRequired) {
        return `${this.label}*`
      }
      return this.label
    }
  }
}

export default FormGroupThuxHorizontal

export const FormGroupThuxHorizontalPlugin = {
  install (Vue, options) {
    Vue.component(FormGroupThuxHorizontal.name, FormGroupThuxHorizontal)
  }
}

</script>
