import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  OWNER_ORGANIZATION_LIST: 'organization-owner-organization',
  OWNER_ORGANIZATION_DETAIL: 'organization-owner-organization-detail',
  OWNER_ORGANIZATION_CONTACT_DETAIL: 'organization-owner-contact-detail',
  OWNER_ORGANIZATION_DOCUMENTS: 'organization-owner-organization-documents',
  OWNER_ORGANIZATION_CONTACT_DOCUMENTS: 'organization-owner-organization-contact-documents'
}

const routes = [
  {
    path: 'owner-organization/',
    name: ROUTES.OWNER_ORGANIZATION_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['organization_ownerorganization_list'],
      requiresAuth: true
    },
    component: () => import('./OwnerOrganizationList.vue'),
    children: [
      {
        path: ':id/',
        name: ROUTES.OWNER_ORGANIZATION_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_ownerorganization_create'],
          requiresAuth: true
        },
        component: () => import('./OwnerOrganizationDetail.vue')

      },
      {
        path: ':id/contact/:cntId/',
        name: ROUTES.OWNER_ORGANIZATION_CONTACT_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_ownercontact_retrieve'],
          requiresAuth: true
        },
        component: () => import('../../../contact/contact/owner-contact/OwnerContactDetail')
      }
    ]
  },
  {
    path: 'owner-organization/:orgId/documents',
    name: ROUTES.OWNER_ORGANIZATION_DOCUMENTS,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['document_uploaddocument_list'],
      requiresAuth: true
    },
    component: () => import('../../../../document/organization-upload-document/OrganizationUploadDocumentPage')
  },
  {
    path: 'owner-organization/:orgType/:orgId/:cntId/documents',
    name: ROUTES.OWNER_ORGANIZATION_CONTACT_DOCUMENTS,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['document_uploaddocument_list'],
      requiresAuth: true
    },
    component: () => import('../../../../document/upload-document/UploadDocumentsList.vue')
  }

]

export { routes, ROUTES }
