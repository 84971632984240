import { parseApiUrl, storeMixin, storeGenerator, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const invoice = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/invoice/{profile}/invoice',
    app: 'invoice',
    invoiceTypologyChoicesList: undefined
  },
  getters: {
    invoiceTypologyChoicesList (state) {
      return state.invoiceTypologyChoicesList
    }
  },
  mutations: {
    setInvoicetypologychoiceslist (state, invoiceTypologyChoicesList) {
      state.invoiceTypologyChoicesList = invoiceTypologyChoicesList
    }
  },
  actions: {
    async getInvoicetypologychoiceslist ({ state, commit }, filters = {}) {
      const url = parseApiUrl(this, `${state.apiUrl}/invoice-typology-choices/`)
      return axiosThux.get(url)
        .then(response => {
          commit('setInvoicetypologychoiceslist', response.data)
        })
    },
    async setIssued ({ state, commit, dispatch }, id) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/set-issued/`
      return axiosThux.patch(url)
        .then(
          response => {
            commit('setDetail', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async setInserted ({ state, commit, dispatch }, id) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/set-inserted/`
      return axiosThux.patch(url)
        .then(
          response => {
            commit('setDetail', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'invoice', model: 'invoice', listEntries: ['invoiceTypologyChoicesList'] }
    ),
    {
      GENERIC: {
        invoice: {
          invoice:
          {
            LIST: {
              ACTIONS: {
                setIssued: 'GENERIC/invoice/invoice/setIssued',
                setInserted: 'GENERIC/invoice/invoice/setInserted'
              }
            }
          }
        }
      }
    }
  )
)()

export { invoice, TYPES }
