import axios from 'axios'
import { axiosThux } from '@/axiosThux'
import VueCookies from 'vue-js-cookie'
import { router } from '@/router'
import { ROUTES as AUTH_ROUTES } from './router'
import { storeMixin } from '@/storeMixin'

const TYPES = {
  AUTH: {
    configuration: 'auth/configuration',
    login: 'auth/login',
    logout: 'auth/logout',
    changePassword: 'auth/changePassword',
    resetPassword: 'auth/resetPassword',
    resetPasswordConfirm: 'auth/resetPasswordConfirm',
    activateAndResetPasswordConfirm: 'auth/activateAndResetPasswordConfirm',
    changeEmail: 'auth/changeEmail',
    checkToken: 'auth/checkToken',
    checkActivationUrl: 'auth/checkActivationUrl',
    activateUser: 'auth/activateUser',
    me: 'auth/me',
    profileHomepage: 'auth/profileHomepage',
    rememberMe: 'auth/rememberMe',
    token: 'auth/token',
    refreshToken: 'auth/refreshToken',
    profile: 'auth/profile',
    profiles: 'auth/profiles',
    menu: 'auth/menu',
    getMenu: 'auth/getMenu'
  }
}

const getToken = () => {
  // if (!state.token) {
  // state.token = Cookies.get('token')
  const token = VueCookies.get('token')
  if (token) {
    return JSON.parse(token)
  }
  return undefined
}
// return state.token

const auth = {
  namespaced: true,
  state: {
    rememberMe: undefined,
    token: undefined,
    me: undefined,
    configuration: {},
    profileHomepage: undefined,
    menu: [],
    profile: undefined,
    profiles: [],
    permissions: []
  },
  getters: {
    rememberMe (state) {
      return state.rememberMe
    },
    token (state) {
      return getToken
    },
    me (state) {
      return state.me
    },
    configuration (state) {
      return state.configuration
    },
    profileHomepage (state) {
      return state.configuration.PROFILES_HOMEPAGE[state.profile]
    },
    menu (state) {
      return state.menu
    },
    // accessToken (state) {
    //   const token = getToken()
    //   return token ? token.access : token
    // },
    accessToken (state) {
      return getToken
    },
    // refreshToken (state) {
    //   const token = getToken()
    //   return token ? token.refresh : token
    // },
    refreshToken (state) {
      return getToken
    },
    profile (state) {
      return state.profile
    },
    profiles (state) {
      return state.profiles
    }
  },
  mutations: {
    rememberMe (state, rememberMe) {
      state.rememberMe = rememberMe
    },
    token (state, token) {
      if (token) {
        const option = this.getters[TYPES.AUTH.rememberMe] ? { expires: 1 } : {}
        VueCookies.set('token', token, { ...option, path: '/' })
      } else {
        VueCookies.remove('token')
      }
    },
    me (state, me) {
      state.me = me
    },
    configuration (state, configuration) {
      state.configuration = configuration || {}
    },
    menu (state, menu) {
      state.menu = menu
    },
    profile (state, profile) {
      state.profile = profile
    },
    profiles (state, profile) {
      state.profiles = profile.profiles
    }
  },
  actions: {
    checkToken () {
      return new Promise((resolve, reject) => {
        const token = this.getters[TYPES.AUTH.token]
        if (!token) {
          this.dispatch(TYPES.AUTH.logout)
          // reject(new Error('Token is not valid'))
        } else {
          resolve()
        }
      })
    },
    async login ({ commit, dispatch }, formData) {
      commit('token', undefined)
      return axiosThux.post('/api/account/token/auth/', formData).then(
        response => {
          commit('rememberMe', formData.rememberMe)
          commit('token', response.data)
        }
      )
    },
    async me ({ commit }) {
      return axiosThux.get('/api/account/me/').then(
        response => {
          commit('me', response.data)
        }
      )
    },
    async configuration ({ state, commit }) {
      if (state.configuration && Object.keys(state.configuration).length > 0) {
        return state.configuration
      }
      return axios.get('/api/get-global-config/')
        .then(response => {
          commit('configuration', response.data)
          return response.data
        })
    },
    async getMenu ({ commit }) {
      const url = `${storeMixin.utils.parseApiUrl(this, '/api/v1/menu/{profile}/menu')}/?no_page=no_page&filter__parent__isnull=True`
      return axiosThux.get(url).then(
        response => {
          commit('menu', response.data)
        }
      )
    },
    async logout ({ commit, dispatch }) {
      Object.keys(this._actions)
        .filter(key => key.indexOf('cleanAllData') > -1)
        .forEach(func => dispatch(func, {}, { root: true }))
      if (this.getters[TYPES.AUTH.token]) {
        const tokens = this.getters[TYPES.AUTH.token]()
        let token = tokens ? tokens.access : undefined
        const refreshToken = tokens ? tokens.refresh : undefined
        if (token) {
          token = `JWT ${token}`
        }
        return axios.post(
          '/api/account/token/logout/',
          { refresh: refreshToken },
          {
            headers: {
              Authorization: token
            }
          }
        ).then(
          () => {
            commit('token', undefined)
            router.replace({ name: AUTH_ROUTES.LOGIN })
          }
        ).catch(
          error => {
            commit('token', undefined)
            router.push({ name: AUTH_ROUTES.LOGIN }).catch(() => {})
            // commit('setErrors', error.response.data)
            dispatch('notifyError', { error: { token: error.response.data.detail } }, { root: true })
            throw error
          }
        )
      } else {
        return new Promise((resolve, reject) => {
          commit('token', undefined)
          router.push({ name: AUTH_ROUTES.LOGIN }).catch(() => {})
          resolve()
        })
      }
    },
    async changePassword ({ commit, dispatch }, formData) {
      return axiosThux.post('/api/account/password/change/', formData)
        .then(response => {
          dispatch('notifySuccess', { title: '', text: response.data.detail }, { root: true })
        })
        .catch(
          error => {
            // commit('setErrors', error.response.data)
            dispatch('notifyError', { error: error.response.data }, { root: true })
            throw error
          }
        )
    },
    async resetPassword ({ commit, dispatch }, formData) {
      return axiosThux.post('/api/account/password/reset/', formData)
        .then(response => {
          dispatch('notifySuccess', { title: '', text: response.data.detail }, { root: true })
        })
        .catch(
          error => {
            // commit('setErrors', error.response.data)
            dispatch('notifyError', { error: error.response.data }, { root: true })
            throw error
          }
        )
    },
    async resetPasswordConfirm ({ commit }, formData) {
      return axiosThux.post('/api/account/password/reset-confirm/', formData)
    },
    async activateAndResetPasswordConfirm ({ commit }, formData) {
      console.log('INVIO I DATI')
      console.log(formData)
      return axiosThux.post('/api/account/activate-user-and-password-reset-confirm/', formData)
    },
    async tokenRefresh ({ state, commit }, refreshToken) {
      // USE GENERIC AXIOS TO PREVENT LOOP ON 401
      return axios.post('/api/account/token/refresh/', { refresh: refreshToken }).then(
        response => {
          commit('token', response.data)
          return response.data
        }
      )
    },
    async changeEmail ({ commit, dispatch }, formData) {
      return axiosThux.patch('/api/account/email/change/', formData)
        .then(response => {
          dispatch('notifySuccess', { title: '', text: response.data.detail }, { root: true })
        })
        .catch(
          error => {
            // commit('setErrors', error.response.data)
            dispatch('notifyError', { error: error.response.data }, { root: true })
            throw error
          }
        )
    },
    async activateUser ({ commit, dispatch }, data) {
      return axiosThux.get(`/api/account/activate-user/${data.uid}/${data.token}/`)
        .catch(
          error => {
            dispatch('notifyError', { error: { '': error.response.data.detail } }, { root: true })
            throw error
          }
        )
    },
    async checkActivationUrl ({ commit, dispatch }, data) {
      return axiosThux.get(`/api/account/check-activation-token/${data.uid}/${data.token}/`)
        .catch(
          error => {
            throw error
          }
        )
    }
  }
}

export { auth, TYPES }
