import { parseApiUrl, storeMixin, storeGenerator, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const quotation = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/quotation/{profile}/quotation',
    app: 'quotation'
  },
  actions: {
    async setAsSent ({ state, commit, dispatch }, id) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/set-sent/`
      return axiosThux.patch(url)
        .then(
          response => {
            commit('setDetail', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async setAsNotSent ({ state, commit, dispatch }, id) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/set-not-sent/`
      return axiosThux.patch(url)
        .then(
          response => {
            commit('setDetail', response.data)
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async doRevision ({ state, commit, dispatch }, id) {
      let url = `/api/v1/quotation/{profile}/quotation/${id}/do-revision/`
      url = parseApiUrl(this, url)
      return axiosThux.post(url)
        .then(response => {
          commit('setDetail', response.data)
          commit('refreshList', response.data)
          dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          return response.data
        }).catch(error => {
          handleError(commit, dispatch, error)
          throw error
        })
    },
    async accept ({ state, commit, dispatch }, id) {
      let url = `/api/v1/quotation/{profile}/quotation/${id}/accept/`
      url = parseApiUrl(this, url)
      return axiosThux.post(url)
        .then(response => {
          commit('setDetail', response.data)
          commit('refreshList', response.data)
          dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          return response.data
        }).catch(error => {
          handleError(commit, dispatch, error)
          throw error
        })
    },
    async generateQuotationXlsxJob ({ state, commit }, data) {
      if (!data.file_name) {
        data.filename = 'preventivi.xlsx'
      }
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/generate-quotation-xslx-job/?`
      return axiosThux.post(url, storeMixin.utils.parseForm(data))
        .then(
          response => {
            commit('notifySuccess', { message: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => storeMixin.utils.handleError(commit, error))
    },
    async downloadGeneratedQuotationXlsxDocument ({ state, commit, dispatch }, { simpleFileName = 'filename' }) {
      const url = `${storeMixin.utils.parseApiUrl(this, state.apiUrl)}/download-generated-quotation-xslx/${simpleFileName}/`
      return axiosThux({
        url: url,
        method: 'GET',
        responseType: 'blob' // important
      }).then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', simpleFileName)
          document.body.appendChild(link)
          link.click()
        }
      ).catch(error => {
        handleError(commit, dispatch, error)
      })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'quotation', model: 'quotation' }
    ),
    {
      GENERIC: {
        quotation: {
          quotation:
          {
            LIST: {
              ACTIONS: {
                setAsSent: 'GENERIC/quotation/quotation/setAsSent',
                setAsNotSent: 'GENERIC/quotation/quotation/setAsNotSent',
                generateQuotationXlsxJob: 'GENERIC/quotation/quotation/generateQuotationXlsxJob',
                downloadGeneratedQuotationXlsxDocument: 'GENERIC/quotation/quotation/downloadGeneratedQuotationXlsxDocument'
              }
            },
            DETAIL: {
              ACTIONS: {
                doRevision: 'GENERIC/quotation/quotation/doRevision',
                accept: 'GENERIC/quotation/quotation/accept'
              }
            }
          }
        }
      }
    }
  )
)()

export { quotation, TYPES }
