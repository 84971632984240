import { parseApiUrl, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const productcategory = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/product/{profile}/product-category',
    app: 'product',
    typeOfSaleChoices: undefined
  },
  getters: {
    typeOfSaleChoices (state) {
      return state.typeOfSaleChoices
    }
  },
  mutations: {
    setTypeOfSaleChoices (state, typeOfSaleChoices) {
      state.typeOfSaleChoices = typeOfSaleChoices
    }
  },
  actions: {
    async getTypeofsalechoices ({ commit }) {
      let url = '/api/v1/product/{profile}/product-category/type-of-sale-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setTypeOfSaleChoices', response.data)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'product', model: 'productcategory', listEntries: ['typeOfSaleChoices'] }
    ),
    { }
  )
)()

export { productcategory, TYPES }
