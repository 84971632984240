import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const documentTypeCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/document-type',
    app: 'document'
  }
})

const TYPES = {
  DOCUMENT_TYPE_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/documentTypeCommonFilter/filters',
        list: 'commonFilters/documentTypeCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/documentTypeCommonFilter/setFilters',
        setList: 'commonFilters/documentTypeCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/documentTypeCommonFilter/getList',
        setFilters: 'commonFilters/documentTypeCommonFilter/setFilters'
      }
    }
  }
}

export { documentTypeCommonFilter, TYPES }
