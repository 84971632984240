import { storeMixin, storeGenerator, parseApiUrl, parseFilters, parseOrderBy, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const agreementdocument = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/agreement/{profile}/agreement-document/',
    app: 'dashboard',
    organizationChoices: []
  },
  actions: {
    async getList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}valid-agreement-documents/?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
        getters.orderBy ? '&order_by__=' + getters.orderBy : ''
      }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'dashboard', model: 'agreementdocument' }
    )
  )
)()

export { agreementdocument, TYPES }
