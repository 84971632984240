import { storeMixin, storeGenerator, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '../../../axiosThux'
import { parseApiUrl, parseFilters } from '../../../storeMixin'

const yardcontractor = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/yard/{profile}/yard-contractor',
    app: 'yard',
    contractorEligibilityChoices: [],
    staffEligibilityChoices: [],
    posEligibilityChoices: [],
    contractorsEvaluations: []
  },
  getters: {
    contractorEligibilityChoices (state) {
      return state.contractorEligibilityChoices
    },
    staffEligibilityChoices (state) {
      return state.staffEligibilityChoices
    },
    posEligibilityChoices (state) {
      return state.posEligibilityChoices
    },
    contractorsEvaluations (state) {
      return state.contractorsEvaluations
    }
  },
  mutations: {
    setContractoreligibilitychoices (state, contractorEligibilityChoices) {
      state.contractorEligibilityChoices = contractorEligibilityChoices
    },
    setStaffeligibilitychoices (state, staffEligibilityChoices) {
      state.staffEligibilityChoices = staffEligibilityChoices
    },
    setPoseligibilitychoices (state, posEligibilityChoices) {
      state.posEligibilityChoices = posEligibilityChoices
    },
    setContractorsevaluations (state, contractorsEvaluations) {
      state.contractorsEvaluations = contractorsEvaluations
    }
  },
  actions: {
    async getContractoreligibilitychoices ({ commit }) {
      let url = '/api/v1/yard/{profile}/yard-contractor/contractor-eligibility-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setContractoreligibilitychoices', response.data)
        })
    },
    async getStaffeligibilitychoices ({ commit }) {
      let url = '/api/v1/yard/{profile}/yard-contractor/staff-eligibility-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setStaffeligibilitychoices', response.data)
        })
    },
    async getPoseligibilitychoices ({ commit }) {
      let url = '/api/v1/yard/{profile}/yard-contractor/pos-eligibility-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setPoseligibilitychoices', response.data)
        })
    },
    async getContractorsevaluations ({ commit }, filters = {}) {
      let url = '/api/v1/yard/{profile}/yard-contractor/contractors-evaluation/?'
      url = parseFilters({ filters }, url)
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setContractorsevaluations', response.data)
        })
    },
    async downloadEligibility ({ state, commit, dispatch }, { action = 'download-eligibility', simpleFileName = 'filename', apiUrl = null, ...data }) {
      apiUrl = apiUrl || state.apiUrl
      const url = `${parseApiUrl(this, apiUrl)}/${action}/`
      return axiosThux({
        url: url,
        method: 'POST',
        data: data
      }).then(
        response => {
          dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
        }
      ).catch(error => {
        handleError(commit, dispatch, error)
      })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'yard', model: 'yardcontractor', listEntries: ['contractorEligibilityChoices', 'staffEligibilityChoices', 'posEligibilityChoices', 'contractorsEvaluations'] }
    ),
    {
      GENERIC: {
        yard: {
          yardcontractor: {
            LIST: {
              ACTIONS: {
                downloadEligibility: 'GENERIC/yard/yardcontractor/downloadEligibility'
              }
            }
          }
        }
      }
    }
  )
)()

export { yardcontractor, TYPES }
