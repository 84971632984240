import { merge } from 'lodash'
import { carriage, TYPES as CARRIAGE_TYPES } from './carriage/store'
import { delivery, TYPES as DELIVERY_TYPES } from './delivery/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      carriage: {
        namespaced: true,
        modules: {
          carriage,
          delivery
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    CARRIAGE_TYPES,
    DELIVERY_TYPES
  )

export { store, TYPES }
