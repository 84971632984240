import axios from 'axios'
import JWTDecode from 'jwt-decode'
import { vue } from '@/main'
import store from '@/store'
import { router } from '@/router'
import { TYPES as AUTH_TYPES } from '@/pages/auth/store'

const _axiosThux = axios.create() // { baseURL: 'https://api.example.com' })

const urlWithoutBlockUI = [
  'my-downloads/',
  'token/refresh/ '
]

let loadQueue = 0

const loading = store => {
  loadQueue++
  store.commit('loading', true)
}

const unloading = store => {
  loadQueue--
  if (loadQueue < 0) {
    loadQueue = 0
  }
  store.commit('loading', loadQueue !== 0)
}

const forceUnloading = store => {
  store.commit('loading', false)
}

let isFetchingToken = false
let tokenSubscribers = []

function subscribeTokenRefresh (callback) {
  tokenSubscribers.push(callback)
}
function onTokenRefreshed (errRefreshing, token) {
  tokenSubscribers.map(callback => callback(errRefreshing, token))
}
function forceLogout (error) {
  isFetchingToken = false
  store.commit(AUTH_TYPES.AUTH.token, undefined)
  store.dispatch(AUTH_TYPES.AUTH.logout).finally(
    () => {
      router.push({ name: 'login' })
    }
  )
  forceUnloading(vue.$store)
  return Promise.reject(error)
}

_axiosThux.interceptors.response.use(response => {
  if (urlWithoutBlockUI.length === 0 || !new RegExp(`${urlWithoutBlockUI.join('|')}`).test(response.config.url)) {
    unloading(vue.$store)
  }
  if (!vue.$store.getters.componentLoading) {
    vue.$store.commit('componentLoading', true)
  }
  return response
}, error => {
  if (urlWithoutBlockUI.length === 0 || !new RegExp(`${urlWithoutBlockUI.join('|')}`).test(error.config.url)) {
    // if (error.response.status === 403) {
    unloading(vue.$store)
  }
  //   store.commit('auth/token', undefined)
  //   store.dispatch('auth/logout')
  // }
  if ([405, 500, 502, 504].indexOf(error.response.status) > -1) {
    store.dispatch('maintenance')
    delete error.response.data
  }
  if (error.response.config.url.includes('token/auth/')) { return Promise.reject(error) }
  if (error.response.status !== 401) {
    return Promise.reject(error)
  }

  if (!isFetchingToken) {
    isFetchingToken = true

    // console.log(store)

    const token = store.getters[AUTH_TYPES.AUTH.token]()
    const refreshToken = token ? token.refresh : undefined
    if (!refreshToken) return forceLogout(error)

    try {
      const isRefreshTokenExpired =
        JWTDecode(refreshToken).exp < Date.now() / 1000

      if (isRefreshTokenExpired) return forceLogout(error)
    } catch (e) {
      return forceLogout(error)
    }
    store.dispatch('auth/tokenRefresh', refreshToken)
      .then(token => {
        isFetchingToken = false
        onTokenRefreshed(null, token.accessToken || token)
        tokenSubscribers = []
      })
      .catch(() => {
        onTokenRefreshed(new Error('Unable to refresh access token'), null)
        tokenSubscribers = []
        return forceLogout(error)
      })
  }

  const initTokenSubscriber = new Promise((resolve, reject) => {
    subscribeTokenRefresh((errRefreshing, newToken) => {
      if (errRefreshing) return reject(errRefreshing)
      error.config.headers.authorization = 'JWT ' + newToken.access
      return resolve(_axiosThux(error.config))
    })
  })
  return initTokenSubscriber
})

// Add a request interceptor
_axiosThux.interceptors.request.use(
  function (config) {
    if (urlWithoutBlockUI.length === 0 || !new RegExp(`${urlWithoutBlockUI.join('|')}`).test(config.url)) {
      loading(vue.$store)
    }
    config.headers.common['Accept-Language'] = vue.$i18n.locale()
    const data = vue.$store.getters['auth/token']()
    if (data) {
      config.headers.common.authorization = `JWT ${data.access}`
    }
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

const axiosThux = _axiosThux

export { axios, axiosThux }
