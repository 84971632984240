import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  JOB_RISK_LIST: 'miscellaneous-job-risk'
}

const routes = [
  {
    path: 'job-risk/',
    name: ROUTES.JOB_RISK_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['organization_jobrisk_list'],
      requiresAuth: true
    },
    component: () => import('./JobRiskList.vue')
  }

]

export { routes, ROUTES }
