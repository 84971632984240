import { routes as vCountryRoutes, ROUTES as COUNTRY_ROUTES } from './country/router'

const routes = [
  {
    path: 'l10n/',
    component: () => import('./L10nWrapper'),
    name: 'l10n-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vCountryRoutes
    ]
  }
]

const ROUTES = {
  ...COUNTRY_ROUTES
}

export { routes, ROUTES }
