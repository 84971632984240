import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const contactqualification = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/contact-qualification',
    app: 'organization'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'contactqualification' }
    ),
    { }
  )
)()

export { contactqualification, TYPES }
