import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  QUOTATION_INSTALLMENT_LIST: 'quotation-installment-list'
}

const routes = [
  {
    path: 'timetable/',
    name: ROUTES.QUOTATION_INSTALLMENT_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['quotation_quotationinstallment_list'],
      requiresAuth: true
    },
    component: () => import('./QuotationInstallmentList.vue')
  }
]

export { routes, ROUTES }
