import { parseApiUrl, parseOrderBy, parseFilters, handleError, storeMixin, storeGenerator, parseForm } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const notification = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/notify/{profile}/notification',
    app: 'notify'
  },
  actions: {
    async getList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/my-downloads/?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
        getters.orderBy ? '&order_by__=' + getters.orderBy : ''
      }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async setRead ({ state, commit, dispatch }, data) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${data.id}/read/?`
      return axiosThux.post(url, parseForm(data))
        .then(
          response => {
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }

  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      {
        profile: 'GENERIC',
        app: 'notify',
        model: 'notification'
      }
    ),
    {
      GENERIC: {
        notify: {
          notification: {
            DETAIL: {
              ACTIONS: {
                setRead: 'GENERIC/notify/notification/setRead'
              }
            }
          }
        }
      }

    }
  )
)()

export { notification, TYPES }
