import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const recipientinvoiceaddress = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/invoice/{profile}/recipient-invoice-address',
    app: 'invoice'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'invoice', model: 'recipientinvoiceaddress' }
    ),
    { }
  )
)()

export { recipientinvoiceaddress, TYPES }
