import { storeMixin, storeGenerator, parseApiUrl, parseFilters, parseOrderBy, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const training = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: 'api/v1/document/{profile}/upload-document',
    app: 'dashboard',
    documentList: {}
  },
  getters: {
    documentList (state) {
      return state.documentList
    }
  },
  mutations: {
    setDocumentlist (state, list) {
      state.documentList = list
    }
  },
  actions: {
    async getDocumentlist ({ dispatch, commit, state }) {
      const url = `${parseApiUrl(this, 'api/v1/document/{profile}/document')}/expired-training/?no_page=no_page`
      return axiosThux.get(url)
        .then(response => {
          commit('setDocumentlist', response.data)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async getList ({ state, commit, getters, dispatch }) {
      let url = `${parseApiUrl(this, state.apiUrl)}/deadline-training/?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
        getters.orderBy ? '&order_by__=' + getters.orderBy : ''
      }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'dashboard', model: 'training', listEntries: ['documentList'] }
    )
  )
)()

export { training, TYPES }
