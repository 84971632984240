import { routes as vAtecoCodeRoutes, ROUTES as ATECO_CODE_ROUTES } from './ateco-code/router'
import { routes as vCcnlContractRoutes, ROUTES as CCNL_ROUTES } from './ccnl-contract/router'
import { routes as vJobRiskRoutes, ROUTES as JOB_RISK_ROUTES } from './job-risk/router'
import { routes as vProfessionalRegisterRoutes, ROUTES as PROFESSIONAL_REG_ROUTES } from './professional-register/router'
import { routes as vEqualOrganizationRoutes, ROUTES as EQUAL_ORG_ROUTES } from './equal-organization/router'
import { routes as vWorkplaceRoutes, ROUTES as WORKPLACE_ROUTES } from './workplace/router'
import { routes as vDpiRoutes, ROUTES as DPI_ROUTES } from './dpi/router'
import { routes as vAttachmentTypologyRoutes, ROUTES as ATTACHMENT_TYPOLOGY_ROUTES } from './attachment-typology/router'

const routes = [
  {
    path: 'miscellaneous/',
    component: () => import('./MiscellaneousWrapper'),
    name: 'miscellaneous-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vAtecoCodeRoutes,
      ...vCcnlContractRoutes,
      ...vJobRiskRoutes,
      ...vProfessionalRegisterRoutes,
      ...vEqualOrganizationRoutes,
      ...vWorkplaceRoutes,
      ...vDpiRoutes,
      ...vAttachmentTypologyRoutes
    ]
  }
]

const ROUTES = {
  ...ATECO_CODE_ROUTES,
  ...CCNL_ROUTES,
  ...JOB_RISK_ROUTES,
  ...PROFESSIONAL_REG_ROUTES,
  ...EQUAL_ORG_ROUTES,
  ...WORKPLACE_ROUTES,
  ...DPI_ROUTES,
  ...ATTACHMENT_TYPOLOGY_ROUTES
}

export { routes, ROUTES }
