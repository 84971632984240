var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.pageOptions.pageEmpty)?_c('div',{staticClass:"fade page-sidebar-fixed page-header-fixed show page-container",class:{
      'page-sidebar-minified': _vm.pageOptions.pageSidebarMinified,
      'page-content-full-height': _vm.pageOptions.pageContentFullHeight,
      'page-without-sidebar': _vm.pageOptions.pageWithoutSidebar,
      'page-with-right-sidebar': _vm.pageOptions.pageWithRightSidebar,
      'page-with-two-sidebar': _vm.pageOptions.pageWithTwoSidebar,
      'page-with-wide-sidebar': _vm.pageOptions.pageWithWideSidebar,
      'page-with-light-sidebar': _vm.pageOptions.pageWithLightSidebar,
      'page-with-top-menu': _vm.pageOptions.pageWithTopMenu,
      'page-sidebar-toggled': _vm.pageOptions.pageMobileSidebarToggled,
      'page-right-sidebar-toggled': _vm.pageOptions.pageMobileRightSidebarToggled || _vm.pageOptions.pageRightSidebarToggled,
      'page-right-sidebar-collapsed': _vm.pageOptions.pageRightSidebarCollapsed,
      'has-scroll': _vm.pageOptions.pageBodyScrollTop,
      'layout-responsive': _vm.isMobile
    }},[_c('Header'),(_vm.pageOptions.pageWithTopMenu)?_c('TopMenu'):_vm._e(),(!_vm.pageOptions.pageWithoutSidebar)?_c('Sidebar'):_vm._e(),(_vm.pageOptions.pageWithTwoSidebar)?_c('SidebarRight'):_vm._e(),_c('div',{staticClass:"content router-transitions top-collapsed",class:{ 'content-full-width': _vm.pageOptions.pageContentFullWidth, 'content-inverse-mode': _vm.pageOptions.pageContentInverseMode }},[_c('router-view'),_c('portal',{attrs:{"to":"layout-header","slim":""}}),_c('portal',{attrs:{"to":"layout-main","slim":""}}),_c('portal',{attrs:{"to":"layout-detail","slim":""}}),_c('portal',{attrs:{"to":"layout-sidebar","slim":""}}),_c('portal',{attrs:{"to":"layout-footer","slim":""}}),_c('vue-ins-progress-bar')],1),(_vm.pageOptions.pageWithFooter)?_c('Footer'):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }