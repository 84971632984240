<template>
  <b-form-group :invalid-feedback="firstErrorMessage" :state="getState" :label="getLabel" :label-class="labelClass">
    <slot :attrs="{ state: getState}" :listeners="{ input: () => preferredValidator.$touch() }" />
    <div
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      class="invalid-feedback d-block"
      v-if="externalErrors">
      <span v-for="error in externalErrors" :key="error">{{ error }}</span>
    </div>
  </b-form-group>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

const FormGroupThux = {
  name: 'FormGroupThux',
  extends: singleErrorExtractorMixin,
  props: {
    externalErrors: undefined,
    labelClass: undefined
  },
  computed: {
    getState () {
      if (!this.validator.$dirty) {
        return undefined
      } else {
        return !!(this.isValid && !this.externalErrors)
      }
    },
    getLabel () {
      if (this.validator && 'required' in this.validator) {
        return `${this.label}*`
      }
      return this.label
    }
  }
}

export default FormGroupThux

export const FormGroupThuxPlugin = {
  install (Vue, options) {
    Vue.component(FormGroupThux.name, FormGroupThux)
  }
}

</script>
