import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  YARD_LIST: 'yard-yard',
  YARD_CREATE: 'yard-yard-create',
  YARD_DETAIL: 'yard-yard-detail',
  YARD_TECHNICIANS_LIST: 'yard-yard-technicians',
  YARD_CONTRACTOR_LIST: 'yard-yard-contractors',
  YARD_CONTROL_BODIES_LIST: 'yard-yard-control-bodies',
  YARD_DOCUMENTS_LIST: 'yard-yard-documents',
  YARD_INSPECTIONS_LIST: 'yard-yard-inspections',
  YARD_ATTACHMENT_LIST: 'yard-yard-attachment'
}

const routes = [
  {
    path: 'yard/',
    name: ROUTES.YARD_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['yard_yard_list'],
      requiresAuth: true
    },
    component: () => import('./YardList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.YARD_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yard_create'],
          requiresAuth: true
        },
        component: () => import('./YardEditPage.vue')
      },
      {
        path: ':id/edit/',
        name: ROUTES.YARD_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yard_retrieve'],
          requiresAuth: true
        },
        component: () => import('./YardEditPage.vue')
      },
      {
        path: ':id/inspections/',
        name: ROUTES.YARD_INSPECTIONS_LIST,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yardinspection_list'],
          requiresAuth: true
        },
        component: () => import('./inspections/YardInspectionsList.vue')
      },
      {
        path: ':id/technicians/',
        name: ROUTES.YARD_TECHNICIANS_LIST,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yardtechnician_list'],
          requiresAuth: true
        },
        component: () => import('./technicians/YardTechniciansList.vue')
      },
      {
        path: ':id/control-bodies/',
        name: ROUTES.YARD_CONTROL_BODIES_LIST,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yardcontrolbody_list'],
          requiresAuth: true
        },
        component: () => import('./control-bodies/YardControlBodiesList.vue')
      },
      {
        path: ':id/contractors/',
        name: ROUTES.YARD_CONTRACTOR_LIST,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yardcontractor_list'],
          requiresAuth: true
        },
        component: () => import('./contractors/YardContractorsList.vue')
      },
      {
        path: ':id/attachments/',
        name: ROUTES.YARD_ATTACHMENT_LIST,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['yard_yardattachment_list'],
          requiresAuth: true
        },
        component: () => import('./attachments/YardAttachmentsList.vue')
      }
    ]
  },
  {
    path: 'yard/:yardId/documents/',
    name: ROUTES.YARD_DOCUMENTS_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['document_uploaddocument_list'],
      requiresAuth: true
    },
    component: () => import('../../document/upload-document/UploadDocumentsList.vue')
  }
]

export { routes, ROUTES }
