import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  COUNTRY_LIST: 'l10n-country'
}

const routes = [
  {
    path: 'country/',
    name: ROUTES.COUNTRY_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['l10n_country_list'],
      requiresAuth: true
    },
    component: () => import('./CountryList.vue')
  }

]

export { routes, ROUTES }
