import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  EMAIL_TYPOLOGY_LIST: 'organization-email-typology'
}

const routes = [
  {
    path: 'email-typology/',
    name: ROUTES.EMAIL_TYPOLOGY_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['organization_emailtypology_list'],
      requiresAuth: true
    },
    component: () => import('./EmailTypologyList.vue')
  }

]

export { routes, ROUTES }
