import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  ORGANIZATION_GROUP_LIST: 'organization-organization-group'
}

const routes = [
  {
    path: 'organization-group/',
    name: ROUTES.ORGANIZATION_GROUP_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['organization_organizationgroup_list'],
      requiresAuth: true
    },
    component: () => import('./OrganizationGroupList.vue')
  }

]

export { routes, ROUTES }
