import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  CONTACT_SECURITY_ROLE_LIST: 'contact-security-role'
}

const routes = [
  {
    path: 'contact-security-role/',
    name: ROUTES.CONTACT_SECURITY_ROLE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['organization_organizationcontactsecurityrole_list'],
      requiresAuth: true
    },
    component: () => import('./ContactSecurityRoleList.vue')
  }

]

export { routes, ROUTES }
