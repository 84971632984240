import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  OFFICE_LIST: 'office-office'
}

const routes = [
  {
    path: 'office/',
    name: ROUTES.OFFICE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['office_office_list'],
      requiresAuth: true
    },
    component: () => import('./OfficeList.vue')
  }

]

export { routes, ROUTES }
