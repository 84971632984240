import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux as axios } from '../../../../axiosThux'
import { handleError, parseApiUrl, parseFilters, parseOrderBy } from '../../../../storeMixin'

const uploaddocumenthistory = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/document/{profile}/upload-document',
    app: 'document'
  },
  actions: {
    async changePage ({ dispatch, commit }, { page, id }) {
      if (parseInt(page)) {
        commit('setPage', page)
        dispatch('getList', id)
      }
    },
    async changePerPage ({ dispatch, commit }, { perPage, id }) {
      commit('setPerPage', perPage)
      commit('setPage', 1)
      dispatch('getList', id)
    },
    async setFilters ({ dispatch, commit }, { filters, id }) {
      commit('setFilters', filters)
      commit('setPage', 1)
      return dispatch('getList', id)
    },
    async getList ({ state, commit, getters, dispatch }, id) {
      let url = `${parseApiUrl(this, state.apiUrl)}/${id}/history/?`
      url = `${url}${getters.perPage ? 'per_page=' + getters.perPage : ''}`
      url = `${url}${getters.page ? '&page=' + getters.page : ''}`
      url = `${url}${
            getters.orderBy ? '&order_by__=' + getters.orderBy : ''
          }`
      url = parseOrderBy(getters, url)
      url = parseFilters(getters, url)
      return axios.get(url)
        .then(response => {
          commit('setList', response.data)
          commit('setPerPage', response.data.per_page)
        }).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'document', model: 'uploaddocumenthistory' }
    ),
    { }
  )
)()

export { uploaddocumenthistory, TYPES }
