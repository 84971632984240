import { parseApiUrl, storeMixin, storeGenerator, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const parseUpdateForm = (data) => {
  const formData = new FormData()
  let isFile = false
  Object.keys(data).forEach(key => {
    if (data[key] instanceof File) {
      // temporary FIX
      isFile = true
    }
    if (data[key]) {
      formData.append(key, data[key])
    }
  })
  return isFile ? formData : data
}

const user = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/account/{profile}/user',
    app: 'account'
  },
  actions: {
    async sendActivationEmail ({ state, commit, dispatch }, id) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/send-activation-email/`
      return axiosThux.post(url)
        .then(
          response => {
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Email sent') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async setProfile ({ state, commit, dispatch }, { id, ...data }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/set-profile/`
      return axiosThux.post(url, parseUpdateForm(data))
        .then(
          response => {
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Role set succesfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    },
    async setContact ({ state, commit, dispatch }, { id, ...data }) {
      const url = `${parseApiUrl(this, state.apiUrl)}/${id}/set-contact/`
      return axiosThux.post(url, parseUpdateForm(data))
        .then(
          response => {
            dispatch('notifySuccess', { title: '', text: this._vm.$t('Contact set succesfully') }, { root: true })
          }
        ).catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'account', model: 'user' }
    ),
    {
      GENERIC: {
        account: {
          user:
          {
            LIST: {
              ACTIONS: {
                sendActivationEmail: 'GENERIC/account/user/sendActivationEmail'
              }
            },
            DETAIL: {
              ACTIONS: {
                setProfile: 'GENERIC/account/user/setProfile',
                setContact: 'GENERIC/account/user/setContact'
              }
            }
          }
        }
      }
    }
  )
)()

export { user, TYPES }
