import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const dpi = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/miscellaneous/{profile}/dpi',
    app: 'miscellaneous'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'miscellaneous', model: 'dpi' }
    ),
    { }
  )
)()

export { dpi, TYPES }
