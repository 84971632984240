import { storeMixin, storeGenerator, parseApiUrl, handleError } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '../../../../../axiosThux'

const suppliercontact = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/supplier-contact',
    app: 'organization'
  },
  actions: {
    async createContactWithOrganizations ({ state, commit, dispatch }, form) {
      const url = parseApiUrl(this, `${state.apiUrl}/create-with-organizations/`)
      return axiosThux.post(url, form)
        .then(response => {
          commit('setDetail', response.data)
          commit('refreshList', response.data)
          dispatch('notifySuccess', { title: '', text: this._vm.$t('Data has been saved successfully') }, { root: true })
        })
        .catch(error => {
          handleError(commit, dispatch, error)
        })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'organization', model: 'suppliercontact' }
    ),
    {
      GENERIC: {
        organization: {
          suppliercontact: {
            DETAIL: {
              ACTIONS: {
                createContactWithOrganizations: 'GENERIC/organization/suppliercontact/createContactWithOrganizations'
              }
            }
          }
        }
      }
    }
  )
)()

export { suppliercontact, TYPES }
