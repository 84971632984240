import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const organizationCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization',
    app: 'organization',
    pk: 'code'
  }
})

const TYPES = {
  ORGANIZATION_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/organizationCommonFilter/filters',
        list: 'commonFilters/organizationCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/organizationCommonFilter/setFilters',
        setList: 'commonFilters/organizationCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/organizationCommonFilter/getList',
        setFilters: 'commonFilters/organizationCommonFilter/setFilters'
      }
    }
  }
}

export { organizationCommonFilter, TYPES }
