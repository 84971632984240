import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  QUOTATION_EXTRA_MODULE_LIST: 'quotation-quotationextramodule'
}

const routes = [
  {
    path: 'quotation-extra-module/',
    name: ROUTES.QUOTATION_EXTRA_MODULE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['quotation_quotationextramodule_list'],
      requiresAuth: true
    },
    component: () => import('./QuotationExtraModuleList.vue')
  }

]

export { routes, ROUTES }
