import { parseApiUrl, storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'
import { axiosThux } from '@/axiosThux'

const yard = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/yard/{profile}/yard',
    app: 'yard',
    workStatusChoices: undefined
  },
  getters: {
    workStatusChoices (state) {
      return state.workStatusChoices
    }
  },
  mutations: {
    setWorkStatusChoices (state, workStatusChoices) {
      state.workStatusChoices = workStatusChoices
    }
  },
  actions: {
    async getWorkstatuschoices ({ commit }) {
      let url = '/api/v1/yard/{profile}/yard/work-status-choices/'
      url = parseApiUrl(this, url)
      return axiosThux.get(url)
        .then(response => {
          commit('setWorkStatusChoices', response.data)
        })
    },
    async generateYardDocuments ({ commit, dispatch }, yardId) {
      let url = `/api/v1/yard/{profile}/yard/${yardId}/generate-documents/`
      url = parseApiUrl(this, url)
      return axiosThux.post(url).then(() => {
        dispatch('notifySuccess', { title: '', text: this._vm.$t('Documents has been generated successfully') }, { root: true })
      }).catch((error) => {
        dispatch('notifyError', { error: error.response.data }, { root: true })
      })
    }
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'yard', model: 'yard', listEntries: ['workStatusChoices'] }
    ),
    {
      GENERIC: {
        yard: {
          yard: {
            DETAIL: {
              ACTIONS: {
                generateYardDocuments: 'GENERIC/yard/yard/generateYardDocuments'
              }
            }
          }
        }
      }
    }
  )
)()

export { yard, TYPES }
