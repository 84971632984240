import { storeMixin } from '@/storeMixin'
import { merge } from 'lodash'

const organizationAddressCommonFilter = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/organization/{profile}/organization-address',
    app: 'organization'
  }
})

const TYPES = {
  ORGANIZATION_ADDRESS_COMMON_FILTER: {
    LIST: {
      GETTERS: {
        filters: 'commonFilters/organizationAddressCommonFilter/filters',
        list: 'commonFilters/organizationAddressCommonFilter/list'
      },
      MUTATIONS: {
        setFilters: 'commonFilters/organizationAddressCommonFilter/setFilters',
        setList: 'commonFilters/organizationAddressCommonFilter/setList'
      },
      ACTIONS: {
        getList: 'commonFilters/organizationAddressCommonFilter/getList',
        setFilters: 'commonFilters/organizationAddressCommonFilter/setFilters'
      }
    }
  }
}

export { organizationAddressCommonFilter, TYPES }
