import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const carriage = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/carriage/{profile}/carriage',
    app: 'carriage',
    pk: 'code'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'carriage', model: 'carriage' }
    ),
    { }
  )
)()

export { carriage, TYPES }
