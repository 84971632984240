import { routes as vCarriageRoutes, ROUTES as CARRIAGE_ROUTES } from './carriage/router'
import { routes as vDeliveryRoutes, ROUTES as DELIVERY_ROUTES } from './delivery/router'

const routes = [
  {
    path: 'carriage/',
    component: () => import('./CarriageWrapper'),
    name: 'carriage-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vCarriageRoutes,
      ...vDeliveryRoutes
    ]
  }
]

const ROUTES = {
  ...CARRIAGE_ROUTES,
  ...DELIVERY_ROUTES
}

export { routes, ROUTES }
