import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  UNIT_TYPE_LIST: 'product-unit-type'
}

const routes = [
  {
    path: 'unit-type/',
    name: ROUTES.UNIT_TYPE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['product_unittype_list'],
      requiresAuth: true
    },
    component: () => import('./UnitTypeList.vue')
  }
]

export { routes, ROUTES }
