import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const vatrow = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/invoice/{profile}/vat-row',
    app: 'invoice'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'invoice', model: 'vatrow' }
    ),
    { }
  )
)()

export { vatrow, TYPES }
