import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  DOCUMENT_TYPE_LIST: 'document-document-type'
}

const routes = [
  {
    path: 'document-type/',
    name: ROUTES.DOCUMENT_TYPE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['document_documenttype_list'],
      requiresAuth: true
    },
    component: () => import('./DocumentTypeList.vue')
  }
]

export { routes, ROUTES }
