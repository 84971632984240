import { merge } from 'lodash'
import { pricelist, TYPES as PRICE_LIST_TYPES } from './price-list/store'
import { pricelistitem, TYPES as PRICE_LIST_ITEM_TYPES } from './price-list-item/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      price: {
        namespaced: true,
        modules: {
          pricelist,
          pricelistitem
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    PRICE_LIST_TYPES,
    PRICE_LIST_ITEM_TYPES
  )

export { store, TYPES }
