import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const ROUTES = {
  SUBJECT_CONTACT_LIST: 'organization-subject-contact',
  SUBJECT_CONTACT_CREATE: 'organization-subject-contact-create',
  SUBJECT_CONTACT_DETAIL: 'organization-subject-contact-detail'
}

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const routes = [
  {
    path: 'subject-contact/',
    name: ROUTES.SUBJECT_CONTACT_LIST,
    beforeEnter: beforeEnter,
    props: true,
    meta: {
      permissions: ['organization_subjectcontact_list'],
      requiresAuth: true
    },
    component: () => import('./SubjectContactList.vue'),
    children: [
      {
        path: 'create/',
        name: ROUTES.SUBJECT_CONTACT_CREATE,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_subjectcontact_create'],
          requiresAuth: true
        },
        component: () => import('./SubjectContactCreate.vue')
      },
      {
        path: ':cntId/',
        name: ROUTES.SUBJECT_CONTACT_DETAIL,
        beforeEnter: beforeEnter,
        props: true,
        meta: {
          permissions: ['organization_subjectcontact_retrieve'],
          requiresAuth: true
        },
        component: () => import('./SubjectContactDetail.vue')
      }
    ]
  }

]

export { routes, ROUTES }
