import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const addressscope = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/address/{profile}/address-scope',
    app: 'address'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'address', model: 'addressscope' }
    ),
    { }
  )
)()

export { addressscope, TYPES }
