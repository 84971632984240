import { storeMixin, storeGenerator } from '@/storeMixin'
import { merge } from 'lodash'

const quotationextramodule = merge(storeMixin.getMixin(), {
  state: {
    apiUrl: '/api/v1/quotation/{profile}/quotation-extra-module',
    app: 'quotation'
  }
})

const TYPES = (() =>
  merge(
    storeGenerator(
      { profile: 'GENERIC', app: 'quotation', model: 'quotationextramodule' }
    ),
    { }
  )
)()

export { quotationextramodule, TYPES }
