import { merge } from 'lodash'
import { invoice, TYPES as INVOICE_TYPES } from './invoice/store'
import { organizationipaaddress, TYPES as ORGANIZATION_IPA_ADDRESS_TYPES } from './organization-ipa-address/store'
import { recipientinvoiceaddress, TYPES as RECIPIENT_INVOICE_ADDRESS_TYPES } from './recipient-invoice-address/store'
import { invoiceissuerbank, TYPES as INVOICE_ISSUER_BANK_TYPES } from './invoice-issuer-bank/store'
import { invoicerow, TYPES as INVOICE_ROW_TYPES } from './invoice-row/store'
import { vatrow, TYPES as VAT_ROW_TYPES } from './vat-row/store'

const store = {
  GENERIC: {
    namespaced: true,
    modules: {
      invoice: {
        namespaced: true,
        modules: {
          invoice,
          organizationipaaddress,
          recipientinvoiceaddress,
          invoiceissuerbank,
          invoicerow,
          vatrow
        }
      }
    }
  }
}

const TYPES = () =>
  merge(
    INVOICE_TYPES,
    RECIPIENT_INVOICE_ADDRESS_TYPES,
    ORGANIZATION_IPA_ADDRESS_TYPES,
    INVOICE_ISSUER_BANK_TYPES,
    INVOICE_ROW_TYPES,
    VAT_ROW_TYPES
  )

export { store, TYPES }
