import { beforeEnterHasPerm } from '@/components/PermissionHelper'

const beforeEnter = (to, from, next) => { return beforeEnterHasPerm(to, from, next) }

const ROUTES = {
  PROJECT_TYPE_LIST: 'project-project-type'
}

const routes = [
  {
    path: 'project-type/',
    name: ROUTES.PROJECT_TYPE_LIST,
    beforeEnter: beforeEnter,
    meta: {
      permissions: ['project_projecttype_list'],
      requiresAuth: true
    },
    component: () => import('./ProjectTypeList.vue')
  }

]

export { routes, ROUTES }
