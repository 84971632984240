import { routes as vLanguageRoutes, ROUTES as LANGUAGE_ROUTES } from './language/router'

const routes = [
  {
    path: 'i18n/',
    component: () => import('./I18nWrapper'),
    name: 'language-wrapper',
    meta: {
      requiresAuth: true
    },
    children: [
      ...vLanguageRoutes
    ]
  }
]

const ROUTES = {
  ...LANGUAGE_ROUTES
}

export { routes, ROUTES }
